import queryConstructor from '../../util/queryConstructor';
import { keepUnusedDataFor } from './constants';

export const handlers = (builder) => ({
	getPoolState: builder.query({
		query: ({chainId, pairAddress, feeRate}) => {
			const queryParams = queryConstructor({
				chainId, pairAddress, feeRate
			});

			return `analyze/fetchPoolState?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getHighestLiqPoolState: builder.query({
		query: ({chainId, feeRates, token0Address, token1Address}) => {
			const queryParams = queryConstructor({
				chainId, feeRates, token0Address, token1Address,
			});

			return `analyze/fetchHighestLiqPoolState?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getTickEndsFromPools: builder.query({
		query: ({chainId, feeRates, token0Address, token1Address}) => {
			const queryParams = queryConstructor({
				chainId, feeRates, token0Address, token1Address,
			});

			return `analyze/fetchTickEndsFromPools?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
});

export default handlers;
