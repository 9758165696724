import { Token } from "@uniswap/sdk-core";
import { useGetSupportedTokensQuery } from 'api/client';

function useGetSupportedTokens(queryParams = {}, queryOps = {}) {
	const {
		data: supportedTokens = [],
		...rest
	} = useGetSupportedTokensQuery(
		queryParams,
		queryOps
	);

	return {
		data: supportedTokens.map(t => new Token(t.chainId, t.address, t.decimals, t.symbol, t.name)),
		...rest
	};
};

export default useGetSupportedTokens;
