import queryConstructor from '../../util/queryConstructor';
import { keepUnusedDataFor } from './constants';

export const handlers = (builder) => ({
	getPoolsAddresses: builder.query({
		query: ({chainId, dexes, tokens, feeRates}) => {
			const queryParams = queryConstructor({
				chainId, dexes, tokens, feeRates
			});

			return `arbitrage/fetchPoolsAddresses?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getPoolsLiquidity: builder.query({
		query: ({chainId, pools}) => {
			if (pools.length < 1) {
				return { data: [] };
			}

			const queryParams = queryConstructor({
				chainId,
				pools: JSON.stringify(pools)
			});

			return `arbitrage/fetchPoolsLiquidity?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getUniV3Quotes: builder.query({
		query: ({chainId, pools, quoteBaseAmountsByAddress}) => {
			if (
				pools.length < 1 ||
				!quoteBaseAmountsByAddress ||
				Object.keys(quoteBaseAmountsByAddress).length < 1
			) {
				return { data: [] };
			}

			const poolsForFetchSwap = pools.map(p => ({
				...p,
				token0AmountIn: quoteBaseAmountsByAddress[p.token0Address],
				token1AmountIn: quoteBaseAmountsByAddress[p.token1Address],
			})).filter(p => !!p.token0AmountIn && !!p.token1AmountIn);

			const queryParams = queryConstructor({
				chainId,
				pools: JSON.stringify(poolsForFetchSwap)
			});

			return `arbitrage/fetchUniV3Quotes?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
});

export default handlers;
