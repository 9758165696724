import { useCallback } from 'react';
import { Pool, Position, NonfungiblePositionManager } from '@uniswap/v3-sdk';
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'

import { NONFUNGIBLE_POSITION_MANAGER_ADDRESS } from 'constants/addresses';
import { useGetPositionsQuery } from 'api/client';
import useWeb3Wallet from 'hooks/useWeb3Wallet';

import { TransactionState, sendTransactionViaExtension } from 'util/browserExtensionWallet';
import calculateGasMargin from 'util/calculateGasMargin';
import txNotification from 'util/txNotification';
import { getTokens } from 'util/tokens';

const LIQUIDITY_PERCENTAGE = new Percent(100, 100);
const SLIPPAGE_TOLERANCE = new Percent(10, 10_000);
const DEADLINE = () => Math.floor(Date.now() / 1000) + 60 * 20;

function useOnRemoveLiquidity(pid) {
	const { wallet, chainId, provider } = useWeb3Wallet();

	const {
    data: positions = [],
  } = useGetPositionsQuery({
  	chainId,
  	pids: [pid]
  }, {
  	skip: !wallet || !chainId || !provider || !pid
  });

  const p = positions.find(position => position.pid === pid);

	return useCallback(async (onCompleteCallback) => {
		if (!wallet || !chainId || !provider || !pid || !p) return;

		const [Token0, Token1] = getTokens(p?.pool?.Token0, p?.pool?.Token1);

		const pool = new Pool(
			Token0,
			Token1,
			p?.pool?.fee,
			p?.pool?.sqrtPriceX96Current,
			p?.pool?.liquidityCurrent,
			p?.pool?.tickCurrent
		);

		const position = new Position({
			pool,
			liquidity: p?.position?.liquidity,
			tickLower: p?.position?.tickLower,
			tickUpper: p?.position?.tickUpper,
		});

		// we fall back to expecting 0 fees in case the fetch fails, which is safe in the
    // vast majority of cases
    const { calldata, value } = NonfungiblePositionManager.removeCallParameters(position, {
      tokenId: pid,
      liquidityPercentage: LIQUIDITY_PERCENTAGE,
      slippageTolerance: SLIPPAGE_TOLERANCE,
      deadline: DEADLINE(),
      collectOptions: {
        expectedCurrencyOwed0: p?.position?.token0?.earned !== '0' ? CurrencyAmount.fromRawAmount(
    			position?.amount0?.currency,
    			p?.position?.token0?.earnedAsRawStr
      	) : CurrencyAmount.fromRawAmount(
      		position?.amount0?.currency,
      		0
      	),
        expectedCurrencyOwed1: p?.position?.token1?.earned !== '0' ? CurrencyAmount.fromRawAmount(
    			position?.amount1?.currency,
    			p?.position?.token1?.earnedAsRawStr
      	) : CurrencyAmount.fromRawAmount(
      		position?.amount1?.currency,
      		0
      	),
        recipient: wallet,
      },
    });

    const txn = {
      to: NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
      data: calldata,
      value,
    };

    return provider
    	.getSigner()
    	.estimateGas(txn)
    	.then(estimate => {
    		const newTxn = {
    			...txn,
    			gasLimit: calculateGasMargin(estimate),
    		};

    		return provider
    			.getSigner()
    			.sendTransaction(newTxn)
    			.then(transactionResponse => {
    				const txHash = transactionResponse.hash;

    				if (txHash) {
    				  txNotification(txHash, onCompleteCallback);
    				}

    				return {
    				  txState: txHash ? TransactionState.Sent : TransactionState.Failed,
    				  txHash: txHash,
    				};
    			})
    	}).catch(err => {
	    	console.error('Err @ useOnRemoveLiquidity()', err);
	    	return /user rejected transaction/i.test(err.toString()) ? {} : {
	    	  txState: TransactionState.Rejected,
	    	  error: err,
	    	}
	    });
	}, [
		wallet,
		chainId,
		provider,
		pid,
		p,
	]);
};

export default useOnRemoveLiquidity;
