import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import Flex from './Flex';

const LineClampedSpan = styled.span`
	display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Spinner = () => (
	<FontAwesomeIcon icon={faSpinnerThird} spin={true} className="mr-1" />
);

function FullError({ reason, callback, callbackIsLoading, hasItemsBelow = false, flat = false, customPadding = 5, className }) {
	return (
		<Container
			className={classnames({
				[`py-${customPadding}`]: !hasItemsBelow,
				[`pt-${customPadding}`]: hasItemsBelow,
				...className ? {
					[className]: true,
				} : {},
			})}
		>
			<Card
				className={classnames('border-0 rounded', {
					shadow: !flat,
					'bg-gray-100': flat
				})}
			>
				<Card.Body>
					<Flex direction="column" className="w-100 text-center px-2">
						<span className="text-danger-dark">Error</span>
						<LineClampedSpan className="text-gray-700">{typeof reason === 'string' ? reason : JSON.stringify(reason)}</LineClampedSpan>
						<Button
							size="sm"
							variant="info"
							className={classnames('mt-2 f-rem-0.75 fw-5 align-self-center', {
								'disabled': callbackIsLoading
							})}
							onClick={() => !callbackIsLoading && callback()}
						>
							{callbackIsLoading && (<Spinner/>)} Try again
						</Button>
					</Flex>
				</Card.Body>
			</Card>
		</Container>
	);
}

export default FullError;
