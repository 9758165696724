import queryConstructor from '../../util/queryConstructor';
import { keepUnusedDataFor } from './constants';

export const handlers = (builder) => ({
	getTokenBalances: builder.query({
		query: ({chainId, tokenAddresses, wallet, getNative, getUsd}) => {
			const queryParams = queryConstructor({
				chainId, tokenAddresses, wallet, getNative, getUsd,
			});

			return `wallet/fetchTokenBalances?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getNativeBalance: builder.query({
		query: ({chainId, wallet}) => {
			const queryParams = queryConstructor({
				chainId, wallet
			});

			return `wallet/fetchNativeBalance?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
});

export default handlers;
