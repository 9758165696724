import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

function Spinner(props) {
	const { className } = props;

	return (
		<FontAwesomeIcon icon={faSpinnerThird} spin={true} className={className || 'mr-1'} />
	);
}

export default Spinner;
