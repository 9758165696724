import React, { useState, useMemo } from 'react';
import { ethers } from 'ethers';
import { Price } from '@uniswap/sdk-core';
import { Pair, TokenAmount } from '@uniswap/sdk';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import {
	useGetPoolsAddressesQuery,
	useGetUsdPriceFromUniQuery,
	useGetPoolsLiquidityQuery,
	useGetUniV3QuotesQuery,
} from 'api/client';

import { ARBITRAGE_TOKENS } from 'constants/tokens';
import supportedChainId, { chainNameMap } from 'constants/chains';
import * as dexes from 'constants/dexes';
import { FEE_RATES } from 'constants/ticks';
import { commaFormat } from 'util/numberFormatter';
import { getTokens } from 'util/tokens';

import Flex from 'components/Flex';
import Toggle from 'components/Toggle';
import Logo from 'components/Logo';
import FullLoader from 'components/FullLoader';
import RefreshBtn from 'components/RefreshBtn';
import Spinner from 'components/Spinner';

import './Arbitrage.scss';

const getValidDexesByChain = (chainId) => !chainId ? Object.keys(dexes) : Object.entries(dexes).filter(
	([dex, chainsObj]) => Object.keys(chainsObj).some(id => chainId === Number(id))
).map(([dex]) => dex);

const chainsForSelect = Object.entries(chainNameMap)
	.filter(([key, val]) => ['polygon'].some(c => c === val))
	.reduce((acc, [key, val]) => {
		acc[key] = val;
		return acc;
	}, {});

const Config = ({chainId, setChainId, validDexOptions, selectedDexes, setSelectedDexes, validTokenOptions, selectedTokens, setSelectedTokens, selectedFeeRates, setSelectedFeeRates, invertPrices, setInvertPrices, useGasAdjusted, setUseGasAdjusted, refetch, isFetching}) => (
	<Flex direction="column" className="w-100">
		<Flex justify="between" className="w-100">
			{/*Title and Refresh*/}
			<Flex>
				<span className="mr-2">Arbitrage</span>
				<RefreshBtn
					className="align-self-start" style={{fontSize: '0.9rem'}}
					action={refetch}
					refreshing={isFetching}
				/>
			</Flex>
			<Flex>
				{/*Chain selector*/}
				<Toggle
					className="shadow-sm f-rem-0.8 mr-2 align-self-start"
					ops={Object.values(chainsForSelect)}
					active={chainNameMap[chainId]}
					setActive={(chainName) => {
						setChainId(supportedChainId[chainName.toUpperCase()]);
					}}
				/>
				{/*Dex selector*/}
				<Toggle
					className="shadow-sm f-rem-0.8 align-self-start"
					ops={validDexOptions}
					active={selectedDexes}
					setActive={(dex) => {
						setSelectedDexes(
							prev => prev.includes(dex)
								? prev.filter(o => o !== dex)
								: [...prev, dex]
						);
					}}
				/>
			</Flex>
		</Flex>
		<Flex justify="between" className="w-100 mt-2">
			<Flex>
				{/*Tokens selector*/}
				<Toggle
					className="shadow-sm f-rem-0.8 align-self-start mr-2"
					ops={validTokenOptions.map(t => t.symbol)}
					active={selectedTokens.map(t => t.symbol)}
					setActive={(symbol) => {
						setSelectedTokens(
							prev => prev.map(t => t.symbol).includes(symbol)
								? prev.filter(t => t.symbol !== symbol)
								: [...prev, validTokenOptions.find(t => t.symbol === symbol)]
						);
					}}
				/>
				{/*Use gas adjusted*/}
				<Toggle
					className="shadow-sm f-rem-0.8 align-self-start"
					ops={['Gas adj']}
					active={!useGasAdjusted ? [] : ['Gas adj']}
					setActive={() => setUseGasAdjusted(prev => !prev)}
				/>
			</Flex>
			<Flex>
				{/*Invert prices*/}
				<Toggle
					className="shadow-sm f-rem-0.8 align-self-start mr-2"
					ops={['Row/Col', 'Col/Row']}
					active={!invertPrices ? ['Row/Col'] : ['Col/Row']}
					setActive={() => setInvertPrices(prev => !prev)}
				/>
				{/*Fee Rate selector*/}
				<Toggle
					className="shadow-sm f-rem-0.8 align-self-start"
					ops={FEE_RATES.map(f => `${f/10000}%`)}
					active={selectedFeeRates.map(f => `${f/10000}%`)}
					setActive={(feeRate) => {
						const formatted = Number(feeRate.replace('%', ''))*10000;
						setSelectedFeeRates(prev => prev.includes(formatted) ? prev.filter(o => o !== formatted) : [...prev, formatted])
					}}
				/>
			</Flex>
		</Flex>
	</Flex>
);

const ArbTableHeader = ({headers}) => {
	return (
		<thead>
			<tr>
				<th scope="col" colname="space">
					<span></span>
				</th>
				{headers.map((token, idx) => (
			    <th scope="col" colname={token.symbol} key={idx}>
			    	<Flex align="center">
			    		<Logo
			    			token={{...token, chainName: token.chainId}}
			    			className="mr-2"
			    		/>
			    		<span className="f-rem-0.8 fw-6">{token.symbol}</span>
			    	</Flex>
			    </th>
				))}
			</tr>
		</thead>
	);
}

const ArbTableRows = ({rows}) => {
	return (
		<>
			{rows.map((row, idx) => (
				<tr key={idx}>
					<th scope="row">
						<Flex align="center">
							<Logo
								token={{...row?.token, chainName: row?.token?.chainId}}
								className="mr-2"
							/>
							<span className="text-gray-700">{row?.token?.symbol}</span>
						</Flex>
					</th>
					{row.rates.map((rate, idx) => (
						<th key={idx}>
							{!rate?.rate || rate?.rate === '-' ? (
								<Spinner/>
							) : (
								<span>
									{commaFormat(rate.rate, String(rate.rate).split('.')[1]?.length, '-')}
								</span>
							)}
						</th>
					))}
				</tr>
			))}
		</>
	);
}

const ArbTable = ({selectedTokens, usdPricesForBaseAmounts, v2PoolsLiqData, v3SwapRatesData, quoteBaseAmountsByAddress, invertPrices}) => {
	const tokensByAddress = useMemo(() => {
		const tokensByAddress = selectedTokens.reduce((acc, token) => {
			acc[token.address] = token;
			return acc;
		}, {});

		return tokensByAddress;
	}, [selectedTokens]);

	const v2PoolsRatesByPairAddress = useMemo(() => {
		const ratesByPairAddress = Object.values(v2PoolsLiqData).filter(
			(pool) => !!tokensByAddress[pool.token0Address] && !!tokensByAddress[pool.token1Address]
		).reduce(
			(acc, pool) => {
				const { pairAddress, token0Address, token1Address, liquidity: { reserve0, reserve1 } = {} } = pool;
				acc[pairAddress] = acc[pairAddress] || {};
				const Token0 = tokensByAddress[token0Address];
				const Token1 = tokensByAddress[token1Address];

				if (!Token0 || !Token1) return acc;

				let amountOutToken1, amountOutToken0;

				try {
					const instance = new Pair(
						new TokenAmount(Token0, reserve0),
						new TokenAmount(Token1, reserve1)
					);

					const amountInToken0 = quoteBaseAmountsByAddress[token0Address].amount;
					const amountInToken1 = quoteBaseAmountsByAddress[token1Address].amount;

					amountOutToken1 = instance.getOutputAmount(
						new TokenAmount(Token0, amountInToken0)
					);

					amountOutToken0 = instance.getOutputAmount(
						new TokenAmount(Token1, amountInToken1)
					);

					acc[pairAddress][token0Address] = {
						tokenIn: token0Address,
						amountIn: amountInToken0.toString(),
						tokenOut: token1Address,
						amountOut: amountOutToken1?.[0]?.toExact(),
						pairAddress: pairAddress,
					};

					acc[pairAddress][token1Address] = {
						tokenIn: token1Address,
						amountIn: amountInToken1.toString(),
						tokenOut: token0Address,
						amountOut: amountOutToken0?.[0]?.toExact(),
						pairAddress: pairAddress,
					};
				} catch(err) {
					if (err.isInsufficientInputAmountError) console.error('isInsufficientInputAmountError');
					else console.error(err);
				}

				return acc;
			}, {});

		return ratesByPairAddress;
	}, [tokensByAddress, v2PoolsLiqData, quoteBaseAmountsByAddress]);

	const allPoolRatesByTokenAddress = useMemo(() => {
		const allPools = {...v2PoolsRatesByPairAddress, ...v3SwapRatesData};
		const poolsByToken = Object.values(allPools).reduce((acc, byTokenInObj = {}) => {
			Object.values(byTokenInObj).forEach(poolWithRate => {
				if (
					!poolWithRate ||
					Object.keys(poolWithRate).length < 1
				) return acc;

				if (
					!tokensByAddress[poolWithRate.tokenIn] ||
					!tokensByAddress[poolWithRate.tokenOut]
				) return acc;

				const tokenIn = tokensByAddress[poolWithRate.tokenIn];
				const tokenOut = tokensByAddress[poolWithRate.tokenOut];
				if (!tokenIn || !tokenOut) return acc;

				const amountIn = ethers.utils.parseUnits(poolWithRate.amountIn, tokenIn.decimals);
				const amountOut = ethers.utils.parseUnits(poolWithRate.amountOut, tokenOut.decimals);
				const price = new Price(tokenIn, tokenOut, amountIn, amountOut);

				acc[tokenIn.address] = acc[tokenIn.address] || {};
				acc[tokenIn.address][tokenOut.address] = acc[tokenIn.address][tokenOut.address] || [];

				acc[tokenIn.address][tokenOut.address].push({
					tokenIn, amountIn, tokenOut, amountOut, price,
				});
			});

			return acc;
		}, {});

		return poolsByToken;
	}, [tokensByAddress, v2PoolsRatesByPairAddress, v3SwapRatesData]);

	const bestRatesForTokens = useMemo(() => {
		const tokensForRows = Object.values(tokensByAddress);
		const rates = tokensForRows.map(t => {
			const bestRates = Object.keys(tokensByAddress).map(address => {
				if (t.address === address) {
					return { rate: '1' };
				}

				const pools = allPoolRatesByTokenAddress?.[t.address]?.[address] || [];
				const [bestPool] = pools.sort((a, b) => a.price.greaterThan(b.price) ? -1 : 1) || {};
				const bestRate = !invertPrices ? bestPool?.price : bestPool?.price?.invert();

				return {
					...bestPool,
					rate: bestRate?.toSignificant() || '-',
				};
			});

			return {
				token: t,
				rates: bestRates
			};
		});

		return rates;
	}, [tokensByAddress, allPoolRatesByTokenAddress, invertPrices]);

	return (
		<div className="w-100" style={{overflowX: 'scroll'}}>
			<Table className="table-stripe">
				<ArbTableHeader headers={selectedTokens} />
				<tbody>
					{bestRatesForTokens.length > 0 ? (
						<ArbTableRows rows={bestRatesForTokens} />
					) : (
			  		<tr>
				  		<td colSpan={selectedTokens.length}>No rates</td>
				  	</tr>
					)}
				</tbody>
			</Table>
		</div>
	);
}

function Arbitrage() {
	const [chainId, setChainId] = useState(supportedChainId.POLYGON);

	const validTokenOptions = ARBITRAGE_TOKENS[chainId];
	const validDexOptions = getValidDexesByChain(chainId);

	const [selectedTokens, setSelectedTokens] = useState(validTokenOptions);
	const [selectedDexes, setSelectedDexes] = useState(validDexOptions);
	const [selectedFeeRates, setSelectedFeeRates] = useState(FEE_RATES);
	const [useGasAdjusted, setUseGasAdjusted] = useState(false);
	const [invertPrices, setInvertPrices] = useState(false);

	const {
		data: usdPricesForBaseAmounts = [],
		isLoading: usdPricesIsLoading,
		refetch: usdPricesRefetch,
		isFetching: usdPricesIsFetching,
	} = useGetUsdPriceFromUniQuery({
		chainId,
		tokenAddresses: selectedTokens.map(t => t.address),
		fast: true,
	});

	const quoteBaseAmountsByAddress = useMemo(() => {
		return usdPricesForBaseAmounts.reduce((acc, priceItem) => {
			const [Token] = getTokens(priceItem.quoteToken);
			const amount = useGasAdjusted ? priceItem.quoteAmountGasAdjusted : priceItem.quoteAmount;
			const parsedAmount = ethers.utils.parseUnits(amount, Token.decimals);

			acc[Token.address] = {
				Token: Token,
				amount: parsedAmount,
			};

			return acc;
		}, {});
	}, [usdPricesForBaseAmounts, useGasAdjusted]);

	const {
		data: allPairAddresses = [],
		isLoading: poolsAddressesIsLoading,
	} = useGetPoolsAddressesQuery({
		chainId,
		dexes: validDexOptions,
		tokens: validTokenOptions.map(t => t.address),
		feeRates: FEE_RATES
	});

	const [selectedV2Pools, selectedV3Pools] = useMemo(() => {
		const selectedTokensByAddressSet = new Set(selectedTokens.map(t => t.address));

		const selectedPairAddresses = allPairAddresses.filter(
			({dex, feeRate, token0Address, token1Address, pairAddress}) => (
				selectedDexes.includes(dex) &&
				selectedTokensByAddressSet.has(token0Address) &&
				selectedTokensByAddressSet.has(token1Address) &&
				(dex === 'uniswap' ? selectedFeeRates.includes(feeRate) : true)
			)
		);

		const selectedV2Pools = [];
		const selectedV3Pools = [];

		selectedPairAddresses.forEach(
			p => p.dex === 'uniswap' && p.feeRate ? selectedV3Pools.push(p) : selectedV2Pools.push(p)
		);

		return [selectedV2Pools, selectedV3Pools];
	}, [selectedTokens, allPairAddresses, selectedDexes, selectedFeeRates]);

	const {
		data: v2PoolsLiqData = [],
		isLoading: v2PoolsLiqDataIsLoading,
		refetch: v2PoolsLiqDataRefetch,
		isFetching: v2PoolsLiqDataIsFetching,
	} = useGetPoolsLiquidityQuery({ chainId, pools: selectedV2Pools });

	const {
		data: v3SwapRatesData = [],
		isLoading: v3SwapRatesDataIsLoading,
		isFetching: v3SwapRatesDataIsFetching,
	} = useGetUniV3QuotesQuery({
		chainId,
		pools: selectedV3Pools.map(p => ({
			pairAddress: p.pairAddress,
			token0Address: p.token0Address,
			token1Address: p.token1Address,
			feeRate: p.feeRate,
		})),
		quoteBaseAmountsByAddress: Object.entries(quoteBaseAmountsByAddress).reduce(
			(acc, [address, {Token, amount}]) => {
				acc[address] = amount.toString();
				return acc;
			}, {})
	});

	const isLoading = usdPricesIsLoading || poolsAddressesIsLoading || v2PoolsLiqDataIsLoading || v3SwapRatesDataIsLoading;

	const isFetching = usdPricesIsFetching || v2PoolsLiqDataIsFetching || v3SwapRatesDataIsFetching;

	function refetch() {
		usdPricesRefetch(); //this triggers v3SwapRatesData to refetch
		v2PoolsLiqDataRefetch();
	}

	return (
		<Container className="Arbitrage py-4 py-lg-5" fluid="md">
			<Card className="rounded">
				<Card.Header className="py-3 px-2.5 bg-white">
					<Config
						chainId={chainId}
						setChainId={setChainId}
						validDexOptions={validDexOptions}
						selectedDexes={selectedDexes}
						setSelectedDexes={setSelectedDexes}
						validTokenOptions={validTokenOptions}
						selectedTokens={selectedTokens}
						setSelectedTokens={setSelectedTokens}
						selectedFeeRates={selectedFeeRates}
						setSelectedFeeRates={setSelectedFeeRates}
						invertPrices={invertPrices}
						setInvertPrices={setInvertPrices}
						useGasAdjusted={useGasAdjusted}
						setUseGasAdjusted={setUseGasAdjusted}
						refetch={refetch}
						isFetching={isFetching}
					/>
				</Card.Header>
				<Card.Body>
					{isLoading ? (
						<FullLoader text="Loading arb table" flat={true} classNameOverrides="p-0" />
					) : (
						<ArbTable
							selectedTokens={selectedTokens}
							usdPricesForBaseAmounts={usdPricesForBaseAmounts}
							v2PoolsLiqData={v2PoolsLiqData}
							v3SwapRatesData={v3SwapRatesData}
							quoteBaseAmountsByAddress={quoteBaseAmountsByAddress}
							invertPrices={invertPrices}
						/>
					)}
				</Card.Body>
			</Card>
		</Container>
	)
}

export default Arbitrage;
