export const defaultWallet = "0xf4Ce1DA492110616024D401F8C659ff395851726";
export const defaultEncryptedWalletJSON = "{\"address\":\"f4ce1da492110616024d401f8c659ff395851726\",\"id\":\"5a067434-df08-42ba-ac20-41dab16cf4c4\",\"version\":3,\"Crypto\":{\"cipher\":\"aes-128-ctr\",\"cipherparams\":{\"iv\":\"8b85ec5a5120c46fc76d31edbede58cb\"},\"ciphertext\":\"c653cc1e99d9a1908d738b312f2f6e61860a9cc8e614bf43b71edb5327cb02b5\",\"kdf\":\"scrypt\",\"kdfparams\":{\"salt\":\"c3705726e2038188e1b1859c912625780c4f4910680906a1ce87fdb13821488a\",\"n\":131072,\"dklen\":32,\"p\":1,\"r\":8},\"mac\":\"41aaae3984aa81ebb6841406c3aee5d8cf3d5cd7729e73c0e1a76db51ab67909\"}}";

export const highlineWallets = [
	{
		name: 'HL Stable/Stable',
		wallet: '0xDecf569f96Cdc9932CcC28095f245faF93C2e8ea',
	},
	{
		name: 'HL Stable/Token',
		wallet: '0x5d48d97e8bBfA09eeD8e65A5251d5246d6A8D3b1',
	},
	{
		name: 'HL Token/Token',
		wallet: '0xbBBa2EF4D15Ea2d39Ddf56873D262BF82dA415b6',
	}
];

export const walletNamesMap = {
	["0xf4Ce1DA492110616024D401F8C659ff395851726".toLowerCase()]: "Adam",
	["0xDecf569f96Cdc9932CcC28095f245faF93C2e8ea".toLowerCase()]: "HL Stable/Stable",
	["0x5d48d97e8bBfA09eeD8e65A5251d5246d6A8D3b1".toLowerCase()]: "HL Stable/Token",
	["0xbBBa2EF4D15Ea2d39Ddf56873D262BF82dA415b6".toLowerCase()]: "HL Token/Token",
};
