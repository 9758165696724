import { toast } from 'react-toastify';
import getBrowserExtensionProvider from 'util/getBrowserExtensionProvider';

const AUTO_CLOSE = {autoClose: 1500};

const TransactionStatus = {
	Success: 1,
	Failed: 0,
};

async function txNotification(txHash, onCompleteCallback) {
	if (!txHash) return toast.error('Transaction error', AUTO_CLOSE);

	const provider = getBrowserExtensionProvider();

	if (provider) {
		toast.success('Transaction sent', AUTO_CLOSE);

		provider.once(txHash, (transaction) => {
			if (transaction.status === TransactionStatus.Success) {
				toast.success('Transaction completed', AUTO_CLOSE);

				if (typeof onCompleteCallback === 'function') {
					onCompleteCallback({success: true});
				}
			} else {
				toast.error('Transaction failed', AUTO_CLOSE);

				if (typeof onCompleteCallback === 'function') {
					onCompleteCallback({success: false});
				}
			}
		});
	}
};

export default txNotification;
