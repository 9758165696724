import queryConstructor from '../../util/queryConstructor';
import { keepUnusedDataFor } from './constants';

export const handlers = (builder) => ({
	getSwap: builder.query({
		query: ({chainId, wallet, inputTokenAddress, outputTokenAddress, inputTokenQuantity, outputTokenQuantity, slippage = 0.001}) => {
			const queryParams = queryConstructor({
				chainId, wallet, inputTokenAddress, outputTokenAddress, inputTokenQuantity, outputTokenQuantity, slippage,
			});

			if (
				!inputTokenAddress ||
				!outputTokenAddress ||
				(!inputTokenQuantity && !outputTokenQuantity)
			) {
				return { data: {} };
			};

			return `swap/fetchSwap?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getUniV3Quote: builder.query({
		query: ({chainId, pairAddress, feeRate, token0Address, token1Address, token0AmountIn, token1AmountIn}) => {
			const queryParams = queryConstructor({
				chainId, pairAddress, feeRate, token0Address, token1Address, token0AmountIn, token1AmountIn,
			});

			return `swap/fetchUniV3Quote?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
});

export default handlers;
