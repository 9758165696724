import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus, faSquareMinus } from '@fortawesome/pro-duotone-svg-icons';
import Flex from '../../../components/Flex';

function HideCardTitle(props) {
	const { title, hide, setHide } = props;

	return (
		<Flex
			justify="between"
			align="center"
			className="text-secondary py-2.5 px-3 pointer title-container"
			onClick={() => setHide(!hide)}
		>
			<h6 className="mb-0 text-gray-800">
				{title}
			</h6>
			<FontAwesomeIcon icon={hide ? faSquarePlus : faSquareMinus} className="text-right"/>
		</Flex>
	);
}

export default HideCardTitle;
