import { computePoolAddress } from '@uniswap/v3-sdk';
import { useState, useEffect } from 'react';
import { V3_FACTORY_ADDRESS } from 'constants/addresses';

function useGetUniV3PairAddress(token0, token1, fee) {
  const [pairAddress, setPairAddress] = useState(undefined);

  useEffect(() => {
    if (token0 && token1 && fee) {
      const _pairAddress = computePoolAddress({
        factoryAddress: V3_FACTORY_ADDRESS,
        tokenA: token0,
        tokenB: token1,
        fee: fee,
      });

      setPairAddress(_pairAddress);
    } else {
      setPairAddress(undefined);
    }
  }, [token0, token1, fee]);

  return pairAddress;
};

export default useGetUniV3PairAddress;
