import supportedChainId from './chains';

const altLogos = {
	[supportedChainId.MAINNET]: {
    "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
		"0xf57e7e7c23978c3caec3c3548e3d615c346e79ff": "https://assets.coingecko.com/coins/images/17233/small/imx.png?1636691817",
		"0x9ab7bb7fdc60f4357ecfef43986818a2a3569c62": "https://assets.coingecko.com/coins/images/17362/small/V2QDNoLg_400x400.jpg?1640054187",
		"0xcafe001067cdef266afb7eb5a286dcfd277f3de5": "https://assets.coingecko.com/coins/images/20403/small/ep7GqM19_400x400.jpg?1636979120",
		"0xf32aa187d5bc16a2c02a6afb7df1459d0d107574": "https://assets.coingecko.com/coins/images/16392/small/hachikoinu-200px.png?1626355242",
		"0x0ab87046fbb341d058f17cbc4c1133f25a20a52f": "https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png?1638764900",
    "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b": "https://s2.coinmarketcap.com/static/img/coins/64x64/21882.png",
	},
  [supportedChainId.OPTIMISM]: {
    "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58": "https://optimistic.etherscan.io/token/images/tether_32.png",
    "0x7F5c764cBc14f9669B88837ca1490cCa17c31607" :"https://optimistic.etherscan.io/token/images/usdc_32.png",
    "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1": "https://optimistic.etherscan.io/token/images/MCDDai_32.png",
    "0x4200000000000000000000000000000000000006": "https://optimistic.etherscan.io/token/images/weth_28.png",
    "0x68f180fcCe6836688e9084f035309E29Bf0A2095": "https://optimistic.etherscan.io/token/images/wbtc_28.png",
    "0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6": "https://optimistic.etherscan.io/token/images/chainlink_32.png",
    "0x2E3D870790dC77A83DD1d18184Acc7439A53f475": "https://optimistic.etherscan.io/token/images/fraxop_32.png",
    "0xC22885e06cd8507c5c74a948C59af853AEd1Ea5C": "https://optimistic.etherscan.io/token/images/usddtron_32.png",
    "0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9": "https://s2.coinmarketcap.com/static/img/coins/64x64/2927.png",
    "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b": "https://s2.coinmarketcap.com/static/img/coins/64x64/21882.png",
  },
	[supportedChainId.BINANCE]: {
    "0x2170ed0880ac9a755fd29b2688956bd959f933f8": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/ETH.svg",
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": "https://bscscan.com/token/images/binance_32.png",
    "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/ADA.svg",
    "0x55d398326f99059ff775485246999027b3197955": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/USDT.svg",
    "0x7083609fce4d1d8dc0c979aab8c869ea2c873402": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/DOT.svg",
    "0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/XRP.svg",
    "0xbf5140a22578168fd562dccf235e5d43a02ce9b1": "https://bscscan.com/token/images/uniswap_32.png",
    "0x4338665cbb7b2485a8855a139b75d5e34ab0db94": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/LTC.svg",
    "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/LINK.svg",
    "0x8ff795a6f4d97e7887c79bea79aba5cc76444adf": "https://bscscan.com/token/images/bitcoincash_32.png",
    "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/USDC.svg",
    "0xba2ae424d960c26247dd6c32edc70b295c744c43": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/DOGE.svg",
    "0x56b6fb708fc5732dec1afc8d8556423a2edccbd6": "https://bscscan.com/token/images/eos_32.png",
    "0x0eb3a705fc54725037cc9e008bdede697f62f335": "https://bscscan.com/token/images/cosmos_32.png",
    "0x16939ef78684453bfdfb47825f8a5f714f12623a": "https://bscscan.com/token/images/tezos_32.png",
    "0xe9e7cea3dedca5984780bafc599bd69add087d56": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BUSD.svg",
    "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/DAI.svg",
    "0x9ac983826058b8a9c7aa1c9171441191232e8404": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/snx.svg",
    "0x52ce071bd9b1c4b00a0b92d298c512478cad67e8": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/COMP.svg",
    "0x5f0da599bb2cccfcf6fdfd7d81743b6020864350": "https://bscscan.com/token/images/makerdao_32.png",
    "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/CAKE.svg",
    "0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/ZEC.svg",
    "0x3d6545b08693dae087e957cb1180ee38b9e3c25e": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/ETC.svg",
    "0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e": "https://bscscan.com/token/images/yfi_32.png",
    "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BTCB.svg",
    "0x1fa4a73a3f0133f0025378af00236f3abdee5d63": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/near.svg",
    "0x101d82428437127bf1608f699cd651e6abf9766e": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BAT.svg",
    "0xfd7b3a77848f1c2d67e05e54d78d174a0c850335": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/ONT.svg",
    "0x23396cf899ca06c4472205fc903bdb4de249d6fc": "https://bscscan.com/token/images/terra-ust_32.png",
    "0xb7f8cd00c5a06c0537e2abff0b58033d02e5e094": "https://bscscan.com/token/images/paxos_32.png",
    "0x111111111117dc0aa78b770fa6a738034120c302": "https://bscscan.com/token/images/1inch_32.png",
    "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63": "https://bscscan.com/token/images/venus_32.png",
    "0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e": "https://bscscan.com/token/images/reeffinance_32.png",
    "0x9678e42cebeb63f23197d726b29b1cb20d0064e5": "https://bscscan.com/token/images/iotex_32.png",
    "0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2": "https://bscscan.com/token/images/dodobird_32.png",
    "0x5b6dcf557e2abe2323c48445e8cc948910d8c2c9": "https://bscscan.com/token/images/mirrorfinance_32.png",
    "0xa1faa113cbe53436df28ff0aee54275c13b40975": "https://bscscan.com/token/images/alpha_32.png?=v2",
    "0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18": "https://bscscan.com/token/images/bandtoken_32.png",
    "0xd475c9c934dcd6d5f1cac530585aa5ba14185b92": "https://bscscan.com/token/images/bch-abc_32.png",
    "0xf307910a4c7bbc79691fd374889b36d8531b08e3": "https://bscscan.com/token/images/ankr_32.png",
    "0x47bead2563dcbf3bf2c9407fea4dc236faba485a": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/SXP.svg",
    "0x882c173bc7ff3b7786ca16dfed3dfffb9ee7847b": "https://bscscan.com/token/images/venus-vbtc_32.png",
    "0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb": "https://bscscan.com/token/images/safepal_32.png",
    "0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BAKE.svg",
    "0xb59490ab09a0f526cc7305822ac65f2ab12f9723": "https://bscscan.com/token/images/litentry_32.png",
    "0xa3f020a5c92e15be13caf0ee5c95cf79585eecc9": "https://bscscan.com/token/images/aelf_32.png",
    "0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd": "https://bscscan.com/token/images/ltonetwork_32.png",
    "0x4bd17003473389a42daf6a0a729f6fdb328bbbd7": "https://bscscan.com/token/images/venus-vai_32.png",
    "0x4b0f1812e5df2a09796481ff14017e6005508003": "https://bscscan.com/token/images/trust_32.png?v=1",
    "0x7950865a9140cb519342433146ed5b40c6f210f7": "https://bscscan.com/token/images/paxosgold_32.png",
    "0xf218184af829cf2b0019f8e6f0b2423498a36983": "https://bscscan.com/token/images/mathwallet_32.png",
    "0xCa3F508B8e4Dd382eE878A314789373D80A5190A": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BIFI.png",
    "0xeca88125a5adbe82614ffc12d0db554e2e2867c8": "https://bscscan.com/token/images/venus-vusdc_32.png",
    "0x7f70642d88cf1c4a3a7abb072b53b929b653eda5": "https://bscscan.com/token/images/dfimoney_32.png",
    "0x96dd399f9c3afda1f194182f71600f1b65946501": "https://bscscan.com/token/images/contentos_32.png",
    "0xd4cb328a82bdf5f03eb737f37fa6b370aef3e888": "https://bscscan.com/token/images/creamfinance_32.png",
    "0xaf53d56ff99f1322515e54fdde93ff8b3b7dafd5": "https://bscscan.com/token/images/Prometeus_32.png",
    "0xe4ae305ebe1abe663f261bc00534067c80ad677c": "https://bscscan.com/token/images/spartan_32.png",
    "0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929": "https://bscscan.com/token/images/certik_32.png",
    "0xf508fcd89b8bd15579dc79a6827cb4686a3592c8": "https://bscscan.com/token/images/venus-veth_32.png",
    "0xf68c9df95a18b2a5a5fa1124d79eeeffbad0b6fa": "https://bscscan.com/token/images/anyswap_32.png",
    "0xf952fc3ca7325cc27d15885d37117676d25bfda6": "https://bscscan.com/token/images/goosedefi_32.png",
    "0xae9269f27437f0fcbc232d39ec814844a51d6b8f": "https://bscscan.com/token/images/burgerswap_32.png",
    "0x95c78222b3d6e262426483d42cfa53685a67ab9d": "https://bscscan.com/token/images/venus-vbusd_32.png",
    "0x5a41f637c3f7553dba6ddc2d3ca92641096577ea": "https://bscscan.com/token/images/justliquidity-juld_32.png",
    "0xa184088a740c695e156f91f5cc086a06bb78b827": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/AUTO.svg",
    "0x1c9491865a1de77c5b6e19d2e6a5f1d7a6f2b25f": "https://bscscan.com/token/images/antimatter_32.png",
    "0xfd5840cd36d94d7229439859c0112a4185bc0255": "https://bscscan.com/token/images/venus-vusdt_32.png",
    "0x968f6f898a6df937fc1859b323ac2f14643e3fed": "https://bscscan.com/token/images/newscrypto_32.png?=v1",
    "0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4": "https://bscscan.com/token/images/stablexswap_32.png",
    "0x8f0528ce5ef7b51152a59745befdd91d97091d2f": "https://bscscan.com/token/images/alpacafinance_32.png",
    "0x948d2a81086a075b3130bac19e4c6dee1d2e3fe8": "https://bscscan.com/token/images/helmet_32.png",
    "0x658a109c5900bc6d2357c87549b651670e5b0539": "https://bscscan.com/token/images/forceprotocol_32.png",
    "0x2ff3d0f6990a40261c66e1ff2017acbc282eb6d0": "https://bscscan.com/token/images/venus-vsxp_32.png",
    "0xca0a9df6a8cad800046c1ddc5755810718b65c44": "https://bscscan.com/token/images/tokenclub_32.png",
    "0x650b940a1033b8a1b1873f78730fcfc73ec11f1f": "https://bscscan.com/token/images/venus-vlink_32.png",
    "0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830": "https://bscscan.com/token/images/berrydata_32.png",
    "0x57a5297f2cb2c0aac9d554660acd6d385ab50c6b": "https://bscscan.com/token/images/venus-vltc_32.png",
    "0x1d1eb8e8293222e1a29d2c0e4ce6c0acfd89aaac": "https://bscscan.com/token/images/hakkafinance_32.png",
    "0x1610bc33319e9398de5f57b33a5b184c806ad217": "https://bscscan.com/token/images/venus-vdot_32.png",
    "0x12e34cdf6a031a10fe241864c32fb03a4fdad739": "https://bscscan.com/token/images/freecoin_32.png",
    "0xe6df05ce8c8301223373cf5b969afcb1498c5528": "https://bscscan.com/token/images/koge_32.png",
    "0xcd7c5025753a49f1881b31c48caa7c517bb46308": "https://bscscan.com/token/images/raven_32.png",
    "0x334b3ecb4dca3593bccc3c7ebd1a1c1d1780fbf1": "https://bscscan.com/token/images/venus-vdai_32.png",
    "0x393b312c01048b3ed2720bf1b090084c09e408a1": "https://bscscan.com/token/images/fry_32.png",
    "0xe10e9822a5de22f8761919310dda35cd997d63c0": "https://bscscan.com/token/images/thugs-2_32.png?=v2",
    "0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4": "https://bscscan.com/token/images/billionhappiness_32.png?v=2",
    "0x34681c1035f97e1edcccec5f142e02ff81a3a230": "https://bscscan.com/token/images/cubiex_32.png",
    "0x233d91a0713155003fc4dce0afa871b508b3b715": "https://bscscan.com/token/images/ditto_32.png",
    "0x6306e883493824ccf606d90e25f68a28e47b98a3": "https://bscscan.com/token/images/extendfinance_32.png?=v1",
    "0xc1d99537392084cc02d3f52386729b79d01035ce": "https://bscscan.com/token/images/staysbase_32.png",
    "0x5f0388ebc2b94fa8e123f404b79ccf5f40b29176": "https://bscscan.com/token/images/venus-vbch_32.png",
    "0xa94b7a842aadb617a0b08fa744e033c6de2f7595": "https://bscscan.com/token/images/flashx_32.png",
    "0x90df11a8cce420675e73922419e3f4f3fe13cccb": "https://bscscan.com/token/images/streamity_32.png",
    "0x4f0ed527e8a95ecaa132af214dfd41f30b361600": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/VBSWAP.svg",
    "0x250632378e573c6be1ac2f97fcdf00515d0aa91b": "https://bscscan.com/token/images/binance-beth_32.png",
    "0xc2e1acef50ae55661855e8dcb72adb182a3cc259": "https://bscscan.com/token/images/boltshare_32.png",
    "0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a": "https://bscscan.com/token/images/jetfuel_32.png",
    "0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51": "https://bscscan.com/token/images/pancakebunny_32.png",
    "0x4197c6ef3879a08cd51e5560da5064b773aa1d29": "https://bscscan.com/token/images/acryptos_32.png",
    "0x972207a639cc1b374b893cc33fa251b55ceb7c07": "https://bscscan.com/token/images/venus-vbeth_32.png",
    "0x5ac52ee5b2a633895292ff6d8a89bb9190451587": "https://bscscan.com/token/images/bscex_32.png",
    "0x8f9b482b74afc0b7e8aefc704f1f04df208ee332": "https://bscscan.com/token/images/vancifinance_32.png",
    "0x60b3bc37593853c04410c4f07fe4d6748245bf77": "https://bscscan.com/token/images/shieldprotocol_32.png",
    "0xcfdf8a80fecaeecc144fa74c0df8691bfd0e26e3": "https://bscscan.com/token/images/kiwiswap_32.png",
    "0x77f6a5f1b7a2b6d6c322af8581317d6bb0a52689": "https://bscscan.com/token/images/sporeengineering_32.png",
    "0x5ea29eee799aa7cc379fde5cf370bc24f2ea7c81": "https://bscscan.com/token/images/keep3rb_32.png",
    "0x72f28c09be1342447fa01ebc76ef508473d08c5c": "https://bscscan.com/token/images/unimex_32.png",
    "0x4f47a0d15c1e53f3d94c069c7d16977c29f9cb6b": "https://bscscan.com/token/images/ramenswap_32.png",
    "0x2cc26dd730f548dc4ac291ae7d84a0c96980d2cb": "https://bscscan.com/token/images/pizzafinance_32.png",
    "0x29f350b3822f51dc29619c583adbc9628646e315": "https://bscscan.com/token/images/7up_32.png",
    "0xb9784c1633ef3b839563b988c323798634714368": "https://bscscan.com/token/images/phoswap_32.png",
    "0xd1102332a213e21faf78b69c03572031f3552c33": "https://bscscan.com/token/images/batdollar-btd_32.png",
    "0x151b1e2635a717bcdc836ecd6fbb62b674fe3e1d": "https://bscscan.com/token/images/venus-vxvs_32.png",
    "0xf91d58b5ae142dacc749f58a49fcbac340cb0343": "https://bscscan.com/token/images/venus-vfil_32.png",
    "0xbe3e4cfd929156f612df36042d79201ecf5344d7": "https://bscscan.com/token/images/weatherfinance_32.png",
    "0xadd8a06fd58761a5047426e160b2b88ad3b9d464": "https://bscscan.com/token/images/cheeseswap_32.png?=v1",
    "0xab9d0fae6eb062f2698c2d429a1be9185a5d4f6e": "https://bscscan.com/token/images/pastafinance_32.png",
    "0x9d8aac497a4b8fe697dd63101d793f0c6a6eebb6": "https://bscscan.com/token/images/defi100_32.png",
    "0x9066e87Bac891409D690cfEfA41379b34af06391": "https://bscscan.com/token/images/tacofinance_32.png",
    "0x5f2caa99fc378248ac02cbbaac27e3fa155ed2c4": "https://bscscan.com/token/images/jointer_32.png",
    "0x762539b45a1dcce3d36d080f74d1aed37844b878": "https://bscscan.com/token/images/linear_32.png",
    "0x7f4959528D4C9c7379b3437C7Eea40376fD96B8a": "https://bscscan.com/token/images/genta_32.png",
    "0x007ea5c0ea75a8df45d288a4debdd5bb633f9e56": "https://bscscan.com/token/images/canya_32.png",
    "0xb248a295732e0225acd3337607cc01068e3b9c10": "https://bscscan.com/token/images/venus-vxrp_32.png",
    "0x1ad8d89074afa789a027b9a31d0bd14e254711d0": "https://bscscan.com/token/images/crossfin_32.png",
    "0x9b44df3318972be845d83f961735609137c4c23c": "https://bscscan.com/token/images/payrue_32.png",
    "0x9a2f5556e9a637e8fbce886d8e3cf8b316a1d8a2": "https://bscscan.com/token/images/BIDR-0E9_32.png",
    "0x8da443f84fea710266c8eb6bc34b71702d033ef2": "https://bscscan.com/token/images/ctsi_32.png",
    "0x0000000000004946c0e9f43f4dee607b0ef1fa1c": "https://bscscan.com/token/images/chi_1inch_32.png",
    "0x81c15d3e956e55e77e1f3f257f0a65bd2725fc55": "https://bscscan.com/token/images/crADA_32.png",
    "0x4a9a2b2b04549c3927dd2c9668a5ef3fca473623": "https://bscscan.com/token/images/dforce_32.png",
    "0xdc0f0a5719c39764b011edd02811bd228296887c": "https://bscscan.com/token/images/dosnetwork_32.png",
    "0x15B3d410fCd0d695E1bbe4F717F8E1B6D0Fb2D0C": "https://bscscan.com/token/images/fry-ketchup_32.png",
    "0xdf1f0026374d4bcc490be5e316963cf6df2fff19": "https://bscscan.com/token/images/innbc_32-1.png",
    "0x8e9f5173e16ff93f81579d73a7f9723324d6b6af": "https://bscscan.com/token/images/milk_32.png?v=1",
    "0x8893d5fa71389673c5c4b9b3cb4ee1ba71207556": "https://bscscan.com/token/images/squirrel_32.png",
    "0x26a5dfab467d4f58fb266648cae769503cec9580": "https://bscscan.com/token/images/benchmark_32.png",
    "0x9bd547446ea13c0c13df2c1885e1f5b019a77441": "https://bscscan.com/token/images/tree_32.png",
    "0x42f6f551ae042cbe50c739158b4f0cac0edb9096": "https://bscscan.com/token/images/nerve_32.png?v=2",
    "0xae13d989dac2f0debff460ac112a837c89baa7cd": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/wbnb.png",
    "0x4Fb99590cA95fc3255D9fA66a1cA46c43C34b09a": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BANANA.svg",
    "0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BANANA.svg",
    "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BUSD.svg",
    "0x6ce8da28e2f864420840cf74474eff5fd80e65b8": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BTCB.svg",
    "0xd66c6b4f0be8ce5b39d52e0fd1344c389929b378": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/ETH.svg",
    "0x67d012F731c23F0313CEA1186d0121779c77fcFE": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/SOUL.svg",
    "0x790be81c3ca0e53974be2688cdb954732c9862e1": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BREW.svg",
    "0x97a30c692ece9c317235d48287d23d358170fc40": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/CRX.svg",
    "0x36C0556c2B15aED79F842675Ff030782738eF9e8": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BLUE.png",
    "0x94BaBBE728D9411612Ee41b20241a6FA251b26Ce": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/GFORCE.png",
    "0x947950bcc74888a40ffa2593c5798f11fc9124c4": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/SUSHI.svg",
    "0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/NUTS.svg",
    "0x05b339b0a346bf01f851dde47a5d485c34fe220c": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/NAUT.png",
    "0xc4daa5a9f2b832ed0f9bc579662883cd53ea9d61": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BRICK.png",
    "0xeda21b525ac789eab1a08ef2404dd8505ffb973d": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/HPS.png",
    "0x7b0409a3a3f79baa284035d48e1dfd581d7d7654": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/RUPEE.svg",
    "0xba26397cdff25f0d26e815d218ef3c77609ae7f1": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/LYPTUS.png",
    "0x0491648c910ad2c1afaab733faf71d30313df7fc": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/JDI.png",
    "0xd944f1d1e9d5f9bb90b62f9d45e447d989580782": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/IOTA.png",
    "0x4354a4f710182966e55ea30cfa807fa1b821a67b": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/FOXY.png",
    "0xc5a49b4cbe004b6fd55b30ba1de6ac360ff9765d": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/SWAMP.svg",
    "0x5ef5994fa33ff4eb6c82d51ee1dc145c546065bd": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/ALLOYv2.svg",
    "0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/KEYFI.png",
    "0xe5904e9816b309d3ed4d061c922f5aa8f3b24c92": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/NFTL.jpg",
    "0xa4f93159ce0a4b533b443c74b89967c60a5969f8": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BFT.png",
    "0xa8c514d991f59bab02d32b68f04204cb89261c88": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/SAFEP.png",
    "0xB0F2939A1c0e43683E5954c9Fe142F7df9F8D967": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/GEN.png",
    "0x8efa59bf5f47c6fe0e97c15cad12f2be6bb899a1": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/gRUPEE.svg",
    "0xda20c8a5c3b1ab48e31ba6e43f0f2830e50218d8": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BINGUS.png",
    "0xab111d5948470ba73d98d66bbdf2798fbe093546": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/XBTC.svg",
    "0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/WATCH.png",
    "0x935a544bf5816e3a7c13db2efe3009ffda0acda2": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/blz.svg",
    "0xadbaf88b39d37dc68775ed1541f1bf83a5a45feb": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/coti.svg",
    "0x1ce0c2827e2ef14d5c4f29a091d735a204794041": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/avax.png",
    "0xcc42724c6683b7e57334c4e856f4c9965ed682bd": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/MATIC.svg",
    "0xfb6115445bff7b52feb98650c87f44907e58f802": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/AAVE.svg",
    "0xad29abb318791d579433d831ed122afeaf29dcfe": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/FTM.svg",
    "0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/NRV.png",
    "0xb1ced2e320e3f4c8e3511b1dc59203303493f382": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/MOONLIGHT.svg",
    "0xf4a037785b3ae6c70f9ed71fa2084b3589cabd46": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/MEMEX.svg",
    "0x0acbb2c3d3826b82b17c09e2dfa605b5279e0c63": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/DEFY.svg",
    "0xf63400ee0420ce5b1ebdee0c942d7de1c734a41f": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/TAPE.svg",
    "0xea90DC6F64d18771Ca1dac8098526a9082265B42": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/DINOP.svg",
    "0xe486a69e432fdc29622bf00315f6b34c99b45e80": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/SPACE.svg",
    "0x1f9f6a696c6fd109cd3956f45dc709d2b3902163": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/Celer.svg",
    "0x2859e4544c4bb03966803b044a93563bd2d0dd4d": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/shib.png",
    "0x0Ef0626736c2d484A792508e99949736D0AF807e": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BCA.svg",
    "0xbcf39f0edda668c58371e519af37ca705f2bfcbd": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/pCWS.png",
    "0x4090e535f2e251f5f88518998b18b54d26b3b07c": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/publichttps://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/TYPH.svg",
    "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270": "https://app.uniswap.org/static/media/matic-token-icon.da7b877d.svg",
    "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174": "https://optimistic.etherscan.io/token/images/usdc_32.png",
    "0xc2132D05D31c914a87C6611C10748AEb04B58e8F": "https://optimistic.etherscan.io/token/images/tether_32.png",
    "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/DAI.svg",
    "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6": "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6/logo.png",
    "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619": "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/logo.png",
    "0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/wbnb.png",
    "0x5d47baba0d66083c52009271faf3f50dcc01023c": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/BANANA.svg",
    "0x5E061239f6Dd8D8035bb341AA8ef8Be6026e80fa": "https://optimistic.etherscan.io/token/images/usdc_32.png",
    "0x3fe4c0de72ef1b25e146aaa2c836df365e21dfd6": "https://optimistic.etherscan.io/token/images/tether_32.png",
    "0x7ab920bb77d89cea9932b9dee8c644e3f39a2e22": "https://raw.githubusercontent.com/ApeSwapFinance/apeswap-dex/main/public/images/coins/DAI.svg",
    "0xe552fb52a4f19e44ef5a967632dbc320b0820639": "https://assets.coingecko.com/coins/images/15595/small/metis.PNG?1621298076",
    "0x6bcd897d4ba5675f860c7418ddc034f6c5610114": "https://bscscan.com/token/images/rainmaker_32.png",
    "0x6855f7bb6287f94ddcc8915e37e73a3c9fee5cf3": "https://bscscan.com/token/images/stackos_32.png",
    "0x00e1656e45f18ec6747f5a8496fd39b50b38396d": "https://bscscan.com/token/images/bombcrypto_32.png",
    "0xf73d8276c15ce56b2f4aee5920e62f767a7f3aea": "https://assets.coingecko.com/coins/images/18035/small/TCG2.jpg?1630306761",
    "0x9c1a3e3a69f83bdf98a51e4a552bbc2e479d45e7": "https://assets.coingecko.com/coins/images/13219/small/bundles_finance_logo.jpg?1606294826",
    "0x9029fdfae9a03135846381c7ce16595c3554e10a": "https://bscscan.com/token/images/openocean_32.png",
    "0x80d04e44955aa9c3f24041b2a824a20a88e735a8": "https://bscscan.com/token/images/mvc_32.png",
    "0x40c8225329bd3e28a043b029e0d07a5344d2c27c": "https://assets.coingecko.com/coins/images/22133/small/aog.PNG?1640905715",
    "0x0cd022dde27169b20895e0e2b2b8a33b25e63579": "https://bscscan.com/token/images/everrise2_32.png",
    "0x8a65b987d9813f0a97446eda0de918b2573ae406": "https://assets.coingecko.com/coins/images/21934/small/qSCAqd6m_400x400.jpg?1640301488",
    "0x03f8fdc10d5bcf7508375585b04e93d656d36954": "https://assets.coingecko.com/coins/images/19266/small/12952.png?1634855953",
    "0xf1968d4113e87e88cf50e6f0e1820dcbd29c4a90": "https://s2.coinmarketcap.com/static/img/coins/64x64/16542.png",
    "0x8c282ea9eacd1b95d44a3a18dcdd1d0472868998": "https://assets.coingecko.com/coins/images/22631/small/ancient_kingdom.PNG?1642343232",
    "0xa77346760341460b42c230ca6d21d4c8e743fa9c": "https://assets.coingecko.com/coins/images/19175/small/pets.png?1644048894",
    "0x5ac5e6Af46Ef285B3536833E65D245c49b608d9b": "https://s2.coinmarketcap.com/static/img/coins/64x64/16754.png",
    "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b": "https://s2.coinmarketcap.com/static/img/coins/64x64/21882.png",
	},
	[supportedChainId.POLYGON]: {
		"0xdf9b4b57865b403e08c85568442f95c26b7896b0": "https://assets.coingecko.com/coins/images/22198/small/download.png?1641192480",
		"0x430ef9263e76dae63c84292c3409d61c598e9682": "https://s2.coinmarketcap.com/static/img/coins/64x64/9308.png",
		"0x24834BBEc7E39ef42f4a75EAF8E5B6486d3F0e57": "https://s2.coinmarketcap.com/static/img/coins/64x64/4172.png",
    "0x9cd6746665d9557e1b9a775819625711d0693439": "https://polygonscan.com/token/images/wormholeluna_32.png",
		"0xee800b277a96b0f490a1a732e1d6395fad960a26": "https://s2.coinmarketcap.com/static/img/coins/64x64/4039.png",
		"0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683": "https://s2.coinmarketcap.com/static/img/coins/64x64/6210.png",
		"0x5fe2b58c013d7601147dcdd68c143a77499f5531": "https://s2.coinmarketcap.com/static/img/coins/64x64/6719.png",
		"0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4": "https://s2.coinmarketcap.com/static/img/coins/64x64/1966.png",
		"0xe0339c80ffde91f3e20494df88d4206d86024cdf": "https://s2.coinmarketcap.com/static/img/coins/64x64/9932.png",
		"0x1379e8886a944d2d9d440b3d88df536aea08d9f3": "https://s2.coinmarketcap.com/static/img/coins/64x64/1721.png",
    "0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89": "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png",
    "0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b": "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
    "0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f": "https://s2.coinmarketcap.com/static/img/coins/64x64/15678.png",
    "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619": "https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/logo.png",
    "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39": "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png",
    "0x172370d5Cd63279eFa6d502DAB29171933a610AF": "https://s2.coinmarketcap.com/static/img/coins/64x64/6538.png",
    "0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a": "https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png",
    "0xd93f7E271cB87c23AaA73edC008A79646d1F9912": "https://polygonscan.com/token/images/wormholesolana_32.png",
    "0x42F6bdCfd82547e89F1069Bf375aa60e6c6c063d": "https://s2.coinmarketcap.com/static/img/coins/64x64/11345.png",
    "0xe6469ba6d2fd6130788e0ea9c0a0515900563b59": "https://s2.coinmarketcap.com/static/img/coins/64x64/15610.png",
    "0x692597b009d13c4049a947cab2239b7d6517875f": "https://s2.coinmarketcap.com/static/img/coins/64x64/7129.png",
    "0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9": "https://polygonscan.com/token/images/celsiusxwrappedeth_32.png",
    "0x64875aaa68d1d5521666c67d692ee0b926b08b2f": "https://polygonscan.com/token/images/celciusxcxada_32.png",
    "0xd6df932a45c0f255f85145f286ea0b292b21c90b": "https://polygonscan.com/token/images/aave_32.png",
    "0xB85517b87BF64942adf3A0B9E4c71E4Bc5Caa4e5": "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
    "0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7": "https://polygonscan.com/token/images/binanceusd_32.png",
    "0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f": "https://s2.coinmarketcap.com/static/img/coins/64x64/20317.png",
    "0x750e4C4984a9e0f12978eA6742Bc1c5D248f40ed": "https://s2.coinmarketcap.com/static/img/coins/64x64/21420.png",
    "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b": "https://s2.coinmarketcap.com/static/img/coins/64x64/21882.png",
    "0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4": "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
	},
  [supportedChainId.ARBITRUM]: {
    "0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65": "https://s2.coinmarketcap.com/static/img/coins/64x64/20317.png",
    "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f": "https://arbiscan.io/token/images/wbtc_28.png",
  },
  [supportedChainId.AVALANCHE]: {
    "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7": "https://snowtrace.io/token/images/avax_32.png",
  },
  'all': {},
};

Object.values(altLogos).forEach(obj => {
  Object.entries(obj).forEach(([address, url]) => {
    altLogos['all'][address.toLowerCase()] = url;
  })
});

export default altLogos;
