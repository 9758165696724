import { useState, useEffect, useRef } from 'react';
import JSBI from 'jsbi';
import { Pool, TickMath, TICK_SPACINGS } from '@uniswap/v3-sdk';
import { CurrencyAmount, Percent } from '@uniswap/sdk-core';
import { MAX_UINT128 } from '../../../../../constants/integers';
import { getTokens } from '../../../../../util/tokens';

function useFormatPoolData({ tickData = {}, pool = {}, position = {} }, { skip } = {}) {
	const [formattedData, setFormattedData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const ran = useRef(false);

	useEffect(() => {
		const {
			fee,
			activeTickIdx,
			// tickSpacing,
			ticksProcessed = [],
		} = tickData;

		const [ Token0, Token1 ] = getTokens(pool.Token0, pool.Token1);

		const formatData = async () => {
      try {
        !ran.current && setIsLoading(true);
        setIsFetching(true);

  			const newData = await Promise.all(
  				ticksProcessed.map(async (t, i) => {
  					const active = t.tickIdx === activeTickIdx;
  					const sqrtPriceX96 = TickMath.getSqrtRatioAtTick(t.tickIdx);

            // let mockTicks, pool;

            // try {
            //   mockTicks = [
            //     {
            //       index: t.tickIdx - TICK_SPACINGS[fee],
            //       liquidityGross: t.liquidityGross,
            //       liquidityNet: JSBI.multiply(t.liquidityNet, JSBI.BigInt('-1')),
            //     }, {
            //       index: t.tickIdx,
            //       liquidityGross: t.liquidityGross,
            //       liquidityNet: t.liquidityNet,
            //     },
            //   ];

            //   pool = Token0 && Token1 && fee
            //     ? new Pool(Token0, Token1, fee, sqrtPriceX96, t.liquidityActive, t.tickIdx, mockTicks)
            //     : undefined;

            //   // console.log('worked', t.liquidityActive, t);
            // } catch(err) {
            //   console.log('failed', t.liquidityActive, t);
            //   console.error('Error in useFormatPoolData.js -> ticksProcessed.map() -> new Pool()', err);
            //   // return null;
            // }

            const mockTicks = [
              {
                index: t.tickIdx - TICK_SPACINGS[fee],
                liquidityGross: JSBI.BigInt(t.liquidityGross),
                liquidityNet: JSBI.multiply(JSBI.BigInt(t.liquidityNet), JSBI.BigInt('-1')),
              }, {
                index: t.tickIdx,
                liquidityGross: JSBI.BigInt(t.liquidityGross),
                liquidityNet: JSBI.BigInt(t.liquidityNet),
              },
            ];

            const pool = Token0 && Token1 && fee
              ? new Pool(Token0, Token1, fee, sqrtPriceX96, JSBI.BigInt(t.liquidityActive), t.tickIdx, mockTicks)
              : undefined;

            const nextSqrtX96 = ticksProcessed[i - 1]
              ? TickMath.getSqrtRatioAtTick(ticksProcessed[i - 1].tickIdx)
              : undefined;
            const maxAmountToken0 = pool.token0
            	? CurrencyAmount.fromRawAmount(pool.token0, MAX_UINT128.toString())
            	: undefined;
            const outputRes0 = pool && maxAmountToken0
            	? await pool.getOutputAmount(maxAmountToken0, nextSqrtX96).catch(console.error)
            	: undefined;

            // console.log({
            //   pT0: pool.token0.symbol,
            //   t0: Token0.symbol,
            //   pT1: pool.token1.symbol,
            //   t1: Token1.symbol
            // });

            const token1Amount = outputRes0?.[0];

            const amount0 = token1Amount ? parseFloat(token1Amount.toExact()) * parseFloat(pool.token1Price.toSignificant(18)) : 0;
            const amount1 = token1Amount ? parseFloat(token1Amount.toExact()) : 0;

            const isTickInPosition = (!!position?.tickLower || position?.tickLower === 0) && t.tickIdx >= position?.tickLower && t.tickIdx < position?.tickUpper;
            const positionPercOfTickLiq = isTickInPosition && position?.liqPerTick
              ? new Percent(position.liqPerTick, JSBI.BigInt(t.liquidityActive)).toSignificant(2)
              : 0;

            return {
              index: i,
              tickIdx: t.tickIdx,
              isCurrent: active,
              activeLiquidity: parseFloat(t.liquidityActive),
              price0: parseFloat(pool.token0Price.toSignificant(18)) || parseFloat(t.price0),
              price1: parseFloat(pool.token1Price.toSignificant(18)) || parseFloat(t.price1),
              tvlToken0: amount0,
              tvlToken1: amount1,
              isTickInPosition: isTickInPosition,
              positionPercOfTickLiq: parseFloat(positionPercOfTickLiq)/100,
            }
  				})
  			);

        const newDataFiltered = newData?.filter(o => !!o);

  			// offset the values to line off bars with TVL used to swap across bar
        newDataFiltered?.forEach((entry, i) => {
          if (i > 0) {
            newDataFiltered[i - 1].tvlToken0 = entry.tvlToken0;
            newDataFiltered[i - 1].tvlToken1 = entry.tvlToken1;
          }
        });

        if (newDataFiltered) {
          setFormattedData(newDataFiltered);
        }
      } catch(error) {
        throw error;
      } finally {
        !ran.current && setIsLoading(false);
        setIsFetching(false);
        ran.current = true;
      }
		}

		if (!skip && ticksProcessed.length > 0) {
			formatData();
		}
	}, [tickData, pool.Token0, pool.Token1, position.tickLower, position.tickUpper, position.liqPerTick, skip]);

	return { data: formattedData, isLoading, isFetching };
}

export default useFormatPoolData;
