import React from "react";
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlassesRound } from '@fortawesome/pro-duotone-svg-icons';
import { Link } from "../../util/router";
import Flex from 'components/Flex';
import './Home.scss';

function Home(props) {
	const { isPrivateWallet } = props;

	return (
		<Container className="Home">
			<Flex direction="column" justify="center" align="center" className="w-100 py-5">
			  <h4 className="mb-4 mb-lg-5">Welcome</h4>
			  <div className="links-grid">
				  <Link to="/uniswap" className="grid-link-item" item="uniswap">
				  	<div className="title-container">
				  		<h5 className="title">Uniswap</h5>
				  	</div>
				  	<div className="logo-container">
					  	<img src="/img/welcome/uniswap.png" alt="uniswap" className="logo" />
					  </div>
				  </Link>
				  {isPrivateWallet && (
				    <Link to="/monitor" className="grid-link-item" item="monitor">
				    	<div className="title-container">
				    		<h5 className="title">Monitor</h5>
				    	</div>
				    	<div className="logo-container">
				    		<FontAwesomeIcon icon={faGlassesRound} className="font-logo"/>
				  	  </div>
				    </Link>
				  )}
			  </div>
			</Flex>
		</Container>
	);
}

export default Home;
