import { useState, useEffect } from 'react';
import { ethers } from 'ethers';

function useWeb3Wallet() {
	const [wallet, setWallet] = useState();
	const [chainId, setChainId] = useState();
	const [provider, setProvider] = useState();

	useEffect(() => {
		async function getInfo() {
			if (window.ethereum) {
				const { ethereum } = window;
				const provider = new ethers.providers.Web3Provider(ethereum);

				provider.send('eth_requestAccounts', []).then(async accounts => {
					if (accounts.length > 0) {
						const providerNetwork = await provider._networkPromise;

						setWallet(accounts[0]);
						setChainId(providerNetwork.chainId);
						setProvider(provider);
					}
				}).catch(console.error);
			};
		};

		function onChange(which) {
			console.log(`WEB3 WALLET CHANGE: ${which}`);
      getInfo();
		};

		if (window.ethereum) {
			getInfo();
			window.ethereum.on('chainChanged', () => onChange('chain'));
			window.ethereum.on('accountsChanged', () => onChange('accounts'));
		}
	}, []);

	return {
		wallet, chainId, provider
	};
};

export default useWeb3Wallet;
