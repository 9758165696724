import React, { useState } from "react";
import "styles/global.scss";

import Meta from 'components/Meta';
import NavbarCustom from 'components/NavbarCustom';
import Footer from 'components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SetWalletPopup from 'components/SetWalletPopup';
import Home from './home';
import Balances from './balances';
import Positions from './uniswap/Positions';
import Position from './uniswap/Position';
import Pools from './pools/views/Pools';
import Pool from './pools/views/Pool';
import Analyze from './analyze';
import Liquidity from './liquidity';
import Swap from './swap';
import Arbitrage from './arbitrage';
import NotFoundPage from "./404";

import { defaultWallet } from 'constants/wallets';
import useWeb3Wallet from 'hooks/useWeb3Wallet';
import { Route, Router, Switch } from 'util/router';

const logo = "/img/logo-navbar.png";

function App(props) {
  const [popupWallet, setPopupWallet] = useState(localStorage.getItem('wallet.active'));
  const { wallet: web3Wallet, chainId: web3ChainId } = useWeb3Wallet();
  const wallet = web3Wallet || popupWallet;

  const [walletPopupOpen, setWalletPopupOpen] = useState(!wallet);
  const isPrivateWallet = wallet && wallet.toLowerCase() === defaultWallet.toLowerCase();

  return (
    <>
      <Router>
        <Meta title="Uniswap Manager"/>
        <NavbarCustom
          bg="white"
          variant="light"
          expand="md"
          logo={logo}
          setWalletPopupOpen={setWalletPopupOpen}
          isPrivateWallet={isPrivateWallet}
        />

        {!wallet || walletPopupOpen ? (
          <SetWalletPopup
            walletPopupOpen={walletPopupOpen}
            setWalletPopupOpen={setWalletPopupOpen}
            wallet={wallet}
            setWallet={setPopupWallet}
          />
        ) : (
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <Home {...props} isPrivateWallet={isPrivateWallet} />}
            />
            <Route
              exact
              path="/balances"
              render={(props) => <Balances {...props} wallet={wallet} />}
            />
            <Route
              exact
              path="/uniswap"
              render={(props) => <Positions {...props} wallet={wallet} />}
            />
            <Route
              exact
              path="/uniswap/:pid"
              render={(props) => <Position {...props} wallet={wallet} isPrivateWallet={isPrivateWallet} />}
            />
            <Route
              exact
              path="/analyze"
              render={(props) => <Analyze {...props} wallet={wallet} />}
            />
            <Route
              exact
              path="/liquidity"
              render={(props) => <Liquidity {...props} wallet={wallet} chainId={web3ChainId} />}
            />

            {isPrivateWallet && (
              <>
                <Route
                  exact
                  path="/monitor"
                  render={(props) => <Pools {...props} wallet={wallet} />}
                />
                <Route
                  exact
                  path="/monitor/:chainName"
                  render={(props) => <Pools {...props} wallet={wallet} />}
                />
                <Route
                  exact
                  path="/monitor/:chainName/:poolId"
                  render={(props) => <Pool {...props} wallet={wallet} />}
                />

                <Route
                  exact
                  path="/swap"
                  render={(props) => <Swap {...props} wallet={wallet} />}
                />
                <Route
                  exact
                  path="/arbitrage"
                  render={(props) => <Arbitrage {...props} wallet={wallet} />}
                />
              </>
            )}

            <Route component={NotFoundPage} />
          </Switch>
        )}

        <Footer
          bg="white"
          textColor="dark"
          copyright={`© ${new Date().getFullYear()} Eagle Capital`}
          logo={logo}
        />
      </Router>
      <ToastContainer/>
    </>
  );
}

export default App;
