import React, { useState, useMemo, useCallback } from 'react';
import JSBI from 'jsbi';
import classnames from 'classnames';
import { CurrencyAmount } from '@uniswap/sdk-core';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/pro-light-svg-icons';
import { faAngleLeft, faSpinnerThird, faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons';

import {
	useGetPositionsQuery,
	useGetPoolQuery,
	useGetTokenBalancesQuery,
} from 'api/client';

import { chainNameMap } from 'constants/chains';
import { WRAPPED_NATIVE_CURRENCY, DAI } from 'constants/tokens';
import genTxHashUrl from 'constants/tx';

import useOnRemoveLiquidity from 'util/position/useOnRemoveLiquidity';
import { useLocation, useHistory } from 'util/router';
import { commaFormat, currencyFormat, fixedAsNumber } from 'util/numberFormatter';
import { getTokens } from 'util/tokens';

import Flex from 'components/Flex';
import FullError from 'components/FullError';
import FullLoader from 'components/FullLoader';
import Logo from 'components/Logo';
import RefreshBtn from 'components/RefreshBtn';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';

import HideCardTitle from 'pages/uniswap/components/HideCardTitle';
import RatioBadge from 'pages/uniswap/components/RatioBadge';
import InRangeIcon from 'pages/uniswap/components/InRangeIcon';

import useGetChainIdFromRouter from 'hooks/useGetChainIdFromRouter';
import useWeb3Wallet from 'hooks/useWeb3Wallet';

import { RenderedPoolInfo } from 'pages/pools/views/Pool/Pool';
import TickChart from './TickChart';
import TVChartCard from './TVChartCard';
import CollectPopup from './CollectPopup';
import './Position.scss';

const SECONDS_IN_DAY = 24 * 60 * 60;

const styles = {
	button: {
		lineHeight: 1
	}
};

function getEarnedPercentageOfPos(p) {
	const positionVal = p.position.valueInDai;
	const earnedVal = p.position.token0.earnedInDai + p.position.token1.earnedInDai;
	if (!earnedVal || earnedVal === 0) return 0;

	return fixedAsNumber(earnedVal/positionVal*100, 2, '-');
}

function getEarnedAprOfPos(p) {
	const { timeStamp: latestEvtTS } = p.position.latestEvt || {};
	const positionVal = p.position.valueInDai;
	const earnedVal = p.position.token0.earnedInDai + p.position.token1.earnedInDai;
	if (!earnedVal || earnedVal === 0) return 0;

	if (!latestEvtTS) return commaFormat(fixedAsNumber(earnedVal/positionVal*100*360, 2));
	else {
		const now = new Date();
		const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
		const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000);

		const secondsSinceEvt = utcSecondsSinceEpoch - latestEvtTS;
		const multpleOfDay = SECONDS_IN_DAY / secondsSinceEvt;
		return commaFormat(fixedAsNumber(earnedVal/positionVal*100*360*multpleOfDay, 2));
	}
}

function Position(props) {
	const history = useHistory();

	const chainId = useGetChainIdFromRouter();
	const chainName = chainNameMap[chainId];

	const { pid } = props.match.params;
	const { positions: positionsFromLocation = [] } = useLocation().state || {};

	const fiatToken = DAI[chainId];

	const {
    data: positions = positionsFromLocation || [],
    isLoading,
    isFetching,
    isError,
    error,
    refetch: positionsRefetch,
  } = useGetPositionsQuery({ chainId, pids: [pid] }, {
  	pollingInterval: 60 * 1000,
  });

  const p = positions.find(position => position.pid === pid);
  const owner = p?.owner;
  const [Token0, Token1] = getTokens(p?.pool?.Token0, p?.pool?.Token1);
  const wrappedNative = WRAPPED_NATIVE_CURRENCY[chainId];
  const hasWrappedNative = [Token0, Token1].filter(o => o).some(t => wrappedNative.equals(t));

  const {
    data: poolSummary = {},
    isLoading: poolSummaryLoading,
    refetch: poolSummaryRefetch,
  } = useGetPoolQuery({
  	pairAddress: p?.pool?.address,
  	chainName: chainName
  }, {
  	skip: !p?.pool?.address,
  	pollingInterval: 60 * 1000,
  });

  const {
  	data: balances = {},
  	isLoading: balancesIsLoading,
  	refetch: balancesRefetch,
  } = useGetTokenBalancesQuery({
  	chainId,
  	tokenAddresses: [Token0?.address, Token1?.address],
  	wallet: owner,
  	getNative: hasWrappedNative,
  	getUsd: true
  }, {
  	skip: !owner || !Token0 || !Token1,
  });

  const refetch = useCallback(() => {
  	positionsRefetch();
  	poolSummaryRefetch();
  	balancesRefetch();
  }, [positionsRefetch, poolSummaryRefetch, balancesRefetch]);

	return (
		!p && isLoading ? (
			<FullLoader text="position" />
		) : (
			<>
				{isError && (<FullError
					reason={error || 'An unknown error occurred'}
					callback={refetch}
					callbackIsLoading={isFetching}
					hasItemsBelow={!!p}
				/>)}

				{!!p && (
					<>
						<Container className="Position py-4 py-lg-5">
							<div className="back-container text-secondary">
								<span
									className="pointer back-btn"
									onClick={() => history.push({
										pathname: '/uniswap',
										search: `?chainId=${chainId}`,
									})}
								>
									<FontAwesomeIcon icon={faAngleLeft} className="f-rem-0.9" /> Back
								</span>
							</div>
							<Row className="pool-info-container">
								<Col xs={12}>
									<PoolTitleInfo
										chainId={chainId}
										position={p}
										refetch={refetch}
										isFetching={isFetching}
										poolSummary={poolSummary}
										poolSummaryLoading={poolSummaryLoading}
									/>
								</Col>
							</Row>
							{/* Main */}
							<Row className="main-container">
								{/* Liq & Earned cards */}
								<Col xs={12} lg={5} xl={4} className="f-rem-0.9 col-infoCards">
									<RenderedLiqCard
										position={p}
										refetch={refetch}
									/>
									<RenderedEarnedCard
										position={p}
										refetch={refetch}
									/>
									<RenderedBalanceCard
										position={p}
										fiatToken={fiatToken}
										balances={balances}
										balancesIsLoading={balancesIsLoading}
									/>
								</Col>
								{/* Right hand column */}
								<Col>
									<TickChart
										chainId={chainId}
										pool={p.pool}
										position={p.position}
										className="mb-3"
									/>

									<PoolMonitorInfo
										token0={p.pool.Token0}
										token1={p.pool.Token1}
										poolSummary={poolSummary}
										poolSummaryLoading={poolSummaryLoading}
										className="mb-3"
									/>

									{[JSON.parse(p.pool.Token0), JSON.parse(p.pool.Token1)].map((Token, i) => (
										<TVChartCard Token={Token} index={i} key={i}/>
									))}
								</Col>
							</Row>
						</Container>

						{/*{showPopups.collect && (
							<CollectPopup
								chainId={chainId}
								wallet={ownerWallet}
								position={p}
								afterClose={() => togglePopup('collect')}
							/>
						)}*/}
					</>
				)}
			</>
		)
	);
};

export default Position;

const PoolTitleInfo = ({chainId, position: p, refetch, isFetching, poolSummary, poolSummaryLoading }) => {
	const chainName = chainNameMap[chainId];

	const [Token0, Token1] = getTokens(p.pool.Token0, p.pool.Token1);
	const tradeLink = `https://app.uniswap.org/#/swap?inputCurrency=${Token0.address}&outputCurrency=${Token1.address}?chain=${chainName}`;
	const poolLink = `https://app.uniswap.org/#/add/${Token0.address}/${Token1.address}/${p.pool.fee}?chain=${chainName}`;
	const poolInfoLink = `https://info.uniswap.org/#/${chainName}/pools/${p.pool.address.toLowerCase()}`;

	const estimatedApr = poolSummaryLoading ? (
		<FontAwesomeIcon icon={faSpinnerThird} spin />
	) : (
		`${commaFormat(fixedAsNumber(poolSummary.apr || 0, 2))}%`
	);

	function openLink(link) {
	  const win = window.open(link, '_blank');
	  win.focus();
	}

	return (
		<Flex wrap="wrap" justify="between" align="start" className="mb-3">
			<Flex align="end" className="mt-2" style={{overflowX: 'scroll'}}>
				<span><InRangeIcon ratio={p.position.token0.ratio}/></span>
				<Logo token={{...Token0, chainName: chainId}} className="mt-1 ml-2 align-self-start"/>
				<Logo token={{...Token1, chainName: chainId}} className="mt-1 align-self-start pullLeft"/>
				<h5 className="mb-0 mx-2">{p.pool.name}</h5>
				<h5 className="mb-0">
					<Badge className="border shadow-sm text-secondary font-weight-normal">{p.pool.fee/10000}% fee</Badge>
				</h5>
				<h5 className="mb-0 ml-2">
					<Badge className="border shadow-sm text-secondary font-weight-normal">{estimatedApr} est APR</Badge>
					{/*<Badge className="border shadow-sm text-secondary font-weight-normal">{p.position.percentOfPool}%/tick</Badge>*/}
				</h5>
				<RefreshBtn refreshing={isFetching} action={refetch} className="ml-2 f-rem-1 align-self-center"/>
			</Flex>
			<Flex align="end" className="mt-2">
				<Button variant="light" size="sm" className="shadow-sm" style={styles.button} onClick={() => openLink(tradeLink)}>
					Trade <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="f-rem-0.75 ml-1" style={{verticalAlign: 'baseline'}}/>
				</Button>
				<Button variant="outline-gray-900" size="sm" className="shadow-sm ml-2" style={styles.button} onClick={() => openLink(poolLink)}>
					Liquidity <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="f-rem-0.75 ml-1" style={{verticalAlign: 'baseline'}}/>
				</Button>
				<Button variant="outline-info-dark" size="sm" className="shadow-sm ml-2" style={styles.button} onClick={() => openLink(poolInfoLink)}>
					Info <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="f-rem-0.75 ml-1" style={{verticalAlign: 'baseline'}}/>
				</Button>
			</Flex>
		</Flex>
	);
};

const RenderedLiqCard = ({ position: p, refetch }) => {
	const [txIsSending, setTxIsSending] = useState(false);
	const [txUrl, setTxUrl] = useState(null);
	const [callTxError, setCallTxError] = useState(null);

	const { wallet, chainId: web3ChainId } = useWeb3Wallet();
	const owner = p?.owner;
	const chainId = useGetChainIdFromRouter();
	const [Token0, Token1] = getTokens(p.pool.Token0, p.pool.Token1);
	const removed = p?.position?.liquidity === "0";

	const showActions = !removed &&
		Number(web3ChainId) === chainId &&
		wallet?.toLowerCase?.() === owner?.toLowerCase?.();

	const onRemoveLiquidity = useOnRemoveLiquidity(p?.pid);

	return (
		<Card
			id="card-liquidity"
			className="shadow border-0 rounded p-2"
		>
			<Flex wrap="wrap" justify="between" align="center" className="px-2 pt-1">
				<span className="mb-0">Liquidity</span>
				<small className="text-gray-700">{p.position.percentOfPool}%/tick</small>
			</Flex>

			<h4 className="text-monospace pl-2 mt-1">{currencyFormat(p.position.valueInDai)}</h4>

			<div className="bg-gray-100 rounded py-2 px-3 mt-1">
				{[
					[Token0, p.position.token0],
					[Token1, p.position.token1],
				].map(([Token, token] = [], key) => (
					<Flex
						key={key}
						wrap="wrap"
						justify="between"
						align="center"
						className="details-box"
					>
						<Flex align="center">
							<Logo token={{...Token, chainName: chainId}} className="mr-2 align-self-start"/>
							<span>{Token.symbol}</span>
						</Flex>
						<Flex align="center">
							<span className="text-gray-600">{commaFormat(token.deposit)}</span>
							<span className="px-1 text-gray-600">•</span>
							<span className="text-monospace">{currencyFormat(token.valueInDai)}</span>
							<RatioBadge ratio={token.ratio} className="ml-2"/>
						</Flex>
					</Flex>
				))}
			</div>

			{showActions && (
				<Flex
					{...txUrl ? {
						justify: 'between',
						align: 'center'
					} : callTxError ? {
						direction: 'column',
					} : {}}
					className="mb-1.5 mt-2.5"
				>
					<Button
						disabled={txIsSending}
						variant="light"
						size="sm"
						className="shadow-sm align-self-start"
						style={styles.button}
						onClick={() => {
							setTxIsSending(true);
							setTxUrl(null);
							setCallTxError(null);

							onRemoveLiquidity(refetch).then(
								({txState, txHash, error}) => {
									setTxIsSending(false);

									if (error) {
										setCallTxError(error.toString());
									} else if (txHash) {
										setTxUrl(genTxHashUrl(txHash, chainId));
									}
								}
							).catch(error => {
								setTxIsSending(false);
								setCallTxError(error.toString());
							});
						}}
					>
						{txIsSending ? <Spinner /> : 'Remove'}
					</Button>
					{callTxError ? (
						<div className="mt-2.5 text-danger f-rem-0.95" style={{lineHeight: '1.2'}}>
							<span>{callTxError}</span>
						</div>
					) : txUrl ? (
						<div className="text-primary f-rem-0.95" style={{lineHeight: '1.2'}}>
							<a
								href={txUrl}
								target="_blank"
								rel="noreferrer"
							>
								View transaction
							</a>
						</div>
					) : null}
				</Flex>
			)}
		</Card>
	);
};

const RenderedEarnedCard = ({ position: p, refetch }) => {
	const { wallet, chainId: web3ChainId } = useWeb3Wallet();
	const owner = p?.owner;
	const chainId = useGetChainIdFromRouter();
	const [Token0, Token1] = getTokens(p.pool.Token0, p.pool.Token1);
	const removed = p?.position?.liquidity === "0";

	const showActions = !removed &&
		Number(web3ChainId) === chainId &&
		wallet?.toLowerCase?.() === owner?.toLowerCase?.();

	return (
		<Card
			id="card-earned"
			className="shadow border-0 rounded p-2 mt-0 mt-lg-3"
		>
			<Flex wrap="wrap" justify="between" align="center" className="px-2 pt-1">
				<span className="mb-0">Earned fees</span>
				<small>
					<span className="text-muted mr-2">({getEarnedAprOfPos(p)}% APR)</span>
					<span className="text-gray-700">{getEarnedPercentageOfPos(p)}%/pos</span>
				</small>
			</Flex>

			<h4 className="text-monospace pl-2 mt-1">{currencyFormat(p.position.token0.earnedInDai + p.position.token1.earnedInDai)}</h4>

			<div className="bg-gray-100 rounded py-2 px-3 mt-1">
				{[
					[Token0, p.position.token0],
					[Token1, p.position.token1],
				].map(([Token, token] = [], key) => (
					<Flex
						key={key}
						wrap="wrap"
						justify="between"
						align="center"
						className="details-box"
					>
						<Flex align="center">
							<Logo token={{...Token, chainName: chainId}} className="mr-2 align-self-start"/>
							<span>{Token.symbol}</span>
						</Flex>
						<Flex wrap="wrap" justify="between" align="center" className="mt-1 text-gray-600">
							<span>{commaFormat(token.earned)}</span>
							<span className="px-1">•</span>
							<span className="text-monospace text-dark">{currencyFormat(token.earnedInDai)}</span>
							<span className="px-1">•</span>
							<span>{token.earnedRatio}%</span>
						</Flex>
					</Flex>
				))}
			</div>

			{showActions && (
				<Flex justify="end" className="mb-1.5 mt-2.5">
					<Button
						variant="outline-primary"
						size="sm"
						className="shadow-sm"
						style={styles.button}
						onClick={() => {}}
					>
						Collect
					</Button>
					<Button
						variant="primary"
						size="sm"
						className="shadow-sm ml-2"
						style={styles.button}
						onClick={() => {}}
					>
						Compound
					</Button>
				</Flex>
			)}
		</Card>
	);
};

const RenderedBalanceCard = ({ position: p, fiatToken, balances, balancesIsLoading }) => {
	const { wallet, chainId: web3ChainId } = useWeb3Wallet();
	const owner = p?.owner;
	const chainId = useGetChainIdFromRouter();
	const [Token0, Token1] = getTokens(p.pool.Token0, p.pool.Token1);
	const wrappedNative = WRAPPED_NATIVE_CURRENCY[chainId];
	const hasWrappedNative = [Token0, Token1].some(t => wrappedNative.equals(t));
	const [showNative, setShowNative] = useState(false);
	const removed = p?.position?.liquidity === "0";

	const showActions = !removed &&
		Number(web3ChainId) === chainId &&
		wallet?.toLowerCase?.() === owner?.toLowerCase?.();

	const convertBalanceToCurrencyAmount = (balanceInfo, token) => {
		if (!balanceInfo) return;
		return {
			...balanceInfo,
			balance: CurrencyAmount.fromFractionalAmount(
				token,
				JSBI.from(balanceInfo.balance.numerator).toString(),
				JSBI.from(balanceInfo.balance.denominator).toString()
			),
		}
	};

	const totalBalance = useMemo(() => {
		if (!showNative || !hasWrappedNative) {
			return currencyFormat(balances?.[Token0.address]?.valueInDai + balances?.[Token1.address]?.valueInDai, '-');
		}

		const T0 = wrappedNative.equals(Token0) ? balances?.native?.valueInDai : balances?.[Token0.address]?.valueInDai;
		const T1 = wrappedNative.equals(Token1) ? balances?.native?.valueInDai : balances?.[Token1.address]?.valueInDai;

		return currencyFormat(T0 + T1, '-');
	}, [showNative, hasWrappedNative, Token0, Token1, balances, wrappedNative]);

	const tokensForRenderWithNativeCheck = useMemo(() => {
		const tokensForRender = [
			[
				Token0,
				convertBalanceToCurrencyAmount(balances[Token0.address], Token0),
				p.position.token0
			],
			[
				Token1,
				convertBalanceToCurrencyAmount(balances[Token1.address], Token1),
				p.position.token1
			],
		];

		const nativeToken = hasWrappedNative && balances?.native?.balance?.currency;

		return hasWrappedNative && showNative && nativeToken ? [
			wrappedNative.equals(Token0) ? [
				{...wrappedNative, symbol: nativeToken?.symbol},
				convertBalanceToCurrencyAmount(balances.native, wrappedNative),
				p.position.token0
			] : tokensForRender[0],
			wrappedNative.equals(Token1) ? [
				{...wrappedNative, symbol: nativeToken?.symbol},
				convertBalanceToCurrencyAmount(balances.native, wrappedNative),
				p.position.token1
			] : tokensForRender[1]
		] : tokensForRender;
	}, [showNative, Token0, Token1, balances, p?.position?.token0, p?.position?.token1, wrappedNative, hasWrappedNative]);

	return (
		<Card
			id="card-balance"
			className="shadow border-0 rounded p-2 mt-0 mt-lg-3"
		>
			{balancesIsLoading || !owner ? (
				<Flex justify="center" align="center" className="text-secondary py-2">
					<FontAwesomeIcon icon={faSpinnerThird} spin className="mr-1" /> Loading balances
				</Flex>
			) : (
				<>
					<Flex wrap="wrap" justify="between" align="center" className="w-100 px-2 pt-1">
						<span className="mb-0">Balances</span>
						{hasWrappedNative && (
							<Toggle
								active={showNative}
								setActive={() => setShowNative(prev => !prev)}
								ops={['Wrapped', 'Native']}
								isOpActive={(active, op) => !active ? op === 'Wrapped' : op === 'Native'}
								className="f-rem-0.7 fw-5"
							/>
						)}
					</Flex>

					<h4 className="text-monospace pl-2 mt-1">{totalBalance}</h4>

					<div className="bg-gray-100 rounded py-2 px-3 mt-1">
						{tokensForRenderWithNativeCheck.map(([Token, tokenBalance, token] = [], key) => (
							<Flex
								key={key}
								wrap="wrap"
								justify="between"
								align="center"
								className="details-box"
							>
								<Flex align="center">
									<Logo token={{...Token, chainName: chainId}} className="mr-2 align-self-start"/>
									<span>{Token.symbol}</span>
								</Flex>
								<Flex wrap="wrap" justify="between" align="center" className="text-gray-600 mt-1">
									<span>{commaFormat(tokenBalance?.balance?.toSignificant(4), undefined, '-')}</span>
									<span className="px-1">•</span>
									<span className="text-dark text-monospace">{currencyFormat(tokenBalance?.valueInDai, '-')}</span>
									<span className="f-rem-0.7 px-1" style={{paddingTop: '2px'}}><FontAwesomeIcon icon={faAt} /></span>
	    		    		<span className="text-monospace">
	    		    			{currencyFormat(
	    		    				CurrencyAmount.fromRawAmount(fiatToken, token?.priceInDai).toExact(),
	    		    				'-'
		    		    		)}
		    		    	</span>
								</Flex>
							</Flex>
						))}
					</div>

					{showActions && (
						<Flex justify="end" className="mb-1.5 mt-2.5">
							<Button variant="outline-primary" size="sm" className="shadow-sm ml-2" style={styles.button}>Add to position</Button>
						</Flex>
					)}
				</>
			)}
		</Card>
	);
};

export const PoolMonitorInfo = ({ token0, token1, poolSummary, poolSummaryLoading, className = '' }) => {
	const [hideInfo, setHideInfo] = useState(true);
	const Tokens = getTokens(token0, token1);
	const hasPool = !!Object.keys(poolSummary || {})[0];

	return (
		<Card className={`PoolMonitorInfo bg-white shadow border-0 rounded ${className}`.trim()}>
			{poolSummaryLoading ? (
				<Flex justify="center" align="center" className="text-secondary py-2">
					<FontAwesomeIcon icon={faSpinnerThird} spin className="mr-1" /> Loading pool info
				</Flex>
			) : !hasPool ? (
				<Flex justify="center" align="center" className="text-secondary py-2">
					<span>No pool found</span>
				</Flex>
			) : (
				<>
					<HideCardTitle title="Pool Info" hide={hideInfo} setHide={setHideInfo} />
					<div
						className={classnames('chart-container w-100 bg-gray-100', {
							'd-none': hideInfo
						})}
					>
						<RenderedPoolInfo pool={poolSummary} Tokens={Tokens} />
					</div>
				</>
			)}
		</Card>
	);
};
