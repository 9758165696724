import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URI } from './constants';
import analyzeHandlers from './analyze';
import swapHandlers from './swap';
import tokensHandlers from './tokens';
import walletHandlers from './wallet';
import poolsHandlers from './pools';
import positionsHandlers from './positions';
import arbitrageHandlers from './arbitrage';

const noCacheHeaders = {
	prepareHeaders: (headers, { getState }) => {
		headers.set('Cache-Control', 'no-cache');
		headers.set('Pragma', 'no-cache');
		headers.set('Expires', '0');

		return headers;
	}
};

export const positionsApiSlice = createApi({
	reducerPath: 'positions',
	baseQuery: fetchBaseQuery({
		baseUrl: API_URI,
		...noCacheHeaders,
	}),
	endpoints: builder => positionsHandlers(builder),
});

export const {
	useGetPositionsQuery, useGetTickDataQuery, useGetMyPositionsAddressesQuery, useGetRouteToRatioCalldataQuery
} = positionsApiSlice;

export const poolsApiSlice = createApi({
	reducerPath: 'pools',
	baseQuery: fetchBaseQuery({
		baseUrl: API_URI,
		...noCacheHeaders,
	}),
	endpoints: builder => poolsHandlers(builder),
});

export const {
	useGetPairAddressQuery, useGetTokensFromPairAddressQuery, useGetPoolsQuery, useGetPoolQuery, useGetPoolFeeRateQuery, useGetDexesFromPairsQuery,
} = poolsApiSlice;

export const analyzeApiSlice = createApi({
	reducerPath: 'analyze',
	baseQuery: fetchBaseQuery({
		baseUrl: API_URI,
		...noCacheHeaders,
	}),
	endpoints: builder => analyzeHandlers(builder),
});

export const {
	useGetPoolStateQuery, useGetHighestLiqPoolStateQuery, useGetTickEndsFromPoolsQuery,
} = analyzeApiSlice;

export const arbitrageApiSlice = createApi({
	reducerPath: 'arbitrage',
	baseQuery: fetchBaseQuery({
		baseUrl: API_URI,
		...noCacheHeaders,
	}),
	endpoints: builder => arbitrageHandlers(builder),
});

export const {
	useGetPoolsAddressesQuery, useGetPoolsLiquidityQuery, useGetUniV3QuotesQuery
} = arbitrageApiSlice;

export const swapApiSlice = createApi({
	reducerPath: 'swap',
	baseQuery: fetchBaseQuery({
		baseUrl: API_URI,
		...noCacheHeaders,
	}),
	endpoints: builder => swapHandlers(builder),
});

export const {
	useGetSwapQuery, useGetUniV3QuoteQuery,
} = swapApiSlice;

export const tokensApiSlice = createApi({
	reducerPath: 'tokens',
	baseQuery: fetchBaseQuery({
		baseUrl: API_URI,
		...noCacheHeaders,
	}),
	endpoints: builder => tokensHandlers(builder),
});

export const {
	useGetSupportedTokensQuery, useGetUsdPriceQuery, useGetUsdPriceFromUniQuery, useGetTokenAllowancesQuery,
} = tokensApiSlice;

export const walletApiSlice = createApi({
	reducerPath: 'wallet',
	baseQuery: fetchBaseQuery({
		baseUrl: API_URI,
		...noCacheHeaders,
	}),
	endpoints: builder => walletHandlers(builder),
});

export const {
	useGetTokenBalancesQuery, useGetNativeBalanceQuery,
} = walletApiSlice;
