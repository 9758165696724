import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import Nav from 'react-bootstrap/Nav';
import './NavbarCustom.scss';

function NavbarCustom(props) {
  const { bg, variant, expand, setWalletPopupOpen, isPrivateWallet } = props;

  return (
    <Navbar bg={bg} variant={variant} expand={expand} collapseOnSelect className="NavbarCustom">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="d-inline-block align-top logo"
              src={props.logo}
              alt="Logo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav className="mr-1">
            <LinkContainer to="/balances">
              <Nav.Link active={false}>Balances</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/uniswap">
              <Nav.Link active={false}>Uniswap</Nav.Link>
            </LinkContainer>
            {isPrivateWallet && (
              <>
                <LinkContainer to="/monitor">
                  <Nav.Link active={false}>Monitor</Nav.Link>
                </LinkContainer>
              </>
            )}
            <LinkContainer to="/analyze">
              <Nav.Link active={false}>Analyze</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/liquidity">
              <Nav.Link active={false}>Liquidity</Nav.Link>
            </LinkContainer>
            {isPrivateWallet && (
              <>
                <LinkContainer to="/swap">
                  <Nav.Link active={false}>Swap</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/arbitrage">
                  <Nav.Link active={false}>Arb</Nav.Link>
                </LinkContainer>
              </>
            )}
            <div className="nav-link pointer" onClick={() => setWalletPopupOpen(true)}>
              Change Wallet
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
