import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Flex from 'components/Flex';
import Logo from 'components/Logo';
import './TokensDropdown.scss';

function TokensDropdown(props) {
	const {
		tokens = [],
		selectedToken,
		setSelectedToken,
		className = '',
	} = props;

	return (
		<Dropdown className={`TokensDropdown ${className}`.trim()}>
			<Dropdown.Toggle variant="none" className="toggle">
				{selectedToken && selectedToken.symbol ? (
					<>
						<Logo token={selectedToken} className="mr-2" style={{marginTop: '-4px'}}/>
						<span className="mr-1">{selectedToken.symbol}</span>
					</>
				) : (
					<span className="mr-1">Select Token</span>
				)}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{tokens.map(t => (
					<Dropdown.Item
						key={t.symbol}
						onClick={() => setSelectedToken(t)}
						className="px-2.5"
					>
						<Flex align="center">
							<Logo token={t} className="mr-2"/>
							<span>{t.symbol}</span>
						</Flex>
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default TokensDropdown;
