export function capFirst(value) {
  return String(value).trim().charAt(0).toUpperCase() + String(value).trim().slice(1);
};

export function capFirstOfEach(value) {
  let splitStr = String(value).toLowerCase().split(' ');

  splitStr = splitStr.map(letter => {
    return letter.trim().charAt(0).toUpperCase() + letter.trim().slice(1);
  });

  return splitStr.join(' ');
}
