import chains from './chains';

export const uniswap = {
	[chains.MAINNET]: 'https://app.uniswap.org/#/swap',
	[chains.OPTIMISM]: 'https://app.uniswap.org/#/swap',
	[chains.POLYGON]: 'https://app.uniswap.org/#/swap',
	[chains.ARBITRUM]: 'https://app.uniswap.org/#/swap',
};

export const sushiswap = {
	[chains.MAINNET]: 'https://app.sushi.com/swap',
	[chains.BINANCE]: 'https://app.sushi.com/swap',
	[chains.POLYGON]: 'https://app.sushi.com/swap',
};

export const apeswap = {
	[chains.BINANCE]: 'https://apeswap.finance/swap',
	[chains.POLYGON]: 'https://apeswap.finance/swap',
};

export const quickswap = {
	[chains.POLYGON]: 'https://quickswap.exchange/#/swap',
};

export const vulcanforged = {
	[chains.POLYGON]: 'https://vulcandex.vulcanforged.com/swap',
};

// export const dexesByChain = {
// 	[chains.MAINNET]: {
// 		uniswap: uniswap[chains.MAINNET],
// 		sushiswap: sushiswap[chains.MAINNET],
// 	},
// 	[chains.OPTIMISM]: {
// 		uniswap: uniswap[chains.OPTIMISM],
// 	},
// 	[chains.BINANCE]: {
// 		sushiswap: sushiswap[chains.BINANCE],
// 		apeswap: apeswap[chains.BINANCE],
// 	},
// 	[chains.POLYGON]: {
// 		uniswap: uniswap[chains.POLYGON],
// 		sushiswap: sushiswap[chains.POLYGON],
// 		apeswap: apeswap[chains.POLYGON],
// 		quickswap: quickswap[chains.POLYGON],
// 	},
// };
