import React from "react";
import Badge from "react-bootstrap/Badge";

export const getColorFromRatio = ratio => {
	let color;

	switch (true) {
		case ratio >= 100:
		case ratio <= 0:
			color = 'danger-dark';
			break;
		case ratio >= 95:
		case ratio <= 5:
			color = 'danger';
			break;
		case ratio >= 85:
		case ratio <= 15:
			color = 'warning';
			break;
		case ratio >= 75:
		case ratio <= 25:
			color = 'warning-light';
			break;
		case ratio >= 67.5:
		case ratio <= 32.5:
			color = 'info-dark';
			break;
		case ratio >= 60:
		case ratio <= 40:
			color = 'info';
			break;
		case ratio < 60:
		case ratio > 40:
			color = 'success-dark';
			break;
		default:
			color = 'secondary';
			break;
	}

	return color;
}

function RatioBadge(props) {
	const { ratio, className, style: _style = {}, ...rest } = props;
	const color = getColorFromRatio(ratio);
	const style = {
		fontSize: '0.6rem',
		..._style
	};

	return (
		<Badge
			className={`border border-${color} text-${color} ${props.className || ''}`}
			style={style}
			{...rest}
		>
			{props.ratio}%
		</Badge>
	);
}
export default RatioBadge;
