import { ethers } from 'ethers';
import { Pool, Position } from '@uniswap/v3-sdk';
import { Percent, CurrencyAmount, Fraction } from '@uniswap/sdk-core';
import { AlphaRouter, SwapType } from '@uniswap/smart-order-router';
import { SWAP_ROUTER_ADDRESS } from 'constants/addresses';
import getBrowserExtensionProvider from 'util/getBrowserExtensionProvider';
import { getTokens } from 'util/tokens';

async function generateRouteToRatioCalldata(_Token0, _Token1, feeRate, _token0Amount,
    _token1Amount, position, poolState, wallet, chainId) {
	const provider = getBrowserExtensionProvider();

  // Ensure everyting is in the order it actually is in the pool
  const [Token0, Token1] = getTokens(poolState?.Token0, poolState?.Token1);
  const token0Amount = Token0.address === _Token0.address ? _token0Amount : _token1Amount;
  const token1Amount = Token1.address === _Token1.address ? _token1Amount : _token0Amount;

  const token0ToAddCurrencyAmount = CurrencyAmount.fromRawAmount(
    Token0,
    ethers.utils.parseUnits(
      token0Amount,
      Token0?.decimals
    )
  );

  const token1ToAddCurrencyAmount = CurrencyAmount.fromRawAmount(
    Token1,
    ethers.utils.parseUnits(
      token1Amount,
      Token1?.decimals
    )
  );

  const currentPosition = constructPositionWithPlaceholderLiquidity(
    chainId,
    Token0,
    Token1,
    feeRate,
    position.position.tickLower,
    position.position.tickUpper,
    poolState
  );

  const swapAndAddConfig = {
    ratioErrorTolerance: new Fraction(1, 500),
    maxIterations: 6,
  };

  const swapAndAddOptions = {
    swapOptions: {
      type: SwapType.SWAP_ROUTER_02,
      recipient: wallet,
      slippageTolerance: new Percent(10, 10_000),
      deadline: Math.floor(Date.now() / 1000) + 60 * 20,
    },
    addLiquidityOptions: {
      tokenId: position.pid,
    },
  };

  const router = new AlphaRouter({ chainId, provider });
  const routeToRatioResponse = await router.routeToRatio(
    token0ToAddCurrencyAmount,
    token1ToAddCurrencyAmount,
    currentPosition,
    swapAndAddConfig,
    swapAndAddOptions
  );

  const route = routeToRatioResponse.result;

  const transaction = {
    data: route?.methodParameters?.calldata,
    to: SWAP_ROUTER_ADDRESS,
    value: route?.methodParameters?.value,
    from: wallet,
  };

  return transaction;
};

export default generateRouteToRatioCalldata;

function constructPositionWithPlaceholderLiquidity(chainId, Token0, Token1, feeRate, tickLower, tickUpper, poolState) {
  // construct pool instance
  const configuredPool = new Pool(
    Token0,
    Token1,
    feeRate,
    poolState.sqrtPriceX96Current,
    poolState.liquidityCurrent,
    poolState.tickCurrent
  );

  // create position using the maximum liquidity from input amounts
  return new Position({
    pool: configuredPool,
    tickLower: tickLower,
    tickUpper: tickUpper,
    liquidity: 1,
  })
}
