import queryConstructor from '../../util/queryConstructor';
import { keepUnusedDataFor } from './constants';

export const handlers = (builder) => ({
	getUsdPrice: builder.query({
		query: ({chainId, tokenAddress, balance, quantity}) => {
			const queryParams = queryConstructor({
				chainId, tokenAddress, balance, quantity
			});

			return `tokens/fetchUsdPrice?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getUsdPriceFromUni: builder.query({
		query: ({chainId, tokenAddresses, usdAmount = '100', fast = true}) => {
			const queryParams = queryConstructor({
				chainId, tokenAddresses, usdAmount, fast,
			});

			return `tokens/fetchUsdPriceFromUni?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getSupportedTokens: builder.query({
		query: ({chainId}) => {
			const queryParams = queryConstructor({ chainId });
			return `tokens/fetchSupportedTokens?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getTokenAllowances: builder.query({
		query: ({chainId, tokenAddresses, spender, wallet}) => {
			const queryParams = queryConstructor({
				chainId, tokenAddresses, spender, wallet,
			});

			return `tokens/fetchTokenAllowances?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
});

export default handlers;
