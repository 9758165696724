import { ethers } from 'ethers';
import { Token } from '@uniswap/sdk-core';
import { DAI } from '../constants/tokens';

export const getTokens = (...args) => {
	const tokens = [...args].filter(o => !!o);
	return tokens.map(
		t => {
			const parsed = typeof t === 'string' ? JSON.parse(t) : t;
			return new Token(parsed.chainId, parsed.address, parsed.decimals, parsed.symbol, parsed.name);
		}
	);
}

export const convertToDaiValue = (price, balance, token) => {
	const [Token] = getTokens(token);
	const dai = DAI[Token.chainId];
	const product = ethers.BigNumber.from(price).mul(
		ethers.utils.parseUnits(typeof balance?.toExact === 'function' ? balance.toExact() : String(balance), Token.decimals)
	);
	const sumOfDecimals = dai.decimals + Token.decimals;
	return Number(ethers.utils.formatUnits(product, sumOfDecimals));
}
