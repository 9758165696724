import React, { useEffect } from "react";
import classnames from 'classnames';
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird, faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

import useOnBlur from '../../hooks/useOnBlur';
import useOnFocus from '../../hooks/useOnFocus';
import './RefreshBtn.scss';

function RefreshBadge(props) {
	const { refreshing, action, className = '', style = {}, time = 60, ...rest } = props;

	let autoRerun = false;
	const setAutoRerun = () => {
		autoRerun = typeof action === 'function' ? setTimeout(() => action(), time*1000) : false;
	};

	useEffect(() => {
		if (refreshing) {
			clearTimeout(autoRerun);
		} else {
			setAutoRerun();
		}

		return () => {
			clearTimeout(autoRerun);
		}
	});

	useOnBlur(() => clearTimeout(autoRerun));
	useOnFocus(() => setAutoRerun());

	return (
		<Badge
			className={classnames(
				`RefreshBtn shadow-sm text-secondary bg-white-hvr pointer ${className}`.trim(),
				{
					'border-animate': !refreshing,
					'bg-light': refreshing
				}
			)}
			onClick={action}
			style={{ ...style, animationDuration: `${time}s` }}
			{...rest}
		>
			{!!refreshing ? (
				<FontAwesomeIcon icon={faSpinnerThird} spin={true} />
			) : (
				<FontAwesomeIcon icon={faRotateRight} />
			)}
		</Badge>
	);
}

export default RefreshBadge;
