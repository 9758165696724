import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Pool, TickMath, TICK_SPACINGS, tickToPrice, nearestUsableTick } from '@uniswap/v3-sdk';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import min from 'lodash/min';
import max from 'lodash/max';
import Slider from 'rc-slider';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons';

import {
	useGetPoolQuery,
	useGetPoolStateQuery,
	useGetTickDataQuery,
	useGetTickEndsFromPoolsQuery,
} from 'api/client';
import chains, { chainNameMap, chainIdMap, chainColors } from 'constants/chains';
import * as dexes from 'constants/dexes';
import { COMMON_TOKENS } from 'constants/tokens';
import { FEE_RATES } from 'constants/ticks';

import { compactNumber, currencyFormat } from 'util/numberFormatter';
import { useRouter } from 'util/router';

import Flex from 'components/Flex';
import Toggle from 'components/Toggle';
import FullLoader from 'components/FullLoader';
import FullError from 'components/FullError';
import RefreshBtn from 'components/RefreshBtn';

import { RenderedChart as TickChartComponent, filterTicks } from 'pages/uniswap/Position/TickChart/TickChart';
import MyPositionInfo from 'pages/uniswap/Position/TickChart/MyPositionInfo';
import useFormatPoolData from 'pages/uniswap/Position/TickChart/hooks/useFormatPoolData';
import useGetUniV3PairAddress from 'hooks/useGetUniV3PairAddress';
import './Analyze.scss';

const CHAINS_FOR_ANALYZE = [chains.MAINNET, chains.OPTIMISM, chains.POLYGON, chains.ARBITRUM];
const DEFAULT_SELECTED_CHAIN = chains.MAINNET;

const styles = {
	button: {
		lineHeight: 1,
		fontSize: '0.7rem',
		fontWeight: 500,
		padding: '.25rem',
	}
};

const getValidDexesByChain = (chainId) => !chainId ? Object.keys(dexes)
	: Object.entries(dexes).filter(
		([dex, chainsObj]) => Object.keys(chainsObj).some(id => chainId === Number(id))
	).map(([dex]) => dex);

const aprPretty = (apr) => {
  const floor = a => Math.floor(a * 10) / 10;
  const floored = String(floor(apr));

  if (!floored || isNaN(floored)) {
  	return '-';
  }

  if (!/\./.test(floored)) {
    if (floored.length > 2) return `${floored}%`;
    else if (floored.length === 2) return `${floored}.0%`;
    else if (floored.length < 2) return `${floored}.00%`;
  } else {
    return `${floored.padEnd(4, '0')}%`;
  }
};

function Analyze(props) {
	const router = useRouter();
	const {
		chainId: chainIdFromRouter,
		token0: token0FromRouter,
		token1: token1FromRouter
	} = router.query;

	const [selectedChainId, setSelectedChainId] = useState(chainIdFromRouter || DEFAULT_SELECTED_CHAIN);
	const [selectedToken0, setSelectedToken0] = useState(token0FromRouter);
	const [selectedToken1, setSelectedToken1] = useState(token1FromRouter);
	const [selectedDexes, setSelectedDexes] = useState(getValidDexesByChain(selectedChainId));
	const [selectedFeeRates, setSelectedFeeRates] = useState(FEE_RATES);
	const [selectedVolForCalc, setSelectedVolForCalc] = useState('h24');
	const [rangeByPerc, setRangeByPerc] = useState({low: null, high: null});

	const configStateValid = !!selectedChainId && selectedDexes.length > 0 && !!selectedToken0 && !!selectedToken1 && selectedFeeRates.length > 0;

	return (
		<Container className="Analyze py-4 py-lg-5">
			<Config
				selectedChainId={selectedChainId}
				setSelectedChainId={setSelectedChainId}
				selectedToken0={selectedToken0}
				setSelectedToken0={setSelectedToken0}
				selectedToken1={selectedToken1}
				setSelectedToken1={setSelectedToken1}
				selectedDexes={selectedDexes}
				setSelectedDexes={setSelectedDexes}
				selectedFeeRates={selectedFeeRates}
				setSelectedFeeRates={setSelectedFeeRates}
			/>
			{!configStateValid ? (
				<div className="text-gray-600 f-rem-0.9 text-center mt-3">Select a chain, at least 1 dex, at least 1 fee rate, and two tokens</div>
			) : (
				<Pools
					selectedChainId={selectedChainId}
					selectedToken0={selectedToken0}
					selectedToken1={selectedToken1}
					selectedDexes={selectedDexes}
					selectedFeeRates={selectedFeeRates}
					selectedVolForCalc={selectedVolForCalc}
					setSelectedVolForCalc={setSelectedVolForCalc}
					rangeByPerc={rangeByPerc}
					setRangeByPerc={setRangeByPerc}
				/>
			)}
		</Container>
	);
}

const Config = ({ selectedChainId, setSelectedChainId, selectedDexes, setSelectedDexes, selectedToken0, setSelectedToken0, selectedToken1, setSelectedToken1, selectedFeeRates, setSelectedFeeRates, percTickRange, setPercTickRange }) => {
	const tokensList = COMMON_TOKENS?.[selectedChainId];
	const validDexOptions = getValidDexesByChain(selectedChainId);
	const getTokenList = (idx) => {
		if (idx === 0) {
			return selectedToken1 ? tokensList.filter(
				o => o.address.toLowerCase() !== selectedToken1.address.toLowerCase()
			) : tokensList;
		} else {
			return selectedToken0 ? tokensList.filter(
				o => o.address.toLowerCase() !== selectedToken0.address.toLowerCase()
			) : tokensList;
		}
	}

	return (
		<Card className="Config shadow border-0 rounded p-3">
			<Flex justify="between" wrap className="w-100">
				<Flex direction="column" className="mb-3 mb-md-0">
					{/* CHAIN */}
					<Flex direction="column" className="ChainSelectorDropdown mb-3">
						<span className="label">Select Chain</span>
						<Toggle
							className="align-self-start shadow-sm f-rem-0.8"
							ops={CHAINS_FOR_ANALYZE.map(chainId => chainNameMap[chainId])}
							active={(chainName) => chainIdMap[chainName] === selectedChainId}
							setActive={(chainName) => {
								const chainId = chainIdMap[chainName];
								setSelectedChainId(chainId);
								setSelectedDexes(prev => {
									const validDexes = getValidDexesByChain(chainId);
									return prev.filter(dex => validDexes.includes(dex));
								});
								setSelectedToken0(null);
								setSelectedToken1(null);
							}}
						/>
					</Flex>
					{/* TOKENS */}
					<Flex justify="center" className="TokensSelectorDropdowns justify-content-lg-start">
						{[0, 1].map(idx => (
							<Flex
								key={idx}
								direction="column"
								className={classnames('TokenDropdownContainer', { 'ml-2': idx > 0 })}
							>
								<span className="label">Select Token{idx}</span>
								<Dropdown className="TokenDropdown">
									<Dropdown.Toggle size="sm" variant="none" className="py-0 f-rem-0.85 shadow-sm">
										{(idx === 0 ? selectedToken0 : selectedToken1)?.symbol || "Select token"}
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{getTokenList(idx).map(t => (
											<Dropdown.Item
												key={t.symbol}
												onClick={() => idx === 0 ? setSelectedToken0(t) : setSelectedToken1(t)}
											>
												{t.symbol}
											</Dropdown.Item>
										))}
									</Dropdown.Menu>
								</Dropdown>
							</Flex>
						))}
					</Flex>
				</Flex>
				<Flex direction="column">
					{/* Dexes */}
					<Flex direction="column" className="mb-3">
						<span className="label">Select dex(es)</span>
						<Toggle
							className="align-self-start shadow-sm f-rem-0.8"
							ops={validDexOptions}
							active={selectedDexes}
							setActive={(dex) => {
								setSelectedDexes(prev => prev.includes(dex) ? prev.filter(o => o !== dex) : [...prev, dex])
							}}
						/>
					</Flex>
					{/* Fee Rates */}
					<Flex direction="column" className="justify-self-end">
						<span className="label">Select Fee Rates</span>
						<Toggle
							className="align-self-start shadow-sm f-rem-0.8"
							ops={FEE_RATES.map(f => `${f/10000}%`)}
							active={selectedFeeRates.map(f => `${f/10000}%`)}
							setActive={(feeRate) => {
								const formatted = Number(feeRate.replace('%', ''))*10000;
								setSelectedFeeRates(prev => prev.includes(formatted) ? prev.filter(o => o !== formatted) : [...prev, formatted])
							}}
						/>
					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
};

const Pools = ({selectedChainId: chainId, selectedDexes: dexes, selectedToken0: token0, selectedToken1: token1, selectedFeeRates, selectedVolForCalc, setSelectedVolForCalc, rangeByPerc, setRangeByPerc}) => {
	const [selectedTickRange, setSelectedTickRange] = useState({});
	const [liqInTickRange, setLiqInTickRange] = useState({});

	const poolsForRender = dexes.flatMap(dex => {
		if (dex !== 'uniswap') return { token0, token1, dex };
		else return selectedFeeRates.map(feeRate => ({
			token0, token1, dex, feeRate
		}));
	});

	function sortPoolsForRender(a, b) {
		if (a.dex === 'uniswap' && b.dex === 'uniswap') return a.feeRate - b.feeRate;
		else return b.dex.localeCompare(a.dex);
	}

	const hasUniswap = dexes.includes('uniswap');
	const feeRates = hasUniswap && poolsForRender.filter(p => p.dex === 'uniswap').map(p => p.feeRate);
	const uniPool =  hasUniswap && new Pool(token0, token1, 100, TickMath.getSqrtRatioAtTick(TickMath.MIN_TICK), '0', TickMath.MIN_TICK);
	const Token0 = uniPool?.token0 || token0;
	const Token1 = uniPool?.token1 || token1;

	return (
		<Flex direction="column">
			{hasUniswap && (
				<UniswapTickSelector
					chainId={chainId}
					Token0={Token0}
					Token1={Token1}
					setLiqInTickRange={setLiqInTickRange}
					selectedTickRange={selectedTickRange}
					setSelectedTickRange={setSelectedTickRange}
					feeRates={feeRates}
					selectedVolForCalc={selectedVolForCalc}
					setSelectedVolForCalc={setSelectedVolForCalc}
					rangeByPerc={rangeByPerc}
					setRangeByPerc={setRangeByPerc}
				/>
			)}

			{poolsForRender.sort(sortPoolsForRender).map((pool, idx) => (
				<RenderedPool
					key={idx}
					chainId={chainId}
					dex={pool.dex}
					Token0={Token0}
					Token1={Token1}
					feeRate={pool.feeRate}
					selectedTickRange={selectedTickRange}
					setSelectedTickRange={setSelectedTickRange}
					liqInTickRange={liqInTickRange}
					setLiqInTickRange={setLiqInTickRange}
					selectedVolForCalc={selectedVolForCalc}
				/>
			))}
		</Flex>
	);
};

const UniswapTickSelector = ({chainId, Token0, Token1, setLiqInTickRange, selectedTickRange, setSelectedTickRange, feeRates = [], selectedVolForCalc, setSelectedVolForCalc, rangeByPerc, setRangeByPerc}) => {
	const {
		data: tickEnds = {},
		isLoading: tickEndsIsLoading
	} = useGetTickEndsFromPoolsQuery({
		chainId,
		feeRates,
		token0Address: Token0.address,
		token1Address: Token1.address,
	});

	const [minValue, setMinValue] = useState(null);
	const [maxValue, setMaxValue] = useState(null);

	const { tickIdxLowerBound, tickIdxUpperBound } = tickEnds;

	useEffect(() => {
		if (!tickEndsIsLoading && minValue === null) {
			setMinValue(tickIdxLowerBound);
			setMaxValue(tickIdxUpperBound);
		}
	}, [tickEndsIsLoading, minValue, tickIdxLowerBound, tickIdxUpperBound]);

	/* Need this bc we need to watch for a change to selectedTickRange
	 * which can happen externally to this Component */
	const [finalMinValue, finalMaxValue] = useMemo(() => {
		if (!tickEndsIsLoading && minValue !== null) {
			const minValueIsOff = selectedTickRange?.minTickIdx && selectedTickRange.minTickIdx !== minValue;
			const maxValueIsOff = selectedTickRange?.maxTickIdx && selectedTickRange.maxTickIdx !== maxValue;

			return [
				!minValueIsOff ? minValue : max([selectedTickRange?.minTickIdx, tickIdxLowerBound]),
				!maxValueIsOff ? maxValue : min([selectedTickRange?.maxTickIdx, tickIdxUpperBound]),
			];
		} else {
			return [minValue, maxValue];
		}
	}, [tickEndsIsLoading, minValue, maxValue, selectedTickRange?.minTickIdx, selectedTickRange?.maxTickIdx, tickIdxLowerBound, tickIdxUpperBound]);

	const [minTickPrice, maxTickPrice] = useMemo(() => {
		return [
			tickToPrice(Token0, Token1, finalMinValue || 0),
			tickToPrice(Token0, Token1, finalMaxValue || 0)
		]
	}, [Token0, Token1, finalMinValue, finalMaxValue]);

	function handleTickChange([min, max]) {
		setMinValue(min);
		setMaxValue(max);
		setSelectedTickRange({
			minTickIdx: min,
			maxTickIdx: max,
		});
	};

	function handlePercentageChange(perc) {

	};

	return tickEndsIsLoading ? (
		<FullLoader text="tick selector" classNameOverrides="p-0 my-3" />
	) : (
		<div className="UniswapTickSelector my-3">
			<Row>
				<Col xs={12} lg={4} xl={3}>
					<div className="shadow border-0 rounded bg-white h-100 py-2 px-3">
						<Flex direction="column">
							<span className="text-gray-600 f-rem-0.7 fw-6 border-bottom pb-1 mb-2">Controls</span>
							<span
								className="text-primary f-rem-0.85 pointer mb-2"
								onClick={() => {
									setSelectedTickRange({});
									setLiqInTickRange({});
									setMinValue(tickIdxLowerBound);
									setMaxValue(tickIdxUpperBound);
								}}
							>
								Reset tick selection
							</span>
							{/* Vol selector for apr calc*/}
							<Toggle
								className="shadow-sm f-rem-0.85"
								ops={['m5', 'h1', 'h6', 'h24']}
								active={(op) => op === selectedVolForCalc}
								setActive={(op) => setSelectedVolForCalc(op)}
								label="Timerange for APR Calc"
								labelClassName="f-rem-0.75 text-gray-600 mb-0.5"
							/>
						</Flex>
					</div>
				</Col>
				<Col xs={12} lg={8} xl={9}>
					<Flex direction="column">
						{/*Control by perc*/}
						<div className="shadow border-0 rounded bg-white mb-3">
							<Flex>
								<TickPercentageRangeInput
									selectedTickRange={selectedTickRange}
									isLowerBound={true}
									handleOnChange={handlePercentageChange}
									rangeByPerc={rangeByPerc}
									setRangeByPerc={setRangeByPerc}
								/>
								<TickPercentageRangeInput
									selectedTickRange={selectedTickRange}
									isLowerBound={false}
									handleOnChange={handlePercentageChange}
									rangeByPerc={rangeByPerc}
									setRangeByPerc={setRangeByPerc}
								/>
							</Flex>
						</div>
						{/*Control by slider*/}
						<div className="shadow border-0 rounded bg-white">
							<Flex justify="center" className="rc-slider-wrapper w-100">
								<Slider
									range
									allowCross={false}
									reverse
									min={tickIdxLowerBound}
									max={tickIdxUpperBound}
									defaultValue={[0, 20]}
									value={[finalMinValue, finalMaxValue]}
									onChange={handleTickChange}
								/>
							</Flex>
							<Flex justify="between" className="label-container w-100">
								<Flex direction="column">
									<span className="price">{minTickPrice.toSignificant(6)} {Token0.symbol}/{Token1.symbol}</span>
									<span className="tick">Min tick: {finalMinValue}</span>
								</Flex>
								<Flex direction="column" className="text-right">
									<span className="price">{maxTickPrice.toSignificant(6)} {Token0.symbol}/{Token1.symbol}</span>
									<span className="tick">Max tick: {finalMaxValue}</span>
								</Flex>
							</Flex>
						</div>
					</Flex>
				</Col>
			</Row>
		</div>
	);
};

const TickPercentageRangeInput = ({selectedTickRange, isLowerBound, handleOnChange, rangeByPerc, setRangeByPerc}) => {
	const ref = useRef();
	const sign = isLowerBound ? '-' : '+';
	const [value, setValue] = useState(`${sign}0`);

	function handleChange(e) {
		if (!e.target.value) {
			return setValue(`${sign}0`);
		}

		const [_preDecimal, postDecimal] = e.target.value.replace(/-|\+/, '').split('.');
		const preDecimal = _preDecimal ? Number(_preDecimal) : undefined;
		const combined = [preDecimal, postDecimal].filter(
			o => !!o || o === 0
		).join('.');
		const newValue = `${sign}${combined}`;

		return setValue(newValue);
	};

	return (
		<Flex>
			<Form.Control
				ref={ref}
				type="number"
				step="0.01"
				name="tickPercentageRangeInput"
				placeholder="0"
				onChange={handleChange}
				value={value}
				style={{borderLeft: 0, borderRight: 0}}
			/>
		</Flex>
	);
};

const RenderedPool = ({chainId, dex, Token0, Token1, feeRate, selectedTickRange, setSelectedTickRange, liqInTickRange, setLiqInTickRange, selectedVolForCalc}) => {
	const pairAddress = useGetUniV3PairAddress(Token0, Token1, feeRate);

	return (
		<Row className="mb-3">
			<Col xs={12} lg={4} xl={3}>
				<PoolInfo
					chainId={chainId}
					feeRate={feeRate}
					pairAddress={pairAddress}
					liqInTickRange={liqInTickRange}
					Token0={Token0}
					Token1={Token1}
					selectedVolForCalc={selectedVolForCalc}
				/>
			</Col>
			<Col xs={12} lg={8} xl={9}>
				{dex === 'uniswap' ? (
					<TickChartWrapper
						chainId={chainId}
						pairAddress={pairAddress}
						feeRate={feeRate}
						Token0={Token0}
						Token1={Token1}
						selectedTickRange={selectedTickRange}
						setSelectedTickRange={setSelectedTickRange}
						liqInTickRange={liqInTickRange}
						setLiqInTickRange={setLiqInTickRange}
					/>
				) : (
					<Card className="shadow border-0 rounded p-3">No ticks</Card>
				)}
			</Col>
		</Row>
	);
};

export const PoolInfo = ({chainId, pairAddress, feeRate, liqInTickRange = {}, Token0, Token1, selectedVolForCalc, className = ''}) => {
	const {
	  data: poolSummary = {},
	  isLoading,
	  refetch,
	  isFetching,
	} = useGetPoolQuery({ pairAddress, chainName: chainNameMap[chainId] });

	const { dexId, feeRate: altFeeRate, liquidity, priceUsd, volume = {}, baseToken } = poolSummary;
	const feeAsPercentage = feeRate ? `${feeRate/10000}%` : `${altFeeRate*100}%`;

	const liqInTickRangeForPool = liqInTickRange?.[feeRate];
	const customLiqFromRange = useMemo(() => {
		const hasReqData = !isLoading && !!liqInTickRangeForPool?.token0Address && !!baseToken?.address;

		if (hasReqData) {
			const { token0Address, tvlToken0, tvlToken1 } = liqInTickRangeForPool;
			return token0Address.toLowerCase() === baseToken.address.toLowerCase()
				? tvlToken0
				: tvlToken1;
		}

		return undefined;
	}, [isLoading, liqInTickRangeForPool, baseToken?.address]);

	const volumeForAprCalc = useMemo(() => {
		let multiplier;

		switch(selectedVolForCalc) {
			case 'h24':
				multiplier = 1;
				break;
			case 'h6':
				multiplier = 4;
				break;
			case 'h1':
				multiplier = 24;
				break;
			case 'm5':
				multiplier = 288;
				break;
			default:
				multiplier = 1;
		}

		return (volume?.[selectedVolForCalc] || 0) * multiplier;
	}, [volume, selectedVolForCalc]);

	const liquidityOverride = (customLiqFromRange ? (customLiqFromRange * priceUsd) : false) || liquidity?.usd;
	const apr = !liquidityOverride ? null : (volumeForAprCalc * (feeRate ? feeRate/100 : altFeeRate*10000) * 360)/(liquidityOverride*100);

	const getPriceLabel = label => label.split(/([0-9]+)/).filter(o => o).reverse().join('');

	const chainName = chainNameMap[chainId];
	const poolLink = `https://app.uniswap.org/#/add/${Token0.address}/${Token1.address}/${feeRate}?chain=${chainName}`;
	const poolInfoLink = `https://info.uniswap.org/#/${chainName}/pools/${pairAddress?.toLowerCase()}`;

	function openLink(link) {
	  const win = window.open(link, '_blank');
	  win.focus();
	}

	return isLoading ? (
		<FullLoader text="pool info" classNameOverrides="p-0" />
	) : (
		<Flex
			direction="column"
			className={`PoolInfo rounded px-3 pb-3 pt-0 bg-white ${className}`.trim()}
			pairaddress={`${pairAddress}`}
		>
			{/* Chain name */}
			<Flex
				justify="center"
				className="py-2 bg-gray-100 mb-2.5"
				style={{marginLeft: '-1rem', marginRight: '-1rem'}}
			>
				<span
					className="text-capitalize"
					style={{color: chainColors[chainId]}}
				>
					{chainName}
				</span>
			</Flex>
			{/* Dex, Fee, Price */}
			<Flex justify="between" className="title-container w-100">
				<div>
					<span className="dex">{dexId}</span>
					<Badge className="fee ml-1.5 border shadow-sm text-secondary">{feeAsPercentage}</Badge>
					<RefreshBtn
					  refreshing={isFetching}
					  action={refetch}
					  style={{fontSize: '0.7rem', backgroundColor: 'white', paddingTop: '.3rem', paddingBottom: '.25rem', marginLeft: '.5rem'}}
					/>
				</div>
				<span className="price ml-3">{currencyFormat(priceUsd)}</span>
			</Flex>
			{/* Liq and APR */}
			<Flex justify="between" className="info-container w-100 mt-2.5">
				<Flex direction="column" className="info-item">
					<span className="label">Liquidity</span>
					<span
						className={classnames('value', { 'text-info': !!customLiqFromRange })}
					>
						${compactNumber(liquidityOverride || liquidity?.usd, '-')}
					</span>
				</Flex>
				<Flex direction="column" className="info-item">
					<span className="label">Est APR</span>
					<span
						className={classnames('value', { 'text-info': !!customLiqFromRange })}
					>
						{aprPretty(apr)}
					</span>
				</Flex>
			</Flex>
			{/* Volume */}
			<Flex direction="column" className="info-container mt-2">
				<span className="title mb-1.5">Volume</span>
				<Flex justify="between">
					{Object.entries(volume).reverse().map(([time, value], idx) => (
						<Flex key={idx} direction="column" className="info-item">
							<span className="label">{getPriceLabel(time)}</span>
							<span className="value">${compactNumber(value)}</span>
						</Flex>
					))}
				</Flex>
			</Flex>
			{/* Links */}
			<Flex justify="center" className="mt-1 pt-2.5 border-top">
				<Button variant="gray-700" size="sm" className="shadow-sm" style={styles.button} onClick={() => openLink(poolLink)}>
					Liquidity <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-1" style={{verticalAlign: 'baseline'}}/>
				</Button>
				<Button variant="info-dark" size="sm" className="shadow-sm ml-2" style={styles.button} onClick={() => openLink(poolInfoLink)}>
					Info <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-1" style={{verticalAlign: 'baseline'}}/>
				</Button>
			</Flex>
		</Flex>
	);
};

const TickChartWrapper = ({chainId, pairAddress, feeRate, Token0, Token1, selectedTickRange, setSelectedTickRange, liqInTickRange, setLiqInTickRange}) => {
	const {
		data: poolState = {},
		isLoading: poolStateIsLoading,
	} = useGetPoolStateQuery({
		chainId,
		pairAddress,
		feeRate,
	}, {
		pollingInterval: 60 * 1000,
	});

	const {
    data: tickData = {},
    isLoading: tickDataIsLoading,
  } = useGetTickDataQuery({
  	chainId,
  	token0Address: Token0.address,
  	token1Address: Token1.address,
  	feeRate,
  	// pool: poolState
  }, {
  	skip: poolStateIsLoading,
  	pollingInterval: 60 * 1000,
  });

  const {
  	data: formattedTickData = [],
  	isLoading: formatPoolDataIsLoading,
  } = useFormatPoolData({ tickData, pool: poolState, position: {} }, {
  	skip: poolStateIsLoading || tickDataIsLoading
  });

  const isLoading = poolStateIsLoading || tickDataIsLoading || formatPoolDataIsLoading;

  const filteredTicks = useMemo(() => {
  	return filterTicks(formattedTickData);
  }, [formattedTickData]);

  const dimTicks = useMemo(() => {
  	return Object.keys(selectedTickRange).length > 0 ? {
	  	lessThan: nearestUsableTick(selectedTickRange.minTickIdx, TICK_SPACINGS[feeRate]),
	  	greaterThan: nearestUsableTick(selectedTickRange.maxTickIdx, TICK_SPACINGS[feeRate]),
	  } : null;
  }, [selectedTickRange, feeRate]);

  const ticksInSelectedRange = useMemo(() => {
  	return dimTicks ? filteredTicks.filter(
  		tick => tick.tickIdx >= dimTicks.lessThan && tick.tickIdx <= dimTicks.greaterThan
  	) : [];
  }, [dimTicks, filteredTicks]);

  const liquidityInSelectedTickRange = useMemo(() => {
  	return ticksInSelectedRange.reduce((acc, tick) => {
  		const { tvlToken0, tvlToken1, price0, price1 } = tick;
  		const tvlToken1InTermsOfToken0 = tvlToken1 * price1;
  		const tvlToken0InTermsOfToken1 = tvlToken0 * price0;

  		acc.tvlToken0 += tvlToken0 || tvlToken1InTermsOfToken0;
  		acc.tvlToken1 += tvlToken1 || tvlToken0InTermsOfToken1;

  		return acc;
  	}, { token0Address: Token0.address, tvlToken0: 0, tvlToken1: 0 });
  }, [ticksInSelectedRange, Token0.address]);

  const setLiqInTickRangeDebounce = debounce(setLiqInTickRange, 100);

  if (
  	Object.keys(selectedTickRange).length > 0 &&
  	(
  		liqInTickRange?.[feeRate]?.tvlToken0 !== liquidityInSelectedTickRange?.tvlToken0 ||
  		liqInTickRange?.[feeRate]?.tvlToken1 !== liquidityInSelectedTickRange?.tvlToken1
  	)
  ) {
  	setLiqInTickRangeDebounce(prev => ({
	  	...prev,
	  	[feeRate]: liquidityInSelectedTickRange,
	  }));
  }

  const handleTickTap = useCallback((tickInChartProps) => {
  	const { tickIdx: activeTickIdx } = filteredTicks.find(tick => !!tick.isCurrent) || {};
  	const { tickIdx } = tickInChartProps;

  	setSelectedTickRange(prev => {
  		const newState = { ...prev };

  		const hasMinTick = !!prev.minTickIdx && prev.minTickIdx >= TickMath.MIN_TICK;
  		const hasMaxTick = !!prev.maxTickIdx && prev.maxTickIdx <= TickMath.MAX_TICK;

  		if (!hasMinTick && !hasMaxTick) {
  			if (tickIdx <= activeTickIdx) {
	  			newState.minTickIdx = tickIdx;
	  			newState.maxTickIdx = TickMath.MAX_TICK;
  			} else {
  				newState.maxTickIdx = tickIdx;
  				newState.minTickIdx = TickMath.MIN_TICK;
  			}
  		} else {
  			if (
  				tickIdx >= prev.maxTickIdx ||
  				(prev.maxTickIdx === TickMath.MAX_TICK && prev.minTickIdx > TickMath.MIN_TICK)
  			) {
  				newState.maxTickIdx = tickIdx;
  			} else if (
  				tickIdx <= prev.minTickIdx ||
  				(prev.minTickIdx === TickMath.MIN_TICK && prev.maxTickIdx < TickMath.MIN_TICK)
  			) {
  				newState.minTickIdx = tickIdx;
  			} else {
  				const midPoint = Math.floor((prev.maxTickIdx + prev.minTickIdx)/2);

  				if (tickIdx <= midPoint) {
  					newState.minTickIdx = tickIdx;
  				} else {
  					newState.maxTickIdx = tickIdx;
  				}
  			}
  		}

  		return newState;
  	});
  }, [filteredTicks, setSelectedTickRange]);

	return isLoading ? (
			<FullLoader text="ticks" classNameOverrides="p-0" />
		) : (
		<Card className="TickChart shadow border-0 rounded">
			<TickChartComponent
				pool={poolState}
				filteredTicks={filteredTicks}
				chartHeight={176}
				hideCurrentPriceLabel={true}
				dimTicks={dimTicks}
				onTickTap={handleTickTap}
			/>
			<MyPositionInfo
				pool={poolState}
				ticks={ticksInSelectedRange.map(t => ({...t, isTickInPosition: true}))}
				mini={true}
			/>
		</Card>
	);
};

export default Analyze;
