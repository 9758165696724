export function truncateWallet(address = '', placeholderChar = '•', includeFirstFour = true) {
	const firstFour = address.slice(0,4);
	const lastFour = [...address].reverse().slice(0,4).reverse().join('');
	const placeholders = [...Array(4).keys()].map(() => placeholderChar).join('');

	return [
		...includeFirstFour ? [firstFour] : [],
		placeholders,
		lastFour,
	].join('');
}
