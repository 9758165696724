import React from 'react';
import classnames from 'classnames';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

function FullLoader({ text, flat = false, classNameOverrides }) {
	return (
		<Container className={classNameOverrides || "py-4 py-lg-5"}>
			<Card
				className={classnames('border-0 rounded', {
					shadow: !flat,
					'bg-transparent': flat
				})}
			>
				<Card.Body>
					<div className="w-100 text-secondary text-center">
						<FontAwesomeIcon icon={faSpinnerThird} spin className="mr-1" /> Loading {text}
					</div>
				</Card.Body>
			</Card>
		</Container>
	);
}

export default FullLoader;
