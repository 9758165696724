import { useEffect, useRef } from "react";

const useOnFocus = fn => {
  const cb = useRef(fn);

  useEffect(() => {
    const onFocus = cb.current;
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [cb]);
};

export default useOnFocus;
