import supportedChainId from './chains';

const urls = {
	[supportedChainId.MAINNET]: "https://etherscan.io/tx",
	[supportedChainId.OPTIMISM]: "https://optimistic.etherscan.io/tx",
	[supportedChainId.BINANCE]: "https://bscscan.com/tx",
	[supportedChainId.POLYGON]: "https://polygonscan.com/tx",
}

const genTxHashUrl = (txHash, chainId) => `${urls[chainId]}/${txHash}`;

export default genTxHashUrl;
