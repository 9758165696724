export const chains = {
  MAINNET: 1,
  OPTIMISM: 10,
  BINANCE: 56,
  POLYGON: 137,
  ARBITRUM: 42161,
  AVALANCHE: 43114,
};

export const chainNameMap = {
  [chains.MAINNET]: 'mainnet',
  [chains.OPTIMISM]: 'optimism',
  [chains.BINANCE]: 'binance',
  [chains.POLYGON]: 'polygon',
  [chains.ARBITRUM]: 'arbitrum',
  [chains.AVALANCHE]: 'avalanche',
};

export const chainIdMap = {
  'ethereum': chains.MAINNET,
  'mainnet': chains.MAINNET,
  'optimism': chains.OPTIMISM,
  'binance': chains.BINANCE,
  'bsc': chains.BINANCE,
  'polygon': chains.POLYGON,
  'arbitrum': chains.ARBITRUM,
  'avalanche': chains.AVALANCHE,
};

export const chainExplorers = {
  [chains.MAINNET]: "https://etherscan.io",
  [chains.OPTIMISM]: "https://optimistic.etherscan.io",
  [chains.BINANCE]: "https://bscscan.com",
  [chains.POLYGON]: "https://polygonscan.com",
  [chains.ARBITRUM]: "https://arbiscan.io",
  [chains.AVALANCHE]: "https://snowtrace.io/",
};

export const chainColors = {
  [chains.MAINNET]: "#454a74",
  [chains.OPTIMISM]: "#f80d22",
  [chains.BINANCE]: "#f0ba0c",
  [chains.POLYGON]: "#8247e5",
  [chains.ARBITRUM]: "#2c374b",
  [chains.AVALANCHE]: "#e3453d",
};

export default chains;

