import queryConstructor from '../../util/queryConstructor';
import { keepUnusedDataFor } from './constants';

export const handlers = (builder) => ({
	getPositions: builder.query({
		query: ({chainId, wallet, pids = []}) => {
			const queryParams = queryConstructor({
				chainId, wallet, pids: pids.join(','),
			});

			return `positions/fetchPositions?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getTickData: builder.query({
		query: ({chainId, token0Address, token1Address, feeRate}) => {
			const queryParams = queryConstructor({
				chainId, token0Address, token1Address, feeRate
			});

			return `positions/fetchTickData?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getMyPositionsAddresses: builder.query({
		query: ({chainId, chainName, wallet}) => {
			const queryParams = queryConstructor({
				chainId, chainName, wallet
			});

			return `positions/fetchMyPositionsAddresses?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getRouteToRatioCalldata: builder.query({
		query: ({chainId, pid, token0AmountToAdd = 25, token1AmountToAdd = 0.0015, wallet}) => {
			const queryParams = queryConstructor({chainId, pid, token0AmountToAdd, token1AmountToAdd, wallet});
			return `positions/getRouteToRatioCalldata?${queryParams}`;
		},
		keepUnusedDataFor: 0,
	}),
});

export default handlers;
