import React from "react";
import altLogos from '../../constants/altLogos';
import { chainNameMap } from '../../constants/chains';
import './Logo.scss';

const getUrl = (address = '', chainNameOrId) => {
	const chainName = !isNaN(Number(chainNameOrId)) ? chainNameMap[chainNameOrId] : chainNameOrId;

	return altLogos['all'][address.toLowerCase()]
		|| `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${chainName}/assets/${address}/logo.png`;
}

const Logos = React.memo((props) => {
	const { token, className = '', ...rest } = props;

	const onError = (e) => {
		e.currentTarget.onerror = null;
		e.currentTarget.src = '/img/token-not-found.png';
		e.currentTarget.classList.add('not-found');
	}

	return (
		<img
			className={`Logo ${className}`.trim()}
			src={token.src || getUrl(token.address, token.chainName || token.chainId)}
			alt={token.symbol}
			onError={onError}
			{...rest}
		/>
	);
})

export default Logos;
