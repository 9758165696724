import React, { useState, useMemo, useRef } from 'react';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import Form from 'react-bootstrap/Form';

import {
	useGetPoolsQuery,
	useGetDexesFromPairsQuery,
	useGetMyPositionsAddressesQuery,
} from 'api/client';
import { chainNameMap } from 'constants/chains';
import { useLocation, useParams, Link } from 'util/router';
import { currencyFormat, commaFormat, compactNumber } from 'util/numberFormatter';
import { capFirstOfEach } from 'util/stringFormatter';
import { escapeCharacters } from 'util/strings';

import FullLoader from 'components/FullLoader';
import FullError from 'components/FullError';
import SortIcon from 'components/SortIcon';
import Flex from 'components/Flex';
import Logo from 'components/Logo';
import RefreshBtn from 'components/RefreshBtn';

import './Pools.scss';

const CHAIN_NAMES = ['ethereum', 'optimism', 'bsc', 'polygon'];
const TABLE_HEADERS = ['pool','price','5m','1h','6h','24h','txns','volume','liq','apr'];
const MY_LISTS = ['myPositions'];

function searchPools(pools = [], search) {
	if (!search) return pools;

	const filterSearch = escapeCharacters(search).split(' ').filter(o => !!o);
	const searchRegExp = filterSearch.map(o => new RegExp(o, 'i'));

	return pools.filter(
		pool => searchRegExp.every(
			regExp =>
				regExp.test(pool.baseToken.symbol) ||
				regExp.test(pool.quoteToken.symbol) ||
				regExp.test(pool.baseToken.name)
		)
	)
};

function sortPools(pools = [], sort) {
	const [col, dir] = sort;

	return [...pools].sort((a, b) => {
		let sortVal;
		const [first, second] = dir === 'asc' ? [a, b] : [b, a];

		switch (col) {
			case 'pool':
				const firstPoolName = `${first.baseToken.symbol}${first.quoteToken.symbol}`;
				const secondPoolName = `${second.baseToken.symbol}${second.quoteToken.symbol}`;
				sortVal = firstPoolName.localeCompare(secondPoolName);
				break;
			case 'price':
				sortVal = first.priceUsd - second.priceUsd;
				break;
			case 'txns':
				sortVal = first.txns.h24 - second.txns.h24;
				break;
			case 'volume':
				sortVal = first.volume.h24 - second.volume.h24;
				break;
			case '5m':
				sortVal = first.priceChange.m5 - second.priceChange.m5;
				break;
			case '1h':
				sortVal = first.priceChange.h1 - second.priceChange.h1;
				break;
			case '6h':
				sortVal = first.priceChange.h6 - second.priceChange.h6;
				break;
			case '24h':
				sortVal = first.priceChange.h24 - second.priceChange.h24;
				break;
			case 'liq':
				sortVal = first.liquidity.usd - second.liquidity.usd;
				break;
			case 'apr':
				sortVal = first.apr - second.apr;
				break;
			default:
				const _firstPoolName = `${first.baseToken.symbol}${first.quoteToken.symbol}`;
				const _secondPoolName = `${second.baseToken.symbol}${second.quoteToken.symbol}`;
				sortVal = _firstPoolName.localeCompare(_secondPoolName);
				break;
		}

		return sortVal;
	});
}

const ChainSelector = ({ selectedChainName }) => {
	return (
		<Flex direction="column" align="center" className="ChainSelector">
			{CHAIN_NAMES.map((chainName, i) => {
				const isActive = chainName === selectedChainName;

				return (
					<Link
						key={chainName}
						className={classnames('logo-container', {
							'mt-3': i > 0,
							'active': isActive,
						})}
						to={isActive ? '/monitor' : `/monitor/${chainName}`}
					>
						<Logo
							token={{src: `/img/chains/${chainName}.svg`, symbol: chainName}}
							title={capFirstOfEach(chainName)}
						/>
					</Link>
				);
			})}
		</Flex>
	);
}

const ListsSelector = ({ selectedChainName, selectedList, setSelectedList }) => {
	return (
		<Flex direction="column" align="center" className="ListsSelector">
			{MY_LISTS.map((listName, i) => {
				const isActive = listName === selectedList;

				return (
					<div
						key={listName}
						className={classnames('logo-container mt-3', {
							'active': isActive,
						})}
						onClick={() => setSelectedList(prev => prev === listName ? false : listName)}
					>
						<FontAwesomeIcon icon={faStar} className="text-info" />
					</div>
				);
			})}
		</Flex>
	);
}

const Sidebar = ({ selectedChainName, selectedList, setSelectedList }) => {
	return (
		<div className="Sidebar">
			<ChainSelector selectedChainName={selectedChainName} />
			<div className="divider"></div>
			<ListsSelector
				selectedChainName={selectedChainName}
				selectedList={selectedList}
				setSelectedList={setSelectedList}
			/>
		</div>
	);
}

const DexSelector = ({ selectedChainName, selectedDex, setSelectedDex }) => {
	const {
		data: dexes = [],
	} = useGetDexesFromPairsQuery({chainName: selectedChainName});

	return (
		<ul className="DexSelector">
			{['all', ...dexes].map((dex, i) => (
				<li
					key={i}
					className={classnames({ active: selectedDex === dex })}
					onClick={() => selectedDex !== dex && setSelectedDex(dex)}
				>
					{dex === 'all' ? ("All Dex's") : (
						<>
							<Logo token={{src: `/img/dexes/${dex}.png`, symbol: dex}} className="isDex mr-2"/> {capFirstOfEach(dex)}
						</>
					)}
				</li>
			))}
		</ul>
	);
}

const SearchPools = ({search = '', setSearch}) => {
	const searchRef = useRef();
	const debounceSetSearch = debounce(setSearch, 200);

	function handleChange() {
		const value = searchRef.current.value;

		if (!value) {
			setSearch('');
		} else {
			debounceSetSearch(value);
		}
	}

	return (
		<Form.Control
			ref={searchRef}
			className="Search"
			type="text"
			name="poolSearch"
			placeholder="Search for pool"
			onChange={handleChange}
			style={{borderLeft: 0, borderRight: 0}}
		/>
	);
}

const RenderedPreHeader = ({ pools, isFetching, refetch }) => {
	const [txns, volume] = pools.reduce((acc, p) => {
		acc[0] += p.txns.h24.buys + p.txns.h24.sells;
		acc[1] += p.volume.h24;
		return acc;
	}, [0, 0]);

	return (
		<div className="Preheader">
			<Flex justify="center" align="center" className="summary">
				<span>24h Txns: {commaFormat(txns)}</span>
				<span className="ml-2">24h Volume: {commaFormat(volume, 0)}</span>
			</Flex>
			<Flex justify="center" align="center" className="price-change">
				Price Change
			</Flex>
			<Flex justify="center" align="center" className="activity">
				Activity
			</Flex>
			<Flex justify="center" align="center">
				<RefreshBtn refreshing={isFetching} action={refetch} style={{fontSize: '0.9rem'}}/>
			</Flex>
		</div>
	);
}

const RenderedHeaders = ({ sort, setSort }) => {
	function toggleSortState(col) {
		const newState = sort[0] !== col ? 'desc' : sort[1] === 'desc' ? 'asc' : false;
		setSort(!newState ? [] : [col, newState]);
	}

	const HeaderItem = ({ h }) => {
		const namePretty = capFirstOfEach(h.split('_').join(' '));

		return (
			<div
				id={h}
				className="header-value"
				onClick={() => toggleSortState(h)}
			>
				<span className="mr-2">{namePretty}</span>
				<SortIcon direction={sort[0] === h && sort[1]}/>
			</div>
		);
	}

	return (
		<div className="headers">
			{TABLE_HEADERS.map((h, i) => <HeaderItem key={i} h={h} />)}
		</div>
	);
}

const RenderPoolRow = ({ pool, selectedChainName, selectedDex, pathname }) => {
	const { chainId, dexId, pairAddress, baseToken, quoteToken, priceUsd, txns, volume, priceChange, liquidity, feeRate, apr: aprRaw } = pool;

	const priceChangeClass = value => classnames('row-value', {
		'text-gray-600': value === 0,
		'text-success-dark': value > 0,
		'text-danger-dark': value < 0,
	});

	const aprClass = value => classnames({
		'text-gray-600': !feeRate || !aprRaw,
		// 'text-gray-700': value < 75,
		'text-info-dark': value >= 75,
	});

	const aprPretty = (apr) => {
		const floor = a => Math.floor(a * 10) / 10;
    const floored = String(floor(apr));

    if (!/\./.test(floored)) {
			if (floored.length > 2) return `${floored}%`;
			else if (floored.length === 2) return `${floored}.0%`;
			else if (floored.length < 2) return `${floored}.00%`;
    } else {
      return `${floored.padEnd(4, '0')}%`;
    }
  };

	const apr = aprRaw ? aprPretty(aprRaw) : '-';
	const fee = feeRate ? `${(feeRate*100).toFixed(2)}%` : '-';
	const totalTxns = commaFormat(txns.h24.buys + txns.h24.sells);

	const showChainIcon = !selectedChainName ? <Logo token={{src: `/img/chains/${chainId}.svg`, symbol: chainId}} className="mr-1.5"/> : undefined;

	return (
		<Link
			key={pairAddress}
			id={pairAddress}
			className="table-row"
			to={{
				pathname: `/monitor/${chainId}/${pairAddress}`,
				state: {
					pool,
					from: pathname,
				}
			}}
		>
			<Flex justify="between" align="center" className="row-value pool-name">
				<div>
					{selectedDex === 'all' ? (
						<>
							{showChainIcon}
							<Logo token={{src: `/img/dexes/${dexId}.png`, symbol: dexId}} className="isDex"/>
							<Logo token={{...baseToken, chainName: chainId}} className="padLeft"/>
						</>
					) : (
						<>
							<Logo token={{...baseToken, chainName: chainId}}/>
						</>
					)}
					<span className="ml-2 fw-5 text-gray-700">{baseToken.symbol}</span>
					<span className="px-0.5 text-gray-600">/</span>
					<span className="text-gray-600">{quoteToken.symbol}</span>
				</div>
				<span
					className="copy-link px-1"
					onClick={(e) => {
						e.preventDefault(); //Prevents pool click from navigating to pool page

						copy(pairAddress, {
							format: 'text/plain',
							onCopy: () => toast.success(`Copied pair address`, { autoClose: 3000 })
						})
					}}
				>
					Copy
				</span>
			</Flex>
			<div className="row-value">
				<span>{currencyFormat(priceUsd)}</span>
			</div>
			{Object.entries(priceChange).reverse().map(([label, price], i) => (
				<div key={i} className={priceChangeClass(price)}>
					<span>{price}%</span>
				</div>
			))}
			<div className="row-value">
				<span>{totalTxns}</span>
			</div>
			<div className="row-value">
				<span>${compactNumber(volume.h24)}</span>
			</div>
			<div className="row-value">
				<span>${compactNumber(liquidity.usd)}</span>
			</div>
			<Flex justify="between" align="center" className="row-value">
				{fee && (<span className="f-rem-0.6 fw-5 text-gray-600">{fee}</span>)}
				<span className={aprClass(aprRaw)}>{apr}</span>
			</Flex>
		</Link>
	);
}

function Pools(props) {
	const { wallet } = props;
	const location = useLocation();
	const routerParams = useParams();
	const [selectedList, setSelectedList] = useState(false);
	const [selectedDex, setSelectedDex] = useState('all');
	const { chainName: selectedChainName } = routerParams;
	const [search, setSearch] = useState('');
	const [sort, setSort] = useState(['pool', 'asc']);

	const {
    data: pools = [],
    isLoading,
    isFetching,
    isError,
    error,
    refetch
  } = useGetPoolsQuery({ chainName: selectedChainName });

  const {
  	data: myPositionsAddresses = [],
  } = useGetMyPositionsAddressesQuery({ chainName: selectedChainName, wallet });

  const sortedAndSelected = useMemo(() => {
  	const myPositionsAddressesSet = new Set(myPositionsAddresses);

  	const withMyLists = pools.slice().map(p => {
  		const pool = { ...p };
  		pool.lists = new Set();

  		if (myPositionsAddressesSet.has(pool.pairAddress.toLowerCase())) {
  			pool.lists.add('myPositions');
  		}

  		return pool;
  	});

  	const selectedByList = !selectedList ? withMyLists : withMyLists.filter(p => p.lists.has(selectedList));
  	const selectedByDex = selectedDex === 'all' ? selectedByList : selectedByList.filter(p => p.dexId === selectedDex);
  	const searched = searchPools(selectedByDex, search);
  	const sorted = sortPools(searched, sort);

  	return sorted;
  }, [pools, myPositionsAddresses, selectedList, selectedDex, search, sort]);

  const hasPools = !!pools && !!pools[0];

  return (
  	!hasPools && isLoading ? (
  		<FullLoader text="pools" flat={true} />
  	) : (
  		<>
  			{isError && (<FullError
  				reason={error || 'An unknown error occurred'}
  				callback={refetch}
  				flat={hasPools}
  				customPadding={hasPools ? '3' : undefined}
  			/>)}

  			{hasPools && (
  				<Flex id="PoolsWrapper">
  					<Sidebar
  						selectedChainName={selectedChainName}
  						selectedList={selectedList}
  						setSelectedList={setSelectedList}
  					/>

  					<Flex direction="column" id="PoolsMain">
  						<DexSelector selectedChainName={selectedChainName} selectedDex={selectedDex} setSelectedDex={setSelectedDex}/>
  						<SearchPools search={search} setSearch={setSearch}/>
  						<div className="Pools">
  							<div className={classnames('inner-wrapper', { isFetching })}>
  								<RenderedPreHeader pools={pools} isFetching={isFetching} refetch={refetch} />
  								<RenderedHeaders sort={sort} setSort={setSort} />
  								{sortedAndSelected.length > 0 ? (
  									sortedAndSelected.map((pool, i) => (
  										<RenderPoolRow
  											key={pool.pairAddress}
  											pool={pool}
  											selectedChainName={selectedChainName}
  											selectedDex={selectedDex}
  											pathname={location.pathname}
  										/>
  									))
  								) : (
  									<div className="w-100 text-center my-3">No pools found</div>
  								)}
  							</div>
  						</div>
  					</Flex>
  				</Flex>
  			)}
  		</>
  	)
  );
}

export default Pools;
