import React, { useState, useEffect, useMemo, useRef } from 'react';
import JSBI from 'jsbi';
import { ethers } from 'ethers';
import { Percent, CurrencyAmount } from '@uniswap/sdk-core';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { faSlidersSimple, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

import {
	useGetUsdPriceQuery,
	useGetTokenBalancesQuery,
	useGetSwapQuery,
} from 'api/client';

import { TOKENS, WRAPPED_NATIVE_CURRENCY, DAI } from 'constants/tokens';
import supportedChainId, { chainNameMap } from 'constants/chains';

import { currencyFormat, commaFormat, fixedAsNumber } from 'util/numberFormatter';
import { useRouter } from 'util/router';
import { getTokens } from 'util/tokens';

import Flex from 'components/Flex';
import Toggle from 'components/Toggle';
import Logo from 'components/Logo';
import RefreshBtn from 'components/RefreshBtn';
import Spinner from 'components/Spinner';
import './Swap.scss';

const chainsForSelect = Object.entries(chainNameMap).filter(
	([key, val]) => ['optimism', 'polygon'].some(c => c === val)
).reduce(
	(acc, [key, val]) => {
		acc[key] = val;
		return acc;
	}, {}
);

function Swap(props) {
	const { wallet } = props;

	const router = useRouter();
	const {
		chainId: chainIdFromRouter,
		inputCurrency: inputCurrencyFromRouter,
		outputCurrency: outputCurrencyFromRouter
	} = router.query;

	const [chainId, setChainId] = useState(chainIdFromRouter || supportedChainId.POLYGON);
	const wrappedNative = WRAPPED_NATIVE_CURRENCY[chainId];

	const [inputCurrency, setInputCurrency] = useState(inputCurrencyFromRouter);
	const [outputCurrency, setOutputCurrency] = useState(outputCurrencyFromRouter || wrappedNative);
	const [inputTokenQuantity, setInputTokenQuantity] = useState('');
	const [outputTokenQuantity, setOutputTokenQuantity] = useState('');
	const [userTypedInputToken, setUserTypedInputToken] = useState(true);
	const inputTokenIsBase = userTypedInputToken || !outputTokenQuantity || outputTokenQuantity === '0';

	const paramsForSwap = {
		inputTokenAddress: inputCurrency?.address,
		outputTokenAddress: outputCurrency?.address,
		...inputTokenIsBase ? {
			inputTokenQuantity: inputCurrency && !!inputTokenQuantity && inputTokenQuantity !== '0' && ethers.utils.parseUnits(String(inputTokenQuantity), inputCurrency.decimals).toString(),
		} : {
			outputTokenQuantity: outputCurrency && !!outputTokenQuantity && outputTokenQuantity !== '0' && ethers.utils.parseUnits(String(outputTokenQuantity), outputCurrency.decimals).toString(),
		}
	};

	const {
		data: swapData = {},
		isLoading: swapDataIsLoading,
		isFetching: swapDataIsFetching,
		refetch: swapDataRefetch,
	} = useGetSwapQuery({ wallet, chainId, ...paramsForSwap });

	const {
		data: ethUsdPriceData = {},
		isFetching: ethUsdPriceDataIsFetching,
		refetch: ethUsdPriceDataRefetch,
	} = useGetUsdPriceQuery({ chainId, tokenAddress: wrappedNative.address, balance: '1' });

	const [inputUsdPrice, outputUsdPrice] = useMemo(() => {
		const { balanceValue: ethUsdPrice } = ethUsdPriceData;
		const { sellAmount, buyAmount, sellTokenToEthRate, buyTokenToEthRate } = swapData;

		if (
			swapDataIsLoading ||
			Object.keys(swapData).length < 1 ||
			!ethUsdPrice ||
			!inputCurrency?.decimals ||
			!outputCurrency?.decimals
		) {
			return [];
		}

		const sellAmountDecimalsInt = Number(ethers.utils.formatUnits(sellAmount, inputCurrency?.decimals));
		const buyAmountDecimalsInt = Number(ethers.utils.formatUnits(buyAmount, outputCurrency?.decimals));
		const sellTokenToEthRateInt = Number(sellTokenToEthRate);
		const buyTokenToEthRateInt = Number(buyTokenToEthRate);

		const inputUsdPrice = sellAmountDecimalsInt / sellTokenToEthRateInt * ethUsdPrice;
		const outputUsdPrice = buyAmountDecimalsInt / buyTokenToEthRateInt * ethUsdPrice;

		return [inputUsdPrice, outputUsdPrice];
	}, [swapDataIsLoading, swapData, ethUsdPriceData, inputCurrency?.decimals, outputCurrency?.decimals]);

	const slippageLoss = useMemo(() => {
		if (!inputUsdPrice || !outputUsdPrice) return null;

		return fixedAsNumber(
			(outputUsdPrice-inputUsdPrice)/inputUsdPrice*100,
			2
		);
	}, [inputUsdPrice, outputUsdPrice]);

	useEffect(() => {
		if (!swapDataIsFetching && Object.keys(swapData).length > 0 && inputCurrency?.decimals && outputCurrency?.decimals) {
			const { sellAmount, buyAmount } = swapData;
			const sellAmountDecimals = ethers.utils.formatUnits(sellAmount, inputCurrency.decimals);
			const buyAmountDecimals = ethers.utils.formatUnits(buyAmount, outputCurrency.decimals);

			if (userTypedInputToken) {
				setOutputTokenQuantity(prev => prev !== buyAmountDecimals ? buyAmountDecimals : prev);
			} else {
				setInputTokenQuantity(prev => prev !== sellAmountDecimals ? sellAmountDecimals : prev);
			}
		}
	}, [swapDataIsFetching, swapData, inputCurrency?.decimals, outputCurrency?.decimals, userTypedInputToken]);

	useEffect(() => {
		const usdPriceDataInterval = setInterval(ethUsdPriceDataRefetch, 10 * 1000);
		return () => clearInterval(usdPriceDataInterval);
	}, []);

	function refetch() {
		swapDataRefetch();
		ethUsdPriceDataRefetch();
	}

	return (
		<Container className="Swap py-4 py-lg-5" fluid="md">
			<Card className="rounded">
				<Card.Header className="p-3 bg-white">
					<Flex justify="between" align="center">
						<Toggle
							className="shadow-sm f-rem-0.8"
							ops={Object.values(chainsForSelect)}
							active={chainNameMap[chainId]}
							setActive={(chainName) => {
								setChainId(supportedChainId[chainName.toUpperCase()]);
							}}
						/>
						<Flex align="center" className="f-rem-0.9">
							<RefreshBtn refreshing={swapDataIsFetching} action={refetch} style={{fontSize: '0.9rem'}}/>
							<span className="ml-2 f-rem-0.9 shadow-sm bg-white badge border">
								<FontAwesomeIcon icon={faSlidersSimple} />
							</span>
						</Flex>
					</Flex>
				</Card.Header>
				<Card.Header className="p-3 bg-white">
					<Flex justify="between" align="center">
						<span className="mr-2">Swap</span>
					</Flex>
				</Card.Header>
				<Card.Body className="p-0">
					<Flex direction="column" className="p-3">
						<SwapInputContainer
							isFirst={true}
							wallet={wallet}
							chainId={chainId}
							tokenQuantity={inputTokenQuantity}
							setTokenQuantity={setInputTokenQuantity}
							userTypedInputToken={userTypedInputToken}
							setUserTypedInputToken={setUserTypedInputToken}
							selectedToken={inputCurrency}
							setSelectedToken={setInputCurrency}
							otherSelectedToken={outputCurrency}
							setOtherSelectedToken={setOutputCurrency}
							usdPrice={inputUsdPrice}
							usdPriceIsFetching={ethUsdPriceDataIsFetching}
						/>
						<SwitchDirection
							inputCurrency={inputCurrency}
							setInputCurrency={setInputCurrency}
							outputCurrency={outputCurrency}
							setOutputCurrency={setOutputCurrency}
							inputTokenQuantity={inputTokenQuantity}
							setInputTokenQuantity={setInputTokenQuantity}
							outputTokenQuantity={outputTokenQuantity}
							setOutputTokenQuantity={setOutputTokenQuantity}
							userTypedInputToken={userTypedInputToken}
							swapDataIsFetching={swapDataIsFetching}
						/>
						<SwapInputContainer
							wallet={wallet}
							chainId={chainId}
							tokenQuantity={outputTokenQuantity}
							setTokenQuantity={setOutputTokenQuantity}
							userTypedInputToken={userTypedInputToken}
							setUserTypedInputToken={setUserTypedInputToken}
							selectedToken={outputCurrency}
							setSelectedToken={setOutputCurrency}
							otherSelectedToken={inputCurrency}
							setOtherSelectedToken={setInputCurrency}
							unitPrice={swapData?.price}
							usdPrice={outputUsdPrice}
							usdPriceIsFetching={ethUsdPriceDataIsFetching}
							slippageLoss={slippageLoss}
						/>
					</Flex>
				</Card.Body>
				<Card.Footer className="bg-white">
					<LiqProviderAndFee
						chainId={chainId}
						ethUsdPriceData={ethUsdPriceData}
						swapDataIsFetching={swapDataIsFetching}
						orders={swapData?.orders}
						inputToken={inputCurrency}
						inputTokenQuantity={inputTokenQuantity}
						gas={swapData?.gas}
						gasPrice={swapData?.gasPrice}
						wrappedNative={wrappedNative}
					/>
				</Card.Footer>
			</Card>
		</Container>
	);
};

export default Swap;

const TokenDropdown = ({ chainId, selectedToken, setSelectedToken, otherSelectedToken, setOtherSelectedToken }) => {
	return (
		<Dropdown className="TokenDropdown mr-3 align-self-stretch">
			<Dropdown.Toggle variant="none" className="pr-0">
				{selectedToken && selectedToken.symbol ? (
					<>
						<Logo token={{...selectedToken, chainName: selectedToken.chainId}} className="mr-2" style={{marginTop: '-4px'}}/>
						<span className="mr-1">{selectedToken.symbol}</span>
					</>
				) : (
					<span className="mr-1">Select</span>
				)}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{TOKENS[chainId].map(t => (
					<Dropdown.Item
						key={t.symbol}
						onClick={() => {
							if (otherSelectedToken && otherSelectedToken.address.toLowerCase() === t.address.toLowerCase()) {
								setOtherSelectedToken(null)
							}

							setSelectedToken(t);
						}}
						className="px-2.5"
					>
						<Flex align="center">
							<Logo token={{...t, chainName: t.chainId}} className="mr-2"/>
							<span>{t.symbol}</span>
						</Flex>
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
};

const TokenQuantityInput = ({tokenQuantity, setTokenQuantity, isFirst, userTypedInputToken, setUserTypedInputToken, inputIsGreaterThanBalance}) => {
	const tokenQuantityRef = useRef();
	const debounceSetTokenQuantity = debounce(setTokenQuantity, 500);

	function handleChange() {
		const value = tokenQuantityRef.current.value;

		if (!value) {
			setTokenQuantity('');
		} else if (!isNaN(Number(value))) {
			debounceSetTokenQuantity(String(value));
		}

		setUserTypedInputToken(isFirst);
	}

	if (
			!!tokenQuantityRef.current &&
			tokenQuantityRef.current.value !== tokenQuantity
	) {
		tokenQuantityRef.current.value = tokenQuantity || '';
	}

	return (
		<Form.Control
			ref={tokenQuantityRef}
			className={classnames('TokenQuantityInput', { 'red-border': inputIsGreaterThanBalance })}
			type="text"
			name="tokenQuantity"
			placeholder="0"
			onChange={handleChange}
		/>
	);
};

const SwapInputContainer = ({isFirst, wallet, chainId, tokenQuantity, setTokenQuantity, userTypedInputToken, setUserTypedInputToken, selectedToken, setSelectedToken, otherSelectedToken, setOtherSelectedToken, unitPrice, usdPrice, usdPriceIsFetching, slippageLoss}) => {
	const [Token] = getTokens(selectedToken);
	const wrappedNative = WRAPPED_NATIVE_CURRENCY[chainId];
	const hasWrappedNative = Token && wrappedNative.equals(Token);

	const {
		data: balances = {},
		isFetching: balancesIsFetching,
	} = useGetTokenBalancesQuery({
		chainId,
		tokenAddresses: [Token?.address],
		wallet,
		getNative: hasWrappedNative,
		getUsd: false
	});

	const currency = balances?.[Token?.address]?.balance?.currency;
	const balance = balances?.[Token?.address]?.balance ? CurrencyAmount.fromFractionalAmount(
		balances?.[Token?.address]?.balance?.currency,
		JSBI.from(balances?.[Token?.address]?.balance?.numerator).toString(),
		JSBI.from(balances?.[Token?.address]?.balance?.denominator).toString()
	) : undefined;

	const balanceIsZero = !balance || balance?.toFixed?.(currency?.decimals) === `0.${[...Array(currency?.decimals || 0).keys()].map(o => '0').join('')}`;
	const inputIsGreaterThanBalance = isFirst && balance && tokenQuantity && Number(tokenQuantity) > Number(balance.toExact());

	return (
		<Flex direction="column" className={classnames('SwapInputContainer', { isFirst })}>
			<Flex justify="between" className="header mb-3">
				{isFirst ? (
					<>
						<span className="you-pay">You Pay</span>
						{!!selectedToken && (
							<span
								className="max pointer"
								onClick={() => !!balance && setTokenQuantity(balanceIsZero ? '0' : balance.toExact())}
							>
								{balancesIsFetching ? <Spinner/> : `Max ${balanceIsZero ? '0' : balance?.toFixed(currency?.decimals) || '0'}`} {Token.symbol}
							</span>
						)}
					</>
				) : (
					<>
						<span className="you-pay">You Recieve</span>
						{unitPrice && selectedToken?.symbol && otherSelectedToken?.symbol && (
							<span className="max">1 {otherSelectedToken.symbol} = {commaFormat(unitPrice, 5)} {selectedToken.symbol}</span>
						)}
					</>
				)}
			</Flex>
			<Flex justify="between">
				<TokenDropdown
					chainId={chainId}
					selectedToken={selectedToken}
					setSelectedToken={setSelectedToken}
					otherSelectedToken={otherSelectedToken}
					setOtherSelectedToken={setOtherSelectedToken}
				/>
				<TokenQuantityInput
					tokenQuantity={tokenQuantity}
					setTokenQuantity={setTokenQuantity}
					isFirst={isFirst}
					userTypedInputToken={userTypedInputToken}
					setUserTypedInputToken={setUserTypedInputToken}
					inputIsGreaterThanBalance={inputIsGreaterThanBalance}
				/>
			</Flex>
			<div className="value-in-dai">
				<span className="value">{tokenQuantity && tokenQuantity !== '0' && selectedToken && usdPriceIsFetching && (<Spinner className="f-rem-0.7 mt-0.5 mr-1"/>)}{currencyFormat(usdPrice, '-')}</span>
				{!isFirst && slippageLoss && (
					<span
						className={classnames('slippage-from-trade ml-1', {
							'text-success': slippageLoss > 0,
							'text-danger': slippageLoss < 0
						})}
					>
						({slippageLoss > 0 && '+'}{slippageLoss}%)
					</span>
				)}
			</div>
		</Flex>
	);
};

const SwitchDirection = ({inputCurrency, setInputCurrency, outputCurrency, setOutputCurrency, inputTokenQuantity, setInputTokenQuantity, outputTokenQuantity, setOutputTokenQuantity, userTypedInputToken, swapDataIsFetching}) => {
	const [rotate, setRotate] = useState(false);

	function handleClick() {
		setRotate(prev => !prev);

		setInputCurrency(outputCurrency);
		setOutputCurrency(inputCurrency);

		if (userTypedInputToken) {
			setInputTokenQuantity(outputTokenQuantity);
			setOutputTokenQuantity('');
		} else {
			setInputTokenQuantity('');
			setOutputTokenQuantity(inputTokenQuantity);
		}
	}

	return (
		<Flex justify="center" className="SwitchDirection">
			<Flex
				justify="center"
				align="center"
				className="SwitchDirectionButton"
				onClick={handleClick}
			>
				<FontAwesomeIcon
					icon={faArrowDown}
					className={classnames({ rotate })}
				/>
			</Flex>
		</Flex>
	);
};

const LiqProviderAndFee = ({chainId, ethUsdPriceData, swapDataIsFetching, orders = [], inputToken, inputTokenQuantity, gas, gasPrice, wrappedNative}) => {
	const [showSources, setShowSources] = useState(false);

	const formatSourceName = (name = '') => name.replace(/_/g, ' ');
	const hasMultiOrders = orders.length > 1;
	const sourcesText = hasMultiOrders ? `${formatSourceName(orders[0]?.source)} +${orders.length-1} source${orders.length-1 > 1 ? 's' : ''}`.trim()
		: orders.length === 1 ? formatSourceName(orders[0]?.source)
		: '-';

	function getPercOfSwap(takerAmount) {
		const total = ethers.utils.parseUnits(String(inputTokenQuantity), inputToken.decimals);
		if (total.isZero()) return '-';

		const takerAmountAsBigInt = ethers.BigNumber.from(takerAmount);
		const percentage = new Percent(takerAmountAsBigInt, total);
		return percentage.toFixed(2);
	}

	const gasAsUsd = useMemo(() => {
		const { balanceValue: ethUsdPrice } = ethUsdPriceData;
		if (!ethUsdPrice || !gas || !gasPrice) return '-';

		const gasPriceParsed = ethers.utils.parseUnits(gasPrice, 'wei');
		const gasTotal = gasPriceParsed.mul(ethers.BigNumber.from(gas));
		const gasAsUsd = Number(ethers.utils.formatUnits(gasTotal, wrappedNative.decimals).toString()) * ethUsdPrice;

		return currencyFormat(gasAsUsd, '-');
	}, [ethUsdPriceData, gas, gasPrice, wrappedNative]);

	return (
		<Flex direction="column" className="LiqProviderAndFee">
			{/*Sources*/}
			<Flex justify="between" align="center" className="w-100">
				<span className="text-gray-700">Liquidity Provider</span>
				<Flex
					align="center"
					className="sources pointer"
					onClick={() => setShowSources(prev => !prev)}
				>
					{swapDataIsFetching ? <Spinner className="mr-0"/> : <span>{sourcesText}</span>}
					{!swapDataIsFetching && hasMultiOrders && (
						<FontAwesomeIcon
							icon={faChevronDown}
							className={classnames('showSourcesIcon', { rotate: showSources })}
						/>
					)}
				</Flex>
			</Flex>
			{!swapDataIsFetching && hasMultiOrders && showSources && (
				<Flex direction="column" className="sources-details-container">
					{orders.map(({ takerAmount, source, fillData }, idx) => (
						<Flex key={idx} align="center" className="py-0.5">
							<span className="text-gray-700 fw-5">
								- {getPercOfSwap(takerAmount)}% <span className="text-gray-600">{formatSourceName(source)}</span>
							</span>
							{(fillData?.tokenAddressPath || fillData?.assets)?.map((address, idx) => (
								<Logo
									key={address}
									token={{address, chainName: chainId}}
									style={{
										width: '14px',
										height: '14px',
										marginLeft: idx === 0 ? '0.325rem' : '-4px',
										marginTop: '-1px',
									}}
								/>
							))}
						</Flex>
					))}
				</Flex>
			)}
			{/*Fee*/}
			<Flex
				justify="between"
				align="center"
				className={classnames('w-100', { 'mt-1': !showSources || swapDataIsFetching })}
			>
				<span className="text-gray-700">Tx Fee</span>
				{swapDataIsFetching ? <Spinner className="mr-0"/> : <span className="text-gray-700">{gasAsUsd}</span>}
			</Flex>
		</Flex>
	);
};
