import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort as faSortSolid } from '@fortawesome/pro-solid-svg-icons';
import { faSort as faSortDuo } from '@fortawesome/pro-duotone-svg-icons';

const SortIcon = ({ direction }) => !direction ? (
	<FontAwesomeIcon icon={faSortSolid} style={{opacity: '0.4'}} />
) : (
	<FontAwesomeIcon icon={faSortDuo} rotation={direction === 'asc' ? 0 : 180}/>
);

export default SortIcon;
