import { Token } from "@uniswap/sdk-core";
import supportedChainId from './chains';

export const WRAPPED_NATIVE_CURRENCY = {
	[supportedChainId.MAINNET]: new Token(
			supportedChainId.MAINNET,
			"0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
			18,
			"WETH",
			"Wrapped Ether"
		),
	[supportedChainId.OPTIMISM]: new Token(
			supportedChainId.OPTIMISM,
			"0x4200000000000000000000000000000000000006",
			18,
			"WETH",
			"Wrapped Ether"
		),
	[supportedChainId.BINANCE]: new Token(
			supportedChainId.BINANCE,
			"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			18,
			"WBNB",
			"Wrapped BNB"
		),
	[supportedChainId.POLYGON]: new Token(
			supportedChainId.POLYGON,
			"0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
			18,
			"WMATIC",
			"Wrapped Matic"
		),
	[supportedChainId.ARBITRUM]: new Token(
			supportedChainId.ARBITRUM,
			"0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
			18,
			"WETH",
			"Wrapped Ether"
		),
	[supportedChainId.AVALANCHE]: new Token(
			supportedChainId.AVALANCHE,
			"0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
			18,
			"WAVAX",
			"Wrapped Avax"
		),
};

export const DAI = {
	[supportedChainId.MAINNET]: new Token(
			supportedChainId.MAINNET,
			"0x6b175474e89094c44da98b954eedeac495271d0f",
			18,
			"DAI",
			"Dai Stablecoin"
		),
	[supportedChainId.OPTIMISM]: new Token(
			supportedChainId.OPTIMISM,
			"0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
			6,
			"USDT",
			"USD//T"
		),
	[supportedChainId.BINANCE]: new Token(
			supportedChainId.BINANCE,
			"0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
			18,
			"DAI",
			"Binance-Peg Dai Token"
		),
	[supportedChainId.POLYGON]: new Token(
			supportedChainId.POLYGON,
			"0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
			6,
			"USDC",
			"USD//C"
		),
	[supportedChainId.ARBITRUM]: new Token(
			supportedChainId.ARBITRUM,
			"0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
			6,
			"USDT",
			"USD//T"
		),
	[supportedChainId.AVALANCHE]: new Token(
			supportedChainId.AVALANCHE,
			"0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
			6,
			"USDT.e",
			"Tether USD"
		),
};

export const STABLES = {
	[supportedChainId.MAINNET]: [
		new Token(
			  supportedChainId.MAINNET,
			  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
			  6,
			  'USDC',
			  'USD//C'
			),
		new Token(
			  supportedChainId.MAINNET,
			  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
			  6,
			  'USDT',
			  'Tether USD'
			),
		new Token(
			  supportedChainId.MAINNET,
			  '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
			  18,
			  'USDP',
			  'Pax Dollar'
			),
		new Token(
				supportedChainId.MAINNET,
				"0x6b175474e89094c44da98b954eedeac495271d0f",
				18,
				"DAI",
				"Dai Stablecoin"
			),
		new Token(
				supportedChainId.MAINNET,
				"0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3",
				18,
				"MIM",
				"Magic Internet Money"
			),
		new Token(
				supportedChainId.MAINNET,
				"0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
				18,
				"LUSD",
				"LUSD Stablecoin"
			),
		new Token(
				supportedChainId.MAINNET,
				"0x853d955acef822db058eb8505911ed77f175b99e",
				18,
				"FRAX",
				"Frax"
			),
		new Token(
				supportedChainId.MAINNET,
				"0x956F47F50A910163D8BF957Cf5846D573E7f87CA",
				18,
				"FEI",
				"Fei USD"
			),
		new Token(
				supportedChainId.MAINNET,
				"0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B",
				18,
				"BOB",
				"BOB"
			),
		new Token(
				supportedChainId.MAINNET,
				"0x0000000000085d4780B73119b644AE5ecd22b376",
				18,
				"TUSD",
				"TrueUSD"
			),
		new Token(
				supportedChainId.MAINNET,
				"0xf939e0a03fb07f59a73314e73794be0e57ac1b4e",
				18,
				"crvUSD",
				"crvUSD"
			),
	],
	[supportedChainId.OPTIMISM]: [
		new Token(
				supportedChainId.OPTIMISM,
				"0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
				6,
				"USDC",
				"USD//C"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
				6,
				"USDT",
				"USD//T"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
				18,
				"DAI",
				"Dai Stablecoin"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0x2E3D870790dC77A83DD1d18184Acc7439A53f475",
				18,
				"FRAX",
				"Frax"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0xC22885e06cd8507c5c74a948C59af853AEd1Ea5C",
				18,
				"USDD",
				"Decentralized USD"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9",
				18,
				"sUSD",
				"Synth sUSD"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B",
				18,
				"BOB",
				"BOB"
			),
	],
	[supportedChainId.BINANCE]: [
		new Token(
				supportedChainId.BINANCE,
				"0xe9e7cea3dedca5984780bafc599bd69add087d56",
				18,
				"BUSD",
				"Binance USD"
			),
		new Token(
				supportedChainId.BINANCE,
				"0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
				18,
				"USDC",
				"USD//C"
			),
		new Token(
				supportedChainId.BINANCE,
				"0x55d398326f99059ff775485246999027b3197955",
				18,
				"USDT",
				"USD//T"
			),
		new Token(
			  supportedChainId.BINANCE,
			  '0xb3c11196A4f3b1da7c23d9FB0A3dDE9c6340934F',
			  18,
			  'USDP',
			  'Pax Dollar'
			),
		new Token(
				supportedChainId.BINANCE,
				"0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
				18,
				"DAI",
				"Binance-Peg Dai Token"
			),
		new Token(
				supportedChainId.BINANCE,
				"0xfe19f0b51438fd612f6fd59c1dbb3ea319f433ba",
				18,
				"MIM",
				"Magic Internet Money"
			),
		new Token(
				supportedChainId.BINANCE,
				"0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40",
				18,
				"FRAX",
				"Frax"
			),
		new Token(
				supportedChainId.BINANCE,
				"0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B",
				18,
				"BOB",
				"BOB"
			),
		new Token(
				supportedChainId.BINANCE,
				"0x14016e85a25aeb13065688cafb43044c2ef86784",
				18,
				"TUSD",
				"TrueUSD"
			),
	],
	[supportedChainId.POLYGON]: [
		new Token(
				supportedChainId.POLYGON,
				"0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
				6,
				"USDC",
				"USD//C"
			),
		new Token(
				supportedChainId.POLYGON,
				"0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
				6,
				"USDT",
				"Tether USD"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
				18,
				"DAI",
				"Dai Stablecoin"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89",
				18,
				"FRAX",
				"Frax"
			),
		new Token(
				supportedChainId.POLYGON,
				"0xa3fa99a148fa48d14ed51d610c367c61876997f1",
				18,
				"miMATIC",
				"miMATIC"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x750e4C4984a9e0f12978eA6742Bc1c5D248f40ed",
				6,
				"axlUSDC",
				"Axelar Wrapped USDC"
			),
		new Token(
				supportedChainId.POLYGON,
				"0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
				18,
				"BOB",
				"BOB"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x2e1AD108fF1D8C782fcBbB89AAd783aC49586756",
				18,
				"TUSD",
				"TrueUSD"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
				6,
				"USDC Plain",
				"USD Coin"
			),
	],
	[supportedChainId.ARBITRUM]: [
		new Token(
				supportedChainId.ARBITRUM,
				"0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
				6,
				"USDT",
				"USD//T"
			),
		new Token(
				supportedChainId.ARBITRUM,
				"0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
				6,
				"USDC",
				"USD//C"
			),
		new Token(
				supportedChainId.ARBITRUM,
				"0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
				18,
				"DAI",
				"Dai Stablecoin"
			),
		new Token(
				supportedChainId.ARBITRUM,
				"0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65",
				6,
				"USD+",
				"USD+"
			),
		new Token(
				supportedChainId.ARBITRUM,
				"0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07",
				18,
				"TUSD",
				"TrueUSD"
			),
		new Token(
				supportedChainId.ARBITRUM,
				"0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
				18,
				"FRAX",
				"FRAX"
			),
		new Token(
				supportedChainId.ARBITRUM,
				"0x64343594Ab9b56e99087BfA6F2335Db24c2d1F17",
				18,
				"VST",
				"Vesta Stable"
			),
	],
	[supportedChainId.AVALANCHE]: [
		new Token(
				supportedChainId.AVALANCHE,
				"0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
				6,
				"USDT.e",
				"Tether USD"
			),
		new Token(
				supportedChainId.AVALANCHE,
				"0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
				6,
				"USDT",
				"TetherToken"
			),
		new Token(
				supportedChainId.AVALANCHE,
				"0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
				6,
				"USDC.e",
				"USD Coin"
			),
		new Token(
				supportedChainId.AVALANCHE,
				"0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
				6,
				"USDC",
				"USD Coin"
			),
		new Token(
				supportedChainId.AVALANCHE,
				"0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
				18,
				"DAI",
				"DAI Stablecoin"
			),
	],
};

export const COMMON_TOKENS = {
	[supportedChainId.MAINNET]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.MAINNET],
		...STABLES[supportedChainId.MAINNET],
	],
	[supportedChainId.OPTIMISM]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.OPTIMISM],
		new Token(
				supportedChainId.OPTIMISM,
				"0x4200000000000000000000000000000000000042",
				18,
				"OP",
				"Optimism"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0x68f180fcce6836688e9084f035309e29bf0a2095",
				8,
				"WBTC",
				"Wrapped BTC"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
				6,
				"USDC",
				"USD//C"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
				6,
				"USDT",
				"USD//T"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
				18,
				"DAI",
				"Dai Stablecoin"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0x2E3D870790dC77A83DD1d18184Acc7439A53f475",
				18,
				"FRAX",
				"Frax"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0xC22885e06cd8507c5c74a948C59af853AEd1Ea5C",
				18,
				"USDD",
				"Decentralized USD"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9",
				18,
				"sUSD",
				"Synth sUSD"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B",
				18,
				"BOB",
				"BOB"
			),
	],
	[supportedChainId.BINANCE]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.BINANCE],
		...STABLES[supportedChainId.BINANCE],
	],
	[supportedChainId.POLYGON]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.POLYGON],
		new Token(
				supportedChainId.POLYGON,
				"0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
				18,
				"WETH",
				"Wrapped Ether"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
				8,
				"WBTC",
				"(PoS Wrapped BTC)"
			),
		...STABLES[supportedChainId.POLYGON],
	],
	[supportedChainId.ARBITRUM]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.ARBITRUM],
		new Token(
				supportedChainId.ARBITRUM,
				"0x912ce59144191c1204e64559fe8253a0e49e6548",
				18,
				"ARB",
				"Arbitrum"
			),
		new Token(
				supportedChainId.ARBITRUM,
				"0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
				8,
				"WBTC",
				"Wrapped BTC"
			),
		...STABLES[supportedChainId.ARBITRUM],
	],
	[supportedChainId.AVALANCHE]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.AVALANCHE],
		...STABLES[supportedChainId.AVALANCHE],
	],
};

export const ARBITRAGE_TOKENS = {
	[supportedChainId.MAINNET]: [],
	[supportedChainId.OPTIMISM]: [],
	[supportedChainId.BINANCE]: [],
	[supportedChainId.POLYGON]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.POLYGON],
		new Token(
				supportedChainId.POLYGON,
				"0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
				18,
				"WETH",
				"Wrapped Ether"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
				8,
				"WBTC",
				"(PoS Wrapped BTC)"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
				6,
				"USDC",
				"USD//C"
			),
		new Token(
				supportedChainId.POLYGON,
				"0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
				6,
				"USDT",
				"Tether USD"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
				18,
				"DAI",
				"Dai Stablecoin"
			),
	],
	[supportedChainId.ARBITRUM]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.ARBITRUM],
	],
	[supportedChainId.AVALANCHE]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.AVALANCHE],
	],
};

export const TOKENS = {
	[supportedChainId.MAINNET]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.MAINNET],
	],
	[supportedChainId.OPTIMISM]: [
		...COMMON_TOKENS[supportedChainId.OPTIMISM],
		new Token(
				supportedChainId.OPTIMISM,
				"0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6",
				18,
				"LINK",
				"ChainLink"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0xE405de8F52ba7559f9df3C368500B6E6ae6Cee49",
				18,
				"sETH",
				"Synth sETH"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0x298B9B95708152ff6968aafd889c6586e9169f1D",
				18,
				"sBTC",
				"Synth sBTC"
			),
		new Token(
				supportedChainId.OPTIMISM,
				"0xc5Db22719A06418028A40A9B5E9A7c02959D0d08",
				18,
				"sLINK",
				"Synth sLINK"
			),
	],
	[supportedChainId.BINANCE]: [
		...COMMON_TOKENS[supportedChainId.BINANCE],
	],
	[supportedChainId.POLYGON]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.POLYGON],
		new Token(
				supportedChainId.POLYGON,
				"0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
				18,
				"WETH",
				"Wrapped Ether"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
				6,
				"USDC",
				"USD Coin (PoS)"
			),
		new Token(
				supportedChainId.POLYGON,
				"0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
				18,
				"USDT",
				"(PoS) Tether USD"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
				8,
				"WBTC",
				"(PoS) Wrapped BTC"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x172370d5Cd63279eFa6d502DAB29171933a610AF",
				18,
				"CRV",
				"CRV (PoS)"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
				18,
				"LINK",
				"ChainLink Token"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a",
				18,
				"SUSHI",
				"Sushi Token"
			),
		new Token(
				supportedChainId.POLYGON,
				"0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4",
				18,
				"MANA",
				"Decentraland"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x430EF9263E76DAE63c84292C3409D61c598E9682",
				18,
				"PYR",
				"PYR Token"
			),
		new Token(
				supportedChainId.POLYGON,
				"0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683",
				18,
				"SAND",
				"Sandbox Token"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b",
				18,
				"AVAX",
				"Avalanche Token"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x5fe2B58c013d7601147DcdD68C143A77499f5531",
				18,
				"GRT",
				"Graph Token"
			),
		new Token(
				supportedChainId.POLYGON,
				"0xB85517b87BF64942adf3A0B9E4c71E4Bc5Caa4e5",
				18,
				"FTM",
				"Fantom Token"
			),
		new Token(
				supportedChainId.POLYGON,
				"0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4",
				18,
				"SOL",
				"Solana"
			),
		new Token(
				supportedChainId.POLYGON,
				"0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
				18,
				"AAVE",
				"Aave (PoS)"
			),
	],
	[supportedChainId.ARBITRUM]: [
		...COMMON_TOKENS[supportedChainId.ARBITRUM],
	],
	[supportedChainId.AVALANCHE]: [
		WRAPPED_NATIVE_CURRENCY[supportedChainId.AVALANCHE],
	],
};
