import { ethers } from 'ethers';
import React, { useState, useReducer, useEffect, useMemo, useCallback, useRef } from 'react';
// import { storeDispatch } from 'redux/store';
import { Pool, Position, TickMath, TICK_SPACINGS, tickToPrice, nearestUsableTick } from '@uniswap/v3-sdk';
import { CurrencyAmount } from '@uniswap/sdk-core';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersSimple, faCircleCheck, faPlus, faMinus, faLock, faLockOpen } from '@fortawesome/pro-solid-svg-icons';
import Flex from 'components/Flex';
import FullLoader from 'components/FullLoader';
import Logo from 'components/Logo';
import RefreshBtn from 'components/RefreshBtn';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';
import TokensDropdown from 'components/TokensDropdown';
import { PoolInfo } from 'pages/analyze/Analyze';
import { RenderedChart as TickChartComponent, filterTicks } from 'pages/uniswap/Position/TickChart/TickChart';
import InRangeIcon from 'pages/uniswap/components/InRangeIcon';

import { TokenAbi } from 'constants/abis';
import { SWAP_ROUTER_ADDRESS } from 'constants/addresses';
import { MAX_UINT256 } from 'constants/integers';
import { FEE_RATES } from 'constants/ticks';
import genTxHashUrl from 'constants/tx';
import { walletNamesMap } from 'constants/wallets';

import genRouteToRatioCalldata from 'util/position/genRouteToRatioCalldata';
import { sendTransactionViaExtension } from 'util/browserExtensionWallet';
import getBrowserExtensionProvider from 'util/getBrowserExtensionProvider';
import { commaFormat, currencyFormat, fixedAsNumber } from 'util/numberFormatter';
import { getTokens } from 'util/tokens';
import { truncateWallet } from 'util/wallets';

import {
	useGetTokenBalancesQuery,
	useGetPoolStateQuery,
	useGetTickDataQuery,
	useGetPositionsQuery,
	// useGetRouteToRatioCalldataQuery,
	useGetTokenAllowancesQuery,
	// positionsApiSlice,
} from 'api/client';
import useGetSupportedTokens from 'hooks/useGetSupportedTokens';
import useGetUniV3PairAddress from 'hooks/useGetUniV3PairAddress';
import useIsMobile from 'hooks/useIsMobile';
import useOnBlockUpdated from 'hooks/useOnBlockUpdated';
import useFormatPoolData from 'pages/uniswap/Position/TickChart/hooks/useFormatPoolData';
import './Liquidity.scss';

function selectedTokensReducer(state, {which, token}) {
	let newState;

	switch(which) {
		case 'reset':
			newState = {
				Token0: undefined,
				Token1: undefined,
			};
			break;
		case 'reverse':
			newState = {
				...state.Token1 ? {
					Token0: state.Token1,
				} : {},
				...state.Token0 ? {
					Token1: state.Token0,
				} : {},
			};
			break;
		case 'Token0':
			newState = {
				Token0: token,
				...state?.Token1?.equals?.(token) ? {
					Token1: undefined
				} : {
					Token1: state.Token1,
				},
			};
			break;
		case 'Token1':
			newState = {
				Token1: token,
				...state?.Token0?.equals?.(token) ? {
					Token0: undefined
				} : {
					Token0: state.Token0,
				},
			};
			break;
		default:
			newState = {
				...state,
				[which]: token,
			};
			break;
	}

	return newState;
};

function Liquidity(props) {
	const { wallet, chainId } = props;
	const isMobile = useIsMobile();
	const [feeRate, setFeeRate] = useState(null);
	const [token0Amount, setToken0Amount] = useState(undefined);
	const [token1Amount, setToken1Amount] = useState(undefined);
	const [selectedTickRange, setSelectedTickRange] = useState({});
	const [liqInTickRange, setLiqInTickRange] = useState({});
	const [selectedVolForCalc, setSelectedVolForCalc] = useState('h24');
	const [tokenReverse, setTokenReverse] = useState(false);
	const [selectedPid, setSelectedPid] = useState(null);

	const {
		data: supportedTokens = [],
	} = useGetSupportedTokens({
		chainId
	}, {
		skip: !chainId || !wallet,
	});

	const [
		{ Token0, Token1 },
		dispatchSelectedTokens,
	] = useReducer(selectedTokensReducer, {});

	const pairAddress = useGetUniV3PairAddress(Token0, Token1, feeRate);

	const {
		data: poolState = {},
		isLoading: poolStateIsLoading,
		isFetching: poolStateIsFetching,
		refetch: poolStateRefetch,
	} = useGetPoolStateQuery({
		chainId,
		pairAddress,
		feeRate,
	}, {
		skip: !chainId || !pairAddress || !feeRate,
	});

	// Set reverse state based on user selection
	useEffect(() => {
		if (!poolStateIsFetching && !!poolState?.fee && Token1) {
			const [Token0] = getTokens(poolState?.Token0);
			setTokenReverse(Token0.equals(Token1));
		}
	}, [poolStateIsFetching, poolState, Token1]);

	const {
		data: positions = [],
		isFetching: positionsIsFetching,
		refetch: positionsRefetch,
	} = useGetPositionsQuery({
		chainId,
		wallet
	}, {
		skip: !chainId || !wallet,
	});

	const positionsInSelectedPool = useMemo(() => {
		if (pairAddress) {
			return positions.filter(
				p => p.pool.address === pairAddress
			);
		}
	}, [pairAddress, positions]);

	const {
		data: token0Balance = {},
		refetch: token0BalanceRefetch,
	} = useGetTokenBalancesQuery({
		chainId,
		tokenAddresses: [Token0?.address].filter(o => o),
		wallet: wallet,
		getUsd: true,
	}, {
		skip: !wallet || !Token0?.address,
	});

	const {
		data: token1Balance = {},
		refetch: token1BalanceRefetch,
	} = useGetTokenBalancesQuery({
		chainId,
		tokenAddresses: [Token1?.address].filter(o => o),
		wallet: wallet,
		getUsd: true,
	}, {
		skip: !wallet || !Token1?.address,
	});

	// Refetch data on block update
	useOnBlockUpdated(blockNumber => {
		poolStateRefetch();
		positionsRefetch();
		token0BalanceRefetch();
		token1BalanceRefetch();
	});

	// Reset everyting
	const handleConfigReset = useCallback(() => {
		dispatchSelectedTokens({which: 'reset'});
		setFeeRate(null);
		setToken0Amount(undefined);
		setToken1Amount(undefined);
		setSelectedTickRange({});
		setTokenReverse(false);
		setSelectedPid(null);
	}, []);

	// Reset selected tokens on chain or wallet switch
	useEffect(() => handleConfigReset(), [chainId, wallet, handleConfigReset]);

	// Reset selectedPid on token or feeRate change
	useEffect(() => setSelectedPid(null), [Token0, Token1, feeRate]);

	const handlePriceRangeReset = useCallback(() => {
		setSelectedTickRange({});
		setLiqInTickRange({});
		setSelectedVolForCalc('h24');
	}, []);

	useEffect(() => handlePriceRangeReset(), [chainId, Token0, Token1, feeRate, pairAddress, handlePriceRangeReset]);

	const handleOnPositionSelectionClick = useCallback((position) => {
		handleConfigReset();
		handlePriceRangeReset();

		setTimeout(() => {
			const [_Token0, _Token1] = getTokens(position.pool.Token0, position.pool.Token1);
			dispatchSelectedTokens({which: 'Token0', token: _Token0});
			dispatchSelectedTokens({which: 'Token1', token: _Token1});
			setFeeRate(position.pool.fee);

			setTimeout(() => {
				setSelectedPid(position.pid);
				setSelectedTickRange(prev => ({
					...prev,
					minTickIdx: position.position.tickLower,
					maxTickIdx: position.position.tickUpper,
				}));
			}, 0);
		}, 0);
	}, [handleConfigReset, handlePriceRangeReset]);

	const walletName = wallet && walletNamesMap[wallet.toLowerCase()];

	return (
		<Container className="Liquidity py-4 py-lg-5" fluid="md">
			<Card className="rounded">
				<Card.Header className="p-3">
					<Flex justify="between" align="center">
						<Flex align="center">
							<span>Wallet: {wallet ? truncateWallet(wallet, undefined, false) : 'Not connected'}</span>
							{walletName && (
								<small className="fw-5 text-muted pl-1.5">{walletName}</small>
							)}
						</Flex>
						<span className="fw-5">Add Liquidity</span>
						<Flex align="center" className="f-rem-0.9">
							<RefreshBtn
								refreshing={false}
								action={() => {}}
								style={{fontSize: '0.9rem'}}
							/>
							<span className="ml-2 f-rem-0.9 shadow-sm bg-white badge border">
								<FontAwesomeIcon icon={faSlidersSimple} />
							</span>
						</Flex>
					</Flex>
				</Card.Header>
				<Card.Body className="p-3">
					<Row>
						{/*Tokens, Fee, and Amounts*/}
						<Col xs={12} lg={5} xl={4}>
							{isMobile && (
								<>
									<MyPositionsForTickSelection
										title="(click to use tokens/ticks)"
										positions={positions}
										currentTick={poolState?.tickCurrent}
										selectedPid={selectedPid}
										handleOnClick={handleOnPositionSelectionClick}
										borderBottom={true}
									/>
									<div className="py-1"></div>
								</>
							)}
							<Flex justify="between" align="center">
								<span className="fw-5">Select Pair</span>
								<span
									className="text-info-dark f-rem-0.9 pointer"
									onClick={handleConfigReset}
								>
									Reset
								</span>
							</Flex>
							<div className="py-1"></div>
							<TokenSelector
								supportedTokens={supportedTokens}
								Token0={Token0}
								Token1={Token1}
								dispatchSelectedTokens={dispatchSelectedTokens}
							/>
							<div className="py-1"></div>
							<FeeTierSelector
								disabled={!Token0 || !Token1}
								feeRate={feeRate}
								setFeeRate={setFeeRate}
							/>
							<div className="py-1"></div>
							<DepositAmounts
								disabled={!Token0 || !Token1 || !feeRate}
								chainId={chainId}
								Token0={Token0}
								Token1={Token1}
								wallet={wallet}
								token0Balance={token0Balance}
								token1Balance={token1Balance}
								token0Amount={token0Amount}
								setToken0Amount={setToken0Amount}
								token1Amount={token1Amount}
								setToken1Amount={setToken1Amount}
								poolState={poolState}
								selectedTickRange={selectedTickRange}
								hasSelectedPosition={!!positionsInSelectedPool?.find?.(p => p.pid === selectedPid)}
							/>
							<div className="py-1"></div>
							<ActionBtn
								chainId={chainId}
								wallet={wallet}
								Token0={Token0}
								Token1={Token1}
								feeRate={feeRate}
								token0Balance={token0Balance}
								token1Balance={token1Balance}
								token0Amount={token0Amount}
								token1Amount={token1Amount}
								selectedPid={selectedPid}
								selectedPosition={positionsInSelectedPool?.find?.(p => p.pid === selectedPid)}
								poolState={poolState}
							/>
							{!isMobile && (
								<>
									<div className="py-1"></div>
									<MyPositionsForTickSelection
										title="(click to use tokens/ticks)"
										positions={positions}
										positionsIsFetching={positionsIsFetching}
										currentTick={poolState?.tickCurrent}
										selectedPid={selectedPid}
										handleOnClick={handleOnPositionSelectionClick}
									/>
								</>
							)}
						</Col>
						{/*Range Selector*/}
						<Col xs={12} lg={7} xl={8}>
							<Flex
								justify="between"
								align="center"
								className={classnames('TickChartWrapperTitle', {
									disabled: !Token0 || !Token1 || !feeRate || !pairAddress || !pairAddress,
								})}
							>
								<span className="fw-5">Set Price Range</span>
								<span
									className="reset-selected-tick-range text-info-dark f-rem-0.9"
									onClick={handlePriceRangeReset}
								>
									Reset
								</span>
							</Flex>
							{!!Token0 && !!Token1 && feeRate && !!pairAddress && !!pairAddress && (
								<>
									<div className="py-1"></div>
									<TickChartWrapper
										chainId={chainId}
										pairAddress={pairAddress}
										poolState={poolState}
										poolStateIsLoading={poolStateIsLoading}
										feeRate={feeRate}
										Token0={Token0}
										Token1={Token1}
										selectedTickRange={selectedTickRange}
										setSelectedTickRange={setSelectedTickRange}
										liqInTickRange={liqInTickRange}
										setLiqInTickRange={setLiqInTickRange}
										selectedVolForCalc={selectedVolForCalc}
										setSelectedVolForCalc={setSelectedVolForCalc}
										positionsInSelectedPool={positionsInSelectedPool}
										positionsIsFetching={positionsIsFetching}
										tokenReverse={tokenReverse}
										dispatchSelectedTokens={dispatchSelectedTokens}
										selectedPid={selectedPid}
										setSelectedPid={setSelectedPid}
									/>
								</>
							)}
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default Liquidity;

const TokenSelector = ({supportedTokens, Token0, Token1, dispatchSelectedTokens}) => {
	return (
		<div className="TokenSelector">
			<TokensDropdown
				tokens={!Token1 ? supportedTokens : supportedTokens.filter(
					t => !t.equals(Token1)
				)}
				selectedToken={Token0}
				setSelectedToken={token => dispatchSelectedTokens({which: 'Token0', token})}
			/>
			<TokensDropdown
				tokens={!Token0 ? supportedTokens : supportedTokens.filter(
					t => !t.equals(Token0)
				)}
				selectedToken={Token1}
				setSelectedToken={token => dispatchSelectedTokens({which: 'Token1', token})}
				className="w-100"
			/>
		</div>
	);
};

const FeeTierSelector = ({disabled, feeRate, setFeeRate}) => {
	return (
		<div
			className={classnames('FeeTierSelector', {
				'disabled': disabled,
			})}
		>
			<span className="d-block fw-5 mb-1">Select Fee Rate</span>
			<div className="fee-selector-wrapper">
				{FEE_RATES.map(f => (
					<Flex
						key={f}
						direction="column"
						className={classnames('tier', {
							'active': feeRate === f,
						})}
						onClick={() => !disabled && setFeeRate(f)}
					>
						<Flex justify="between">
							<span className="fw-5">{f/10000}%</span>
							{feeRate === f && (
								<FontAwesomeIcon icon={faCircleCheck} className="text-primary" />
							)}
						</Flex>
					</Flex>
				))}
			</div>
		</div>
	);
};

const DepositAmounts = ({disabled, chainId, Token0, Token1, wallet, token0Balance, token1Balance, token0Amount, setToken0Amount, token1Amount, setToken1Amount, poolState, selectedTickRange, hasSelectedPosition}) => {
	const [ratioLocked, setRatioLocked] = useState(true);
	const [dependentInputIdx, setDependentInputIdx] = useState();
	const token0InputRef = useRef();
	const token1InputRef = useRef();

	useEffect(() => {
		if (ratioLocked && poolState?.address && selectedTickRange?.minTickIdx) {
			const [poolToken0, poolToken1] = getTokens(poolState?.Token0, poolState?.Token1);

			// const dependentToken = dependentInputIdx === '0' ? Token0 : Token1;
			const independentToken = dependentInputIdx === '0' ? Token1 : Token0;
			// const dependentAmount = dependentInputIdx === '0' ? token0Amount : token1Amount;
			const independentAmount = dependentInputIdx === '0' ? token1Amount : token0Amount;

			// const dependentCurrencyAmount = CurrencyAmount.fromRawAmount(
			// 	dependentToken,
			// 	ethers.utils.parseUnits(
			// 		dependentAmount || '0',
			// 		dependentToken?.decimals
			// 	)
			// );
			const independentCurrencyAmount = CurrencyAmount.fromRawAmount(
				independentToken,
				ethers.utils.parseUnits(
					independentAmount || '0',
					independentToken?.decimals
				)
			);

			const poolForPosition = new Pool(
				poolToken0,
				poolToken1,
				poolState.fee,
				poolState.sqrtPriceX96Current,
				poolState.liquidityCurrent,
				poolState.tickCurrent
			);

			const position = poolForPosition.token0.equals(independentToken) ? Position.fromAmount0({
				pool: poolForPosition,
				tickLower: selectedTickRange?.minTickIdx,
				tickUpper: selectedTickRange?.maxTickIdx,
				amount0: independentCurrencyAmount.quotient,
				useFullPrecision: true, // we want full precision for the theoretical position
			}) : Position.fromAmount1({
				pool: poolForPosition,
				tickLower: selectedTickRange?.minTickIdx,
				tickUpper: selectedTickRange?.maxTickIdx,
				amount1: independentCurrencyAmount.quotient,
			});

			const calculatedDependentCurrencyAmount = poolForPosition.token0.equals(independentToken)
				? position.amount1
				: position.amount0;

			if (dependentInputIdx === '0') {
				setToken0Amount(calculatedDependentCurrencyAmount.toExact());
				token0InputRef.current.value = calculatedDependentCurrencyAmount.toExact();
			} else {
				setToken1Amount(calculatedDependentCurrencyAmount.toExact());
				token1InputRef.current.value = calculatedDependentCurrencyAmount.toExact();
			}
		}
	}, [
		ratioLocked,
		dependentInputIdx,
		Token0,
		Token1,
		token0Amount,
		token1Amount,
		setToken0Amount,
		setToken1Amount,
		poolState,
		selectedTickRange,
	]);

	useEffect(() => {
		if (!hasSelectedPosition) {
			setRatioLocked(true);
		}
	}, [hasSelectedPosition]);

	return (
		<div
			className={classnames('DepositAmounts', {
				'disabled': disabled,
			})}
		>
			<Flex justify="between" align="center">
				<span className="d-block fw-5 mb-1">Deposit Amounts</span>
				<Flex align="center" className="actions">
					<FontAwesomeIcon
						icon={ratioLocked ? faLock : faLockOpen }
						className="ratio-lock"
						onClick={() => hasSelectedPosition && setRatioLocked(prev => !prev)}
						style={!hasSelectedPosition ? {
							opacity: '0.75',
							cursor: 'not-allowed',
						} : {}}
					/>
					<span
						className="all-balance text-info-dark f-rem-0.9"
						onClick={() => {
							if (!disabled) {
								setRatioLocked(false);

								token0InputRef.current.value = token0Balance?.[Token0?.address]?.balanceAsStr;
								setToken0Amount(token0InputRef.current.value);
								token1InputRef.current.value = token1Balance?.[Token1?.address]?.balanceAsStr;
								setToken1Amount(token1InputRef.current.value);
							}
						}}
					>
						All
					</span>
				</Flex>
			</Flex>
			<DepositAmountQuantityInput
				disabled={disabled || !Token0}
				chainId={chainId}
				token={Token0}
				tokenAmount={token0Amount}
				setTokenAmount={setToken0Amount}
				balance={token0Balance?.[Token0?.address]?.balanceAsStr}
				tokenUsdPrice={Number(token0Balance?.[Token0?.address]?.usdPriceStr)}
				wallet={wallet}
				inputRef={token0InputRef}
				setDependentInputIdx={() => setDependentInputIdx("1")}
			/>
			<div className="py-1"></div>
			<DepositAmountQuantityInput
				disabled={disabled || !Token1}
				chainId={chainId}
				token={Token1}
				tokenAmount={token1Amount}
				setTokenAmount={setToken1Amount}
				balance={token1Balance?.[Token1?.address]?.balanceAsStr}
				tokenUsdPrice={Number(token1Balance?.[Token1?.address]?.usdPriceStr)}
				wallet={wallet}
				inputRef={token1InputRef}
				setDependentInputIdx={() => setDependentInputIdx("0")}
			/>
		</div>
	);
};

const DepositAmountQuantityInput = ({disabled, chainId, token, tokenAmount, setTokenAmount, balance, tokenUsdPrice, wallet, inputRef, setDependentInputIdx}) => {
	useEffect(() => {
		inputRef.current.value = '';
	// eslint-disable-next-line
	}, [token]);

	const tokenAmountUsdValue = useMemo(() => {
		if (tokenAmount && tokenUsdPrice) {
			return Number(tokenAmount) * Number(tokenUsdPrice);
		}
	}, [tokenAmount, tokenUsdPrice]);

	return (
		<Flex
			direction="column"
			justify="start"
			align="between"
			className="DepositAmountQuantityInput"
		>
			<Flex
				justify="between"
				className="input-and-token-logo"
			>
				<input
					ref={inputRef}
					type="number"
					placeholder="0"
					disabled={disabled}
					onFocus={setDependentInputIdx}
					onChange={e => setTokenAmount(e.target.value)}
				/>
				<Flex
					justify="end"
					align="center"
					className="logo-wrapper border"
				>
					{token ? (
						<>
							<Logo token={token} />
							<span className="ml-1">{token?.symbol}</span>
						</>
					) : (
						<span>Select a token</span>
					)}
				</Flex>
			</Flex>

			<Flex
				justify="between"
				align="center"
				className="usd-value-and-balance"
			>
				<span className="usd-value">{currencyFormat(tokenAmountUsdValue, '-')}</span>
				<span
					className="balance"
					onClick={() => {
						if (!disabled) {
							setDependentInputIdx();

							inputRef.current.value = balance || 0;
							setTokenAmount(balance || 0);
						}
					}}
				>
					Balance: {balance || '0'}
				</span>
			</Flex>
		</Flex>
	);
};

const ActionBtn = ({chainId, wallet, Token0, Token1, feeRate, token0Balance, token1Balance, token0Amount, token1Amount, selectedPid, selectedPosition, poolState}) => {
	const [calldataIsLoading, setCalldataIsLoading] = useState(null);
	const [calldataError, setCalldataError] = useState(null);
	const [txUrl, setTxUrl] = useState(null);
	// Use this for useGetTokenAllowances which stays in the same order
	// regardless of tokenReverse; This is so useGetTokenAllowancesQuery doesn't
	// unnecessarily run when you reverse but have the same two tokens.
	const memoizedTokensForPairAddress = useMemo(() => {
		return {
			Token0: !Token0 || !Token1 ? Token0
				: Token0?.sortsBefore?.(Token1) ? Token0 : Token1,
			Token1: !Token0 || !Token1 ? Token1
				: Token0?.sortsBefore?.(Token1) ? Token1 : Token0,
		}
	}, [Token0, Token1]);

	const {
		data: tokenAllowances = {},
		isFetching: tokenAllowancesIsFetching,
	} = useGetTokenAllowancesQuery({
		chainId,
		tokenAddresses: [memoizedTokensForPairAddress?.Token0?.address, memoizedTokensForPairAddress?.Token1?.address].filter(o => o),
		spender: SWAP_ROUTER_ADDRESS,
		wallet,
	}, {
		skip: !memoizedTokensForPairAddress?.Token0?.address || !memoizedTokensForPairAddress?.Token1?.address,
		pollingInterval: 15 * 1000,
	});

	const {
		// token0BalanceCurrencyAmount,
		token0ToAddCurrencyAmount,
		token0InsufficientBalance,
		// token0AllowanceCurrencyAmount,
		token0RequiresApproval,
		// token1BalanceCurrencyAmount,
		token1ToAddCurrencyAmount,
		token1InsufficientBalance,
		// token1AllowanceCurrencyAmount,
		token1RequiresApproval,
	} = useMemo(() => {
		// Token0
		const token0BalanceCurrencyAmount = Token0 && token0Balance?.[Token0?.address]?.balanceAsStr && CurrencyAmount.fromRawAmount(
			Token0,
			ethers.utils.parseUnits(
				token0Balance?.[Token0?.address]?.balanceAsStr,
				Token0?.decimals
			)
		);
		const token0ToAddCurrencyAmount = Token0 && token0Amount && CurrencyAmount.fromRawAmount(
			Token0,
			ethers.utils.parseUnits(
				token0Amount,
				Token0?.decimals
			)
		);
		const token0InsufficientBalance = !!token0BalanceCurrencyAmount && !!token0ToAddCurrencyAmount &&  token0BalanceCurrencyAmount.lessThan(token0ToAddCurrencyAmount);
		const token0AllowanceCurrencyAmount = tokenAllowances?.[Token0?.address] && CurrencyAmount.fromRawAmount(
			Token0,
			tokenAllowances?.[Token0?.address]
		);
		const token0RequiresApproval = !token0ToAddCurrencyAmount || !token0AllowanceCurrencyAmount || token0AllowanceCurrencyAmount.lessThan(token0ToAddCurrencyAmount);

		// Token1
		const token1BalanceCurrencyAmount = Token1 && token1Balance?.[Token1?.address]?.balanceAsStr && CurrencyAmount.fromRawAmount(
			Token1,
			ethers.utils.parseUnits(
				token1Balance?.[Token1?.address]?.balanceAsStr,
				Token1?.decimals
			)
		);
		const token1ToAddCurrencyAmount = Token1 && token1Amount && CurrencyAmount.fromRawAmount(
			Token1,
			ethers.utils.parseUnits(
				token1Amount,
				Token1?.decimals
			)
		);
		const token1InsufficientBalance = !!token1BalanceCurrencyAmount && !!token1ToAddCurrencyAmount &&  token1BalanceCurrencyAmount.lessThan(token1ToAddCurrencyAmount);
		const token1AllowanceCurrencyAmount = tokenAllowances?.[Token1?.address] && CurrencyAmount.fromRawAmount(
			Token1,
			tokenAllowances?.[Token1?.address]
		);
		const token1RequiresApproval = !token1ToAddCurrencyAmount || !token1AllowanceCurrencyAmount || token1AllowanceCurrencyAmount.lessThan(token1ToAddCurrencyAmount);

		return {
			token0BalanceCurrencyAmount,
			token0ToAddCurrencyAmount,
			token0InsufficientBalance,
			token0AllowanceCurrencyAmount,
			token0RequiresApproval,
			token1BalanceCurrencyAmount,
			token1ToAddCurrencyAmount,
			token1InsufficientBalance,
			token1AllowanceCurrencyAmount,
			token1RequiresApproval,
		};
	}, [Token0, Token1, token0Balance, token1Balance, token0Amount, token1Amount, tokenAllowances]);

	const btnText = useMemo(() => {
		if (!wallet || !chainId) return 'Connect Web3 wallet';
		if (!Token0 || !Token1) return 'Select Tokens';
		if (!feeRate) return 'Select fee rate';
		if (token0InsufficientBalance) return `Insufficient ${Token0.symbol} balance`;
		if (token1InsufficientBalance) return `Insufficient ${Token1.symbol} balance`;
		if (
			!token0Amount ||
			!token1Amount ||
			(token0ToAddCurrencyAmount?.equalTo?.(0) && token1ToAddCurrencyAmount?.equalTo?.(0))
		) return 'Specify amounts';
		if (tokenAllowancesIsFetching) return <Spinner />;
		if (token0RequiresApproval) return `Approve ${Token0.symbol}`;
		if (token1RequiresApproval) return `Approve ${Token1.symbol}`;
		if (calldataIsLoading) return <Spinner />;
		if (!selectedPid) return 'Mint Position';
		if (selectedPid) return 'Add Liquidity';
	}, [wallet, chainId, Token0, Token1, feeRate, token0Amount, token1Amount, token0ToAddCurrencyAmount, token1ToAddCurrencyAmount, token0InsufficientBalance, token1InsufficientBalance, token0RequiresApproval, token1RequiresApproval, tokenAllowancesIsFetching, selectedPid, calldataIsLoading]);

	const onTokenSpenderApproval = useCallback(async (token) => {
		try {
			const tokenContract = new ethers.Contract(
				token.address,
				TokenAbi,
				getBrowserExtensionProvider(),
			);

			const transaction = await tokenContract.populateTransaction.approve(
				SWAP_ROUTER_ADDRESS,
				MAX_UINT256
			);

			await sendTransactionViaExtension({
				...transaction,
				from: wallet,
			});
		} catch(err) {
			console.error(err);
		}
	}, [wallet]);

	const onAddLiquidity = useCallback(async () => {
		setCalldataError(null);
		setTxUrl(null);
		setCalldataIsLoading(true);

		try {
			const calldata = await genRouteToRatioCalldata(
				Token0,
				Token1,
				feeRate,
				token0Amount,
				token1Amount,
				selectedPosition,
				poolState,
				wallet,
				chainId,
			);

			if (calldata?.data) sendTransactionViaExtension(calldata).then(
				({ txHash }) => txHash && setTxUrl(genTxHashUrl(txHash, chainId))
			);
			else throw new Error('Failed to generate calldata; try again');
		} catch(err) {
			setCalldataError(err.toString());
			console.error(err);
		}

		setCalldataIsLoading(false);

		// try {
		// 	const [SortedToken0, SortedToken1] = getTokens(poolState?.Token0, poolState?.Token1);

		// 	const queryResult = storeDispatch(positionsApiSlice.endpoints.getRouteToRatioCalldata.initiate({
		// 		chainId,
		// 		pid: selectedPid,
		// 		token0AmountToAdd: SortedToken0.address === Token0.address ? token0Amount : token1Amount,
		// 		token1AmountToAdd: SortedToken1.address === Token1.address ? token1Amount : token0Amount,
		// 		wallet,
		// 	}));

		// 	const response = await queryResult.finally(
		// 		() => queryResult.unsubscribe()
		// 	);

		// 	console.log(response?.data)

		// 	response?.data && sendTransactionViaExtension(response?.data);
		// } catch(err) {
		// 	console.error(err);
		// }

		// setCalldataIsLoading(false);
	}, [
		Token0,
		Token1,
		feeRate,
		token0Amount,
		token1Amount,
		selectedPosition,
		poolState,
		wallet,
		chainId,
	]);

	return (
		<>
			<Button
				variant={token0InsufficientBalance || token1InsufficientBalance ? 'danger' : 'primary'}
				disabled={
					!Token0 || !Token1 || !feeRate || !token0Amount || !token1Amount ||
					!['Approve', 'Mint', 'Add Liquidity'].some(
						o => new RegExp(o, 'i').test(btnText)
					) || calldataIsLoading
				}
				className="w-100"
				style={{borderRadius: '0.5rem'}}
				onClick={() => {
					if (token0RequiresApproval) onTokenSpenderApproval(Token0);
					else if (token1RequiresApproval) onTokenSpenderApproval(Token1);
					else if (selectedPid) {
						onAddLiquidity();
					}
				}}
			>
				{btnText}
			</Button>
			{calldataError ? (
				<div className="mt-2 px-2 text-center text-danger f-rem-0.95" style={{lineHeight: '1.2'}}>
					<span>{calldataError}</span>
				</div>
			) : txUrl ? (
				<div className="mt-2 px-2 text-center text-primary f-rem-0.95" style={{lineHeight: '1.2'}}>
					<a
						href={txUrl}
						target="_blank"
						rel="noreferrer"
					>
						View transaction
					</a>
				</div>
			) : null}
		</>
	);
};

const TickChartWrapper = ({chainId, pairAddress, poolState, poolStateIsLoading, feeRate, Token0, Token1, selectedTickRange, setSelectedTickRange, liqInTickRange, setLiqInTickRange, selectedVolForCalc, setSelectedVolForCalc, positionsInSelectedPool, positionsIsFetching, tokenReverse, dispatchSelectedTokens, selectedPid, setSelectedPid}) => {
	const {
    data: tickData = {},
    isLoading: tickDataIsLoading,
  } = useGetTickDataQuery({
  	chainId,
  	token0Address: Token0?.address,
  	token1Address: Token1?.address,
  	feeRate,
  	// pool: poolState
  }, {
  	skip: poolStateIsLoading,
  	pollingInterval: 60 * 1000,
  });

  const {
  	data: formattedTickData = [],
  	isLoading: formattedPoolDataIsLoading,
  	isFetching: formattedPoolDataIsFetching,
  } = useFormatPoolData({ tickData, pool: poolState, position: {} }, {
  	skip: poolStateIsLoading || tickDataIsLoading
  });

  const isLoading = poolStateIsLoading || tickDataIsLoading || formattedPoolDataIsLoading;

  const filteredTicks = useMemo(() => {
  	return filterTicks(formattedTickData);
  }, [formattedTickData]);

  // const reverseTicks = Number(poolState?.token0Price) < Number(poolState?.token1Price) ? !tokenReverse
  // 	: tokenReverse;

  const dimTicks = useMemo(() => {
  	return feeRate && Object.keys(selectedTickRange).length > 0 ? {
	  	lessThan: nearestUsableTick(selectedTickRange.minTickIdx, TICK_SPACINGS[feeRate]),
	  	greaterThan: nearestUsableTick(selectedTickRange.maxTickIdx, TICK_SPACINGS[feeRate]),
	  } : null;
  }, [selectedTickRange, feeRate]);

  const ticksInSelectedRange = useMemo(() => {
  	return dimTicks ? filteredTicks.filter(
  		tick => tick.tickIdx >= dimTicks.lessThan && tick.tickIdx <= dimTicks.greaterThan
  	) : [];
  }, [dimTicks, filteredTicks]);

  const liquidityInSelectedTickRange = useMemo(() => {
  	return ticksInSelectedRange.reduce((acc, tick) => {
  		const { tvlToken0, tvlToken1, price0, price1 } = tick;
  		const tvlToken1InTermsOfToken0 = tvlToken1 * price1;
  		const tvlToken0InTermsOfToken1 = tvlToken0 * price0;

  		acc.tvlToken0 += tvlToken0 || tvlToken1InTermsOfToken0;
  		acc.tvlToken1 += tvlToken1 || tvlToken0InTermsOfToken1;

  		return acc;
  	}, {
  		token0Address: tokenReverse ? Token1?.address : Token0?.address,
  		tvlToken0: 0,
  		tvlToken1: 0
  	});
  }, [ticksInSelectedRange, Token0?.address, Token1?.address, tokenReverse]);

  useEffect(() => {
	  if (
	  	feeRate && Object.keys(selectedTickRange).length > 0 && (
	  		liqInTickRange?.[feeRate]?.tvlToken0 !== liquidityInSelectedTickRange?.tvlToken0 ||
	  		liqInTickRange?.[feeRate]?.tvlToken1 !== liquidityInSelectedTickRange?.tvlToken1
	  	)
	  ) {
	  	setLiqInTickRange(prev => ({
		  	...prev,
		  	[feeRate]: liquidityInSelectedTickRange,
		  }));
	  }
  }, [selectedTickRange, feeRate, liqInTickRange, liquidityInSelectedTickRange, setLiqInTickRange]);

  const handleTickTap = useCallback((tickInChartProps) => {
  	setSelectedPid(null);
  	const { tickIdx: activeTickIdx } = filteredTicks.find(tick => !!tick.isCurrent) || {};
  	const { tickIdx } = tickInChartProps;

  	setSelectedTickRange(prev => {
  		const newState = { ...prev };

  		const hasMinTick = !!prev.minTickIdx && prev.minTickIdx >= TickMath.MIN_TICK;
  		const hasMaxTick = !!prev.maxTickIdx && prev.maxTickIdx <= TickMath.MAX_TICK;

  		if (!hasMinTick && !hasMaxTick) {
  			if (tickIdx <= activeTickIdx) {
	  			newState.minTickIdx = tickIdx;
	  			newState.maxTickIdx = nearestUsableTick(TickMath.MAX_TICK, TICK_SPACINGS[feeRate]);
  			} else {
  				newState.maxTickIdx = tickIdx;
  				newState.minTickIdx = nearestUsableTick(TickMath.MIN_TICK, TICK_SPACINGS[feeRate]);
  			}
  		} else {
  			if (
  				tickIdx >= prev.maxTickIdx ||
  				tickIdx >= activeTickIdx
  			) {
  				newState.maxTickIdx = tickIdx;
  			} else if (
  				tickIdx <= prev.minTickIdx ||
  				tickIdx < activeTickIdx
  			) {
  				newState.minTickIdx = tickIdx;
  			}
  		}

  		return newState;
  	});
  }, [setSelectedPid, filteredTicks, setSelectedTickRange, feeRate]);

  const [Token0FromPool, Token1FromPool] = getTokens(poolState?.Token0, poolState?.Token1);

  const currentTick = (!formattedPoolDataIsFetching ? filteredTicks : []).find(t => t.isCurrent);

	return isLoading ? (
		<FullLoader text="ticks" classNameOverrides="p-0" />
	) : (
		<>
			<Card className="TickChart border-0 rounded">
				<TickChartComponent
					pool={poolState}
					// filteredTicks={reverseTicks ? filteredTicks.reverse() : filteredTicks}
					filteredTicks={filteredTicks}
					reverseChart={tokenReverse}
					chartHeight={176}
					hideCurrentPriceLabel={true}
					dimTicks={dimTicks}
					onTickTap={handleTickTap}
				/>
			</Card>
			<Row className="PoolInfoAndPriceRangeSelectors mb-3">
				<Col xs={12} lg={5} className="mt-3">
					{/* Vol selector for apr calc*/}
					<Flex
						direction="column"
						className="PoolInfoContainer"
					>
						<Card className="mb-2">
							<Card.Body className="pt-2 pb-3 px-3">
								<Toggle
									className="border f-rem-0.85"
									ops={['m5', 'h1', 'h6', 'h24']}
									active={(op) => op === selectedVolForCalc}
									setActive={(op) => setSelectedVolForCalc(op)}
									label="Timerange for APR Calc"
									labelClassName="f-rem-0.75 text-gray-600 mb-0.5"
								/>
							</Card.Body>
						</Card>
						<div>
							<PoolInfo
								chainId={chainId}
								feeRate={feeRate}
								pairAddress={pairAddress}
								liqInTickRange={liqInTickRange}
								Token0={Token0}
								Token1={Token1}
								selectedVolForCalc={selectedVolForCalc}
								className="shadow-none border"
							/>
						</div>
					</Flex>
				</Col>
				<Col xs={12} lg={7} className="mt-3">
					<Flex
						justify="between"
						align="center"
						className="mb-3"
					>
						<Toggle
							ops={[Token0FromPool?.symbol, Token1FromPool?.symbol]}
							active={tokenReverse
								? Token0FromPool.equals(Token1) ? Token1FromPool?.symbol : Token0FromPool?.symbol
								: Token0FromPool.equals(Token0) ? Token0FromPool?.symbol : Token1FromPool?.symbol
							}
							setActive={() => dispatchSelectedTokens({which: 'reverse'})}
							className="w-fit f-rem-0.75 fw-5"
						/>
						<span className="f-rem-0.9 fw-5 text-gray-700">
							Cur Price: {commaFormat(
								tokenReverse ? currentTick?.price1 : currentTick?.price0, 6
							)}
						</span>
					</Flex>
					{/*Tick range*/}
					<TickSelectorBoxes
						Token0={Token0}
						Token1={Token1}
						feeRate={feeRate}
						selectedTickRange={selectedTickRange}
						setSelectedTickRange={setSelectedTickRange}
						ticks={!formattedPoolDataIsFetching ? filteredTicks : []}
						tokenReverse={tokenReverse}
						setSelectedPid={setSelectedPid}
					/>
					{/*Show range percentage*/}
					<TickPercentageBoxes
						Token0={Token0}
						Token1={Token1}
						selectedTickRange={selectedTickRange}
						ticks={!formattedPoolDataIsFetching ? filteredTicks : []}
						tokenReverse={tokenReverse}
					/>
					{/*Use existing position tick range*/}
					<MyPositionsForTickSelection
						title="(click to use ticks)"
						positions={positionsInSelectedPool}
						positionsIsFetching={positionsIsFetching}
						currentTick={currentTick}
						selectedPid={selectedPid}
						handleOnClick={position => {
							setSelectedPid(position.pid);
							setSelectedTickRange(prev => ({
								...prev,
								minTickIdx: position.position.tickLower,
								maxTickIdx: position.position.tickUpper,
							}));
						}}
					/>
				</Col>
			</Row>
		</>
	);
};

const TickSelectorBoxes = ({Token0, Token1, feeRate, selectedTickRange, setSelectedTickRange, ticks = [], tokenReverse, setSelectedPid}) => {
	const [minTick, maxTick] = useMemo(() => [
		ticks?.find?.(t => t.tickIdx === selectedTickRange?.minTickIdx) || ticks?.[0],
		ticks?.find?.(t => t.tickIdx === selectedTickRange?.maxTickIdx) || ticks?.[ticks?.length - 1],
	], [selectedTickRange, ticks]);

	return (
		<Flex
			justify="between"
			align="center"
			className="TickSelectorBoxes"
		>
			<TickSelectorBox
				title="Min"
				tickPrice={tokenReverse ? maxTick?.price1 : minTick?.price0}
				tokenSymbol={Token0?.symbol}
				otherTokenSymbol={Token1?.symbol}
				feeRate={feeRate}
				setSelectedTickRange={setSelectedTickRange}
				tokenReverse={tokenReverse}
				setSelectedPid={setSelectedPid}
			/>
			<div className="mx-1"></div>
			<TickSelectorBox
				title="Max"
				tickPrice={tokenReverse ? minTick?.price1 : maxTick?.price0}
				tokenSymbol={Token1?.symbol}
				otherTokenSymbol={Token0?.symbol}
				feeRate={feeRate}
				setSelectedTickRange={setSelectedTickRange}
				tokenReverse={tokenReverse}
				setSelectedPid={setSelectedPid}
			/>
		</Flex>
	);
};

const TickSelectorBox = ({title, tickPrice, tokenSymbol, otherTokenSymbol, feeRate, setSelectedTickRange, tokenReverse, setSelectedPid}) => {
	const whichTick = title === 'Min'
		? !tokenReverse ? 'minTickIdx' : 'maxTickIdx'
		: !tokenReverse ? 'maxTickIdx' : 'minTickIdx';

	return (
		<Flex
			justify="between"
			align="center"
			className="TickSelectorBox border rounded p-2 w-100"
		>
			<FontAwesomeIcon
				icon={faMinus}
				className="tick-modify-btn mr-0.5"
				onClick={() => {
					setSelectedPid(null);
					setSelectedTickRange(prev => ({
						...prev,
						...prev?.[whichTick] ? {
							[whichTick]: prev?.[whichTick] + ((!tokenReverse ? -1 : 1)*TICK_SPACINGS[feeRate]),
						} : {},
					}))
				}}
			/>
			<Flex direction="column" className="mx-1.5">
				<span className="f-rem-0.7 fw-5 text-gray-600 text-wrap text-center">
					{title} price
				</span>
				<span className="f-rem-1 fw-5 text-gray-700 text-wrap text-center">
					{commaFormat(tickPrice, 6)}
				</span>
				<span className="f-rem-0.7 fw-5 text-gray-600 text-wrap text-center">
					{otherTokenSymbol} per {tokenSymbol}
				</span>
			</Flex>
			<FontAwesomeIcon
				icon={faPlus}
				className="tick-modify-btn ml-0.5"
				onClick={() => setSelectedTickRange(prev => ({
					...prev,
					...prev?.[whichTick] ? {
						[whichTick]: prev?.[whichTick] + ((!tokenReverse ? 1 : -1)*TICK_SPACINGS[feeRate]),
					} : {},
				}))}
			/>
		</Flex>
	);
};

const TickPercentageBoxes = ({Token0, Token1, selectedTickRange, ticks = [], tokenReverse}) => {
	const mini = window.innerWidth <= 991;
	const [minTick, maxTick, currentTick] = useMemo(() => [
		ticks?.find?.(t => t.tickIdx === selectedTickRange?.minTickIdx) || ticks?.[0],
		ticks?.find?.(t => t.tickIdx === selectedTickRange?.maxTickIdx) || ticks?.[ticks?.length - 1],
		ticks?.find?.(t => t.isCurrent),
	], [selectedTickRange, ticks]);

	const [
		startPercFormatted, rangePercFormatted, endPercFormatted
	] = useMemo(() => {
		if (!currentTick?.tickIdx || !minTick?.tickIdx || !maxTick?.tickIdx) return [];

		const curTickPrice = tickToPrice(Token0, Token1, currentTick.tickIdx);
		const minTickPrice = tickToPrice(Token0, Token1, minTick.tickIdx);
		const maxTickPrice = tickToPrice(Token0, Token1, maxTick.tickIdx);

		const startPercAwayFromCurPrice = minTickPrice.subtract(curTickPrice).divide(minTickPrice).multiply(100);
		const endPercAwayFromCurPrice = maxTickPrice.subtract(curTickPrice).divide(maxTickPrice).multiply(100);
		const fullRangePerc = Math.abs(startPercAwayFromCurPrice.toFixed(2) - endPercAwayFromCurPrice.toFixed(2));

		const startPercFormatted = `${startPercAwayFromCurPrice.toFixed(2) >= 0 ? '+' : ''}${startPercAwayFromCurPrice.toFixed(2)}%`;
		const endPercFormatted = `${endPercAwayFromCurPrice.toFixed(2) >= 0 ? '+' : ''}${endPercAwayFromCurPrice.toFixed(2)}%`;
		const rangePercFormatted = `${fixedAsNumber(fullRangePerc, 2)}%`;

		const returnPercentages = [
			endPercFormatted,
			rangePercFormatted,
			startPercFormatted,
		];

		return !tokenReverse ? returnPercentages.reverse() : returnPercentages;
	}, [Token0, Token1, currentTick?.tickIdx, minTick?.tickIdx, maxTick?.tickIdx, tokenReverse]);

	return (
		<Flex direction="column" className={classnames('PositionPercentages', { 'border-0 mt-0 pt-0': mini })}>
			<span className={classnames({'mb-1.5': !mini, 'mb-3': mini})}>Position range</span>
			<Flex justify="between" align="center" className="range-container w-100">
				<Flex direction="column" className="edge-range-container border rounded p-2 text-center w-25">
					<span className="f-rem-0.9 fw-5 text-gray-700 text-wrap">{startPercFormatted}</span>
					<span className="f-rem-0.7 fw-5 text-gray-600 text-wrap">From cur tick</span>
				</Flex>
				<div className="border-line-between"></div>
				<Flex direction="column" className="edge-range-container border rounded py-2 px-4 text-center">
					<span className="f-rem-1 fw-5 text-gray-700 text-wrap">{rangePercFormatted}</span>
					<span className="f-rem-0.7 fw-5 text-gray-600 text-wrap">Range</span>
				</Flex>
				<div className="border-line-between"></div>
				<Flex direction="column" className="edge-range-container border rounded p-2 text-center w-25">
					<span className="f-rem-0.9 fw-5 text-gray-700 text-wrap">{endPercFormatted}</span>
					<span className="f-rem-0.7 fw-5 text-gray-600 text-wrap">From cur tick</span>
				</Flex>
			</Flex>
		</Flex>
	);
};

const MyPositionsForTickSelection = ({title, positions = [], positionsIsFetching, currentTick, selectedPid, handleOnClick, borderBottom}) => {
	return !positions?.[0] ? (
		<span
			className={classnames('MyPositionsForTickSelection d-block text-muted', {
				borderBottom: borderBottom,
			})}
		>
			No positions {positionsIsFetching && <small><Spinner /></small>}
		</span>
	) : (
		<Flex
			direction="column"
			className={classnames('MyPositionsForTickSelection', {
				borderBottom: borderBottom
			})}
		>
			<span>Positions <small className="text-muted">{title}{positionsIsFetching && <Spinner className="ml-1" />}</small></span>
			<Flex className="positions-container">
				{positions.map(position => (
					<MyPositionBox
						key={position.pid}
						position={position}
						currentTick={currentTick}
						selectedPid={selectedPid}
						handleOnClick={handleOnClick}
					/>
				))}
			</Flex>
		</Flex>
	);
};

const MyPositionBox = ({position, currentTick, selectedPid, handleOnClick}) => {
	const [Token0, Token1] = getTokens(position?.pool?.Token0, position?.pool?.Token1);
	const _currentTickIdx = currentTick?.tickIdx || position?.pool?.tickCurrent;
	const curTickPrice = tickToPrice(Token0, Token1, _currentTickIdx);
	const minTickPrice = tickToPrice(Token0, Token1, position?.position?.tickLower);
	const maxTickPrice = tickToPrice(Token0, Token1, position?.position?.tickUpper);

	const startPercAwayFromCurPrice = minTickPrice.subtract(curTickPrice).divide(minTickPrice).multiply(100);
	const endPercAwayFromCurPrice = maxTickPrice.subtract(curTickPrice).divide(maxTickPrice).multiply(100);
	const fullRangePerc = Math.abs(startPercAwayFromCurPrice.toFixed(2) - endPercAwayFromCurPrice.toFixed(2));
	const rangePercFormatted = `${fixedAsNumber(fullRangePerc, 2)}%`;

	const positionIsInRange = _currentTickIdx >= position?.position?.tickLower &&
		_currentTickIdx <= position?.position?.tickUpper;

	return (
		<Flex
			key={position.pid}
			direction="column"
			className={classnames('position', {
				active: selectedPid === position.pid,
			})}
			onClick={() => handleOnClick(position)}
		>
			<Flex justify="between" align="center">
				<span
					className={classnames('header', {
						'text-primary': selectedPid === position.pid,
					})}
				>
					{Token0.symbol}/{Token1.symbol}
				</span>
				<InRangeIcon ratio={positionIsInRange ? 50 : 0}/>
			</Flex>
			<span className="value-in-dai">{currencyFormat(position.position.valueInDai)}</span>
			<span className="position-range">{rangePercFormatted} range</span>
		</Flex>
	);
};
