import React, { useState, useReducer, useMemo, useEffect, useCallback, useRef } from 'react';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-duotone-svg-icons';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Logo from 'components/Logo';
import Flex from 'components/Flex';
import RefreshBtn from 'components/RefreshBtn';
import Spinner from 'components/Spinner';
import Toggle from 'components/Toggle';

import supportedChainIds, { chainNameMap, chainIdMap, chainColors } from 'constants/chains';
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens';
import { commaFormat, currencyFormat } from 'util/numberFormatter';
import { getTokens } from 'util/tokens';

import {
	useGetSupportedTokensQuery,
	useGetTokenBalancesQuery
} from 'api/client';

const capChainName = chainId => `${chainNameMap[chainId].charAt(0).toUpperCase()}${chainNameMap[chainId].slice(1)}`;

function allBalancesReducer(state, {chainId, balances = []}) {
	return chainId === 'reset' ? {} : {
		...state,
		[chainId]: balances
	}
}

function Balances(props) {
	const { wallet } = props;
	const walletRef = useRef(wallet);
	const [activeChainIds, setActiveChainIds] = useState(Object.values(supportedChainIds));
	const [showSmallBalances, setShowSmallBalances] = useState(false);
	const [allBalances = {}, dispatchAllBalances] = useReducer(allBalancesReducer, {});

	useEffect(() => {
		if (wallet !== walletRef.current) {
			walletRef.current = wallet;
			dispatchAllBalances({chainId: 'reset'});
		}
	}, [wallet, dispatchAllBalances]);

	return (
		<Container className="Positions py-4 py-lg-5">
			<Flex justify="between">
				<ChainToggle
					activeChainIds={activeChainIds}
					setActiveChainIds={setActiveChainIds}
				/>
				<Flex>
					<BalancesFilter
						showSmallBalances={showSmallBalances}
						setShowSmallBalances={setShowSmallBalances}
					/>
					<CopyBalancesToClipboard
						balances={Object.entries(allBalances).flatMap(
							([chainId, balances = []]) => balances.length > 0 && balances.map(b => ({ ...b, chainId }))
						).filter(o => o)}
						className="py-1 px-2 align-self-center"
					/>
				</Flex>
			</Flex>
			{activeChainIds.map(chainId => (
				<BalancesForChain
					key={chainId}
					chainId={chainId}
					wallet={wallet}
					dispatchAllBalances={dispatchAllBalances}
					showSmallBalances={showSmallBalances}
				/>
			))}
		</Container>
	);
};

export default Balances;

const ChainToggle = ({activeChainIds, setActiveChainIds}) => {
	return (
		<Toggle
			ops={Object.values(chainNameMap)}
			active={activeChainIds.map(id => chainNameMap[id])}
			setActive={chainName => setActiveChainIds(
				prev => prev.includes(chainIdMap[chainName]) ? prev.filter(id => id !== chainIdMap[chainName])
					: [...prev, chainIdMap[chainName]].sort((a, b) => a - b)
			)}
			className="w-fit"
		/>
	);
};

const BalancesForChain = ({chainId, wallet, dispatchAllBalances, showSmallBalances}) => {
	const chainName = chainNameMap[chainId];

	const {
		data: tokens = [],
		isLoading: tokensIsLoading,
	} = useGetSupportedTokensQuery({chainId});

	const {
		data: balances = {},
		isLoading: balancesIsLoading,
		isFetching: balancesIsFetching,
		refetch: balancesRefetch,
		isError: balancesIsError,
	} = useGetTokenBalancesQuery({
		chainId,
		tokenAddresses: tokens?.map(t => t.address),
		wallet: wallet,
		getNative: true,
		getUsd: true,
	}, {
		skip: tokensIsLoading
	});

	const isLoading = useMemo(() => tokensIsLoading || balancesIsLoading, [tokensIsLoading, balancesIsLoading]);

	const filteredAndSorted = useMemo(() => {
		const filtered = Object.entries(balances).filter(
			([address, balance]) => {
				const isValidForSmallBalanceToggle = !showSmallBalances ? balance.valueInDai >= 0.05 : true;
				const isValidGreaterThanZero = balance.balanceAsStr !== '0' && balance.valueInDai >= 0.01;
				return isValidForSmallBalanceToggle && isValidGreaterThanZero;
			}
		);

		return sortBy(
			filtered,
			([address, balance]) => balance.balance.currency.symbol
		).map(
			([address, balance]) => ({ address, ...balance })
		);
	}, [balances, showSmallBalances]);

	const stringifiedFilteredAndSorted = JSON.stringify(filteredAndSorted);

	useEffect(() => {
		if (!isLoading && !balancesIsError) {
			dispatchAllBalances({chainId, balances: JSON.parse(stringifiedFilteredAndSorted)});
		}
	}, [isLoading, balancesIsError, chainId, stringifiedFilteredAndSorted, dispatchAllBalances]);

	return (
		<Card className="shadow border-0 rounded mt-2.5">
			<div className="chain-border-color" style={{backgroundColor: chainColors[chainId]}}></div>
			<Card.Body className="p-0">
				<Flex wrap="wrap" justify="between" align="center" className="py-3 px-4">
					<h5 className="mb-0 d-flex align-items-center">
						<span className="mr-2 text-capitalize">{chainName}</span>
						<RefreshBtn
							refreshing={balancesIsFetching}
							action={balancesRefetch}
							style={{fontSize: '0.9rem'}}
						/>
						<CopyBalancesToClipboard
							balances={filteredAndSorted.map(b => ({...b, chainId}))}
						/>
					</h5>
					{/*<h6 className="text-gray-700 mb-0 text-monospace text-right">{currencyFormat(totalValue)}</h6>*/}
				</Flex>
				<div className="w-100" style={{ overflowX: 'scroll' }}>
					<Table className="table-stripe">
						{!isLoading && filteredAndSorted.length > 0 && <RenderedHeaders />}
						<tbody>
							{isLoading ? (
					  		<tr>
						  		<td colSpan="3" className="text-center"><Spinner className="text-muted" /></td>
						  	</tr>
							) : filteredAndSorted.length > 0 ? (
								filteredAndSorted.map(b => (
									<RenderedBalanceRow
										key={b.address}
										address={b.address}
										balance={b.balance}
										balanceAsStr={b.balanceAsStr}
										usdPrice={b.usdPrice}
										valueInDai={b.valueInDai}
									/>
								))
							) : (
					  		<tr>
						  		<td colSpan="3" className="text-center">No balances found</td>
						  	</tr>
							)}
						</tbody>
					</Table>
				</div>
			</Card.Body>
		</Card>
	);
};

const RenderedHeaders = ({}) => {
	return (
		<thead>
		  <tr>
		    <th scope="col" colname="token">
		    	<span className="mr-2">Token</span>
		    </th>
		    <th scope="col" colname="balance">
		    	<span className="mr-2">Balance</span>
		    </th>
		    <th scope="col" colname="valueInDai">
		    	<span className="mr-2">Value</span>
		    </th>
		  </tr>
		</thead>
	);
};

const RenderedBalanceRow = ({address, balance, balanceAsStr, usdPrice, valueInDai}) => {
	const [Token] = getTokens(balance.currency.isNative ? WRAPPED_NATIVE_CURRENCY[balance.currency.chainId] : balance.currency);

	return (
		<tr>
			{/*Token*/}
			<th scope="row">
				<Flex>
					<Logo token={{...Token, chainName: Token.chainId}} className="align-self-start"/>
					<span className="ml-2" style={{alignSelf: 'flex-end'}}>{balance.currency.symbol}</span>
				</Flex>
			</th>
			{/*Balance*/}
			<td>
				<Flex>
					<span className="text-monospace f-rem-0.9">{commaFormat(balanceAsStr, 5, '-')}</span>
				</Flex>
			</td>
			{/*Value (inDai)*/}
			<td>
				<Flex>
					<span className="text-monospace f-rem-0.9">{currencyFormat(valueInDai, '-')}</span>
				</Flex>
			</td>
		</tr>
	);
};

const CopyBalancesToClipboard = ({balances, className = ''}) => {
	const copyBalancesDataToClipboard = useCallback(() => {
		const date = dayjs().format('MM-DD-YY HH:MM:ss');

		const balancesText = balances.map(
			b => [
				capChainName(b.chainId),
				b?.balance?.currency?.symbol,
				b?.balanceAsStr,
				currencyFormat(b?.valueInDai),
				date,
			].join('\t')
		).join('\n');

		copy(balancesText, {
			debug: true,
			format: 'text/plain',
			onCopy: () => toast.success(`Balances copied`, { autoClose: 2000 })
		});
	}, [balances]);

	return (
		<span
			className={`shadow-sm text-secondary pointer border badge f-rem-0.8 ml-1.5 bg-white-hvr ${className}`.trim()}
			onClick={copyBalancesDataToClipboard}
		>
			<FontAwesomeIcon icon={faCopy} className="" />
		</span>
	);
};

const BalancesFilter = ({showSmallBalances, setShowSmallBalances}) => {
	return (
		<Toggle
			ops={['Show small']}
			active={showSmallBalances ? 'Show small' : ''}
			setActive={() => setShowSmallBalances(prev => !prev)}
		/>
	);
};
