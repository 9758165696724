import { useEffect, useRef } from "react";

const useOnBlur = fn => {
  const cb = useRef(fn);

  useEffect(() => {
    const onBlur = cb.current;
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, [cb]);
};

export default useOnBlur;
