import { configureStore } from '@reduxjs/toolkit';
import { positionsApiSlice, poolsApiSlice, analyzeApiSlice, arbitrageApiSlice, swapApiSlice, tokensApiSlice, walletApiSlice } from '../api/client';

const store = configureStore({
	reducer: {
		[positionsApiSlice.reducerPath]: positionsApiSlice.reducer,
		[poolsApiSlice.reducerPath]: poolsApiSlice.reducer,
		[analyzeApiSlice.reducerPath]: analyzeApiSlice.reducer,
		[arbitrageApiSlice.reducerPath]: arbitrageApiSlice.reducer,
		[swapApiSlice.reducerPath]: swapApiSlice.reducer,
		[tokensApiSlice.reducerPath]: tokensApiSlice.reducer,
		[walletApiSlice.reducerPath]: walletApiSlice.reducer,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(
			positionsApiSlice.middleware
		).concat(
			poolsApiSlice.middleware
		).concat(
			analyzeApiSlice.middleware
		).concat(
			arbitrageApiSlice.middleware
		).concat(
			swapApiSlice.middleware
		).concat(
			tokensApiSlice.middleware
		).concat(
			walletApiSlice.middleware
		)
});

export default store;

export const storeDispatch = store.dispatch;
