import React, { useState } from 'react';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus, faSquarePlus, faXmark, faArrowUpRightFromSquare, faClone } from '@fortawesome/pro-duotone-svg-icons';

import { chainExplorers, chainIdMap } from '../../../../constants/chains';
import * as dexes from '../../../../constants/dexes';
import { WRAPPED_NATIVE_CURRENCY } from '../../../../constants/tokens';
import {
  useGetPoolQuery,
  useGetTokensFromPairAddressQuery,
} from '../../../../api/client';
import { useLocation, Link } from '../../../../util/router';
import { currencyFormat, compactNumber } from '../../../../util/numberFormatter';
import { getTokens } from '../../../../util/tokens';

import Flex from '../../../../components/Flex';
import FullLoader from '../../../../components/FullLoader';
import FullError from '../../../../components/FullError';
import RefreshBtn from '../../../../components/RefreshBtn';
import Logo from '../../../../components/Logo';
import TradingViewWidget from '../../../../components/TradingViewWidget';
import TV_symbolMap from '../../../../components/TradingViewWidget/symbolMap';
import './Pool.scss';

const getPriceLabel = label => label.split(/([0-9]+)/).filter(o => o).reverse().join('');

const RenderChart = ({ symbol }) => {
  function getExchangePair(symbol) {
    const fromMap = TV_symbolMap[symbol];
    if (!fromMap) return ['', symbol];

    const [exchange, pair] = fromMap.split(':');
    const unwrappedSymbol = Object.values(WRAPPED_NATIVE_CURRENCY).some(o => o.symbol === symbol) ? symbol.slice(1) : symbol;
    return [exchange, pair.replace(unwrappedSymbol.toUpperCase(), `${unwrappedSymbol.toUpperCase()}/`)];
  }

  const [hideChart, setHideChart] = useState(false);
  const [ exchange, pair ] = getExchangePair(symbol);

  return (
    <>
      <Flex justify="between" align="center" className="TVChartHeader py-2.5 px-3">
        <h6 className="mb-0">
          <span className="text-gray-800">{pair}</span>
          <span className="text-gray-600 px-1">•</span>
          <span className="text-gray-600">{exchange}</span>
        </h6>
        <div
          style={{flexGrow: '1'}}
          className="text-right pointer hide-chart-container"
          onClick={() => setHideChart(!hideChart)}
        >
          <FontAwesomeIcon
            icon={hideChart ? faSquarePlus : faSquareMinus}
          />
        </div>
      </Flex>
      <div className={classnames('w-100', { 'd-none': hideChart })} style={{ height: '380px' }}>
        <TradingViewWidget symbol={symbol} />
      </div>
    </>
  );
};

const RenderedPoolHeader = ({ pool, Tokens, isFetching, refetch, previousPath }) => {
  const { chainId, dexId, baseToken, quoteToken, apr: aprRaw, feeRate } = pool;
  const Token0 = Tokens.find(o => o.symbol === baseToken.symbol) || {};
  const Token1 = Tokens.find(o => o.symbol === quoteToken.symbol) || {};

  const aprPretty = (apr) => {
    const floor = a => Math.floor(a * 10) / 10;
    const floored = String(floor(apr));

    if (!/\./.test(floored)) {
      if (floored.length > 2) return `${floored}%`;
      else if (floored.length === 2) return `${floored}.0%`;
      else if (floored.length < 2) return `${floored}.00%`;
    } else {
      return `${floored.padEnd(4, '0')}%`;
    }
  };

  const apr = aprRaw ? aprPretty(aprRaw) : '-';
  const fee = feeRate ? `${(feeRate*100).toFixed(2)}%` : '-';

  return (
    <Flex justify="between" align="start" className="PoolHeader px-3 py-2">
      <div>
        <Flex align="center">
          <Logo token={{src: `/img/dexes/${dexId}.png`, symbol: dexId}} className="isDex"/>
          <span className="dexId ml-2">{dexId}</span>
          {fee && (<span className="fee ml-2">{fee}</span>)}
          <span className="apr ml-2">{apr} APR</span>
        </Flex>
        <Flex align="center" className="mt-1">
          {Token0 && (
            <Logo token={{...Token0, chainName: chainId}}/>
          )}
          {Token1 && (
            <Logo token={{...Token1, chainName: chainId}} className="pullLeft"/>
          )}
          <span className="text-gray-800 font-weight-regular ml-2">{baseToken.symbol} / {quoteToken.symbol}</span>
        </Flex>
      </div>
      <Flex align="start">
        <RefreshBtn 
          refreshing={isFetching} 
          action={refetch} 
          style={{fontSize: '0.9rem', backgroundColor: 'white', paddingTop: '.3rem', paddingBottom: '.25rem'}}
        />
        <Link to={previousPath || '/monitor'}>
          <FontAwesomeIcon 
            icon={faXmark} 
            className="py-1 px-2 ml-1.5 shadow-sm rounded border f-rem-0.9 bg-white bg-white-hvr text-danger text-lighten-10 pointer"
          />
        </Link>
      </Flex>
    </Flex>
  );
}

const VolumeToggleRendered = ({volume, txns, priceChange}) => {
  const [txnsActiveTab, setTxnsActiveTab] = useState('m5');

  return (
    <Flex direction="column" className="txns-container mt-3.5">
      <Flex className="toggles">
        {Object.keys(priceChange).reverse().map((label, i) => (
          <span
            key={i}
            className={classnames('text-center', { active: txnsActiveTab === label })}
            onClick={() => setTxnsActiveTab(label)}
          >
            {getPriceLabel(label)}
          </span>
        ))}
      </Flex>
      <Flex className="values">
        <Flex direction="column" className="txns-tab">
          <span className="label text-uppercase">Txns</span>
          <span className="value">{compactNumber(txns[txnsActiveTab].buys + txns[txnsActiveTab].sells)}</span>
        </Flex>
        <Flex direction="column" className="txns-tab">
          <span className="label text-uppercase">Buys</span>
          <span className="value">{compactNumber(txns[txnsActiveTab].buys)}</span>
        </Flex>
        <Flex direction="column" className="txns-tab">
          <span className="label text-uppercase">Sells</span>
          <span className="value">{compactNumber(txns[txnsActiveTab].sells)}</span>
        </Flex>
        <Flex direction="column" className="txns-tab">
          <span className="label text-uppercase">Volume</span>
          <span className="value">${txnsActiveTab === 'm5' ? compactNumber(volume['m5'], '-') : compactNumber(volume[txnsActiveTab], '-')}</span>
        </Flex>
      </Flex>
    </Flex>
  );
}

export const RenderedPoolInfo = ({ pool, Tokens }) => {
  const { chainId, dexId, pairAddress, baseToken, quoteToken, priceUsd, txns, volume, priceChange, liquidity } = pool;
  const Token0 = Tokens.find(o => o.symbol === baseToken.symbol) || {};
  const Token1 = Tokens.find(o => o.symbol === quoteToken.symbol) || {};

  function openLink(link) {
    const win = window.open(link, '_blank');
    win.focus();
  }

  const priceChangeClass = value => classnames('value', {
    'text-gray-600': value === 0,
    'text-success-dark': value > 0,
    'text-danger-dark': value < 0,
  });

  return (
    <div className="PoolInfo">
      {/*Price and Liquidity*/}
      <Flex justify="between" className="price-info w-100">
        <Flex direction="column">
          <span className="label">Price</span>
          <span className="value">{currencyFormat(priceUsd)}</span>
        </Flex>
        <Flex direction="column" className="text-right">
          <span className="label">Liquidity</span>
          <span className="value">${compactNumber(liquidity.usd)}</span>
        </Flex>
      </Flex>
      {/*Price change*/}
      <Flex justify="between" className="price-change w-100 mt-3.5">
        {Object.entries(priceChange).reverse().map(([label, price], i, arr) => (
          <Flex
            key={i}
            direction="column"
            className={classnames({
              'text-center': i > 0 && i < arr.length - 1,
              'text-right': i === arr.length - 1
            })}
          >
            <span className="label text-uppercase">{getPriceLabel(label)}</span>
            <span className={priceChangeClass(price)}>{price}%</span>
          </Flex>
        ))}
      </Flex>
      {/*Volume*/}
      <VolumeToggleRendered
        volume={volume}
        txns={txns}
        priceChange={priceChange}
      />

      <hr className="my-3.5"/>
      {/*Pair info/addresses*/}
      <Flex direction="column">
        {[
          {name: 'pair', address: pairAddress},
          {name: Token0.symbol, address: Token0.address},
          {name: Token1.symbol, address: Token1.address},
        ].map(({ name, address }, i) => (
          <Flex
            key={i}
            justify="between"
            align="center"
            className={classnames('f-rem-0.9', {'mt-1.5': i > 0})}
          >
            <span className="text-truncate text-uppercase pr-5"><b className="text-gray-800">{name}:</b> {address}</span>
            <Flex align="center">
              <FontAwesomeIcon
                icon={faClone}
                className="px-1.5 py-1 f-rem-0.75 border rounded bg-gray-100-hvr pointer"
                onClick={() => copy(address, {
                  format: 'text/plain',
                  onCopy: () => toast.success(`Copied ${name} address`, { autoClose: 3000 })
                })}
              />
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className="px-1.5 py-1 f-rem-0.75 border rounded bg-gray-100-hvr pointer ml-1"
                onClick={() => openLink(`${chainExplorers[chainIdMap[chainId]]}/address/${address}`)}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>

      <hr className="my-3.5"/>

      <Flex direction="column" className="f-rem-0.95 text-gray-800">
        <div
          className="border rounded bg-white bg-gray-100-hvr-light text-center py-1 pointer shadow-sm"
          onClick={() => openLink(`${dexes[dexId][chainIdMap[chainId]]}?outputCurrency=${Token0.address}&chain=${chainId}`)}
        >
          Trade on <span className="text-capitalize">{dexId}</span> <FontAwesomeIcon className="f-rem-0.8"
                icon={faArrowUpRightFromSquare}/>
        </div>
      </Flex>
    </div>
  );
}

function Pool(props) {
	const { poolId, chainName } = props.match.params;
  const { pool: poolFromLocation = {}, from: previousPath } = useLocation().state || {};

  const {
    data: pool = poolFromLocation || {},
    isLoading,
    isFetching,
    isError,
    error,
    refetch
  } = useGetPoolQuery({ pairAddress: poolId, chainName });

  const { data: tokens = [] } = useGetTokensFromPairAddressQuery({
    chainId: chainIdMap[pool.chainId],
    pairAddress: pool.pairAddress,
  });

  const Tokens = tokens.length > 0 ? getTokens(...tokens) : [];
  const hasPool = !!Object.keys(pool || {})[0];

  return (
    !hasPool && isLoading ? (
    	<FullLoader text="pool" flat={true} />
    ) : (
    	<>
    		{isError && (<FullError
    			reason={error || 'An unknown error occurred'}
    			callback={refetch}
    			flat={hasPool}
    			customPadding={hasPool ? '3' : undefined}
    		/>)}

    		{hasPool && (
    			<Row className="Pool no-gutters">
    			  <Col xs={12} lg={7} xl={8}>
    			    <RenderChart symbol={pool.baseToken.symbol}/>
    			    <RenderChart symbol={pool.quoteToken.symbol}/>
    			  </Col>
    			  <Col xs={12} lg={5} xl={4} className="bg-white">
    			    <Flex direction="column" className="h-100">
    			      <RenderedPoolHeader
    			        pool={pool}
    			        Tokens={Tokens}
    			        isFetching={isFetching}
    			        refetch={refetch}
    			        previousPath={previousPath}
    			      />
    			      <RenderedPoolInfo
    			        pool={pool}
    			        Tokens={Tokens}
    			      />
    			    </Flex>
    			  </Col>
    			</Row>
    		)}
    	</>
    )
  );
}

export default Pool;
