import { ethers } from 'ethers';

function getBrowserExtensionProvider() {
  try {
    return new ethers.providers.Web3Provider(window?.ethereum, 'any');
  } catch (e) {
    console.log('No Wallet Extension Found');
    return null;
  }
}

export default getBrowserExtensionProvider;
