import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle, faSkullCrossbones } from '@fortawesome/pro-duotone-svg-icons';

const getColorFromRatio = ratio => {
	let color, icon = faDotCircle;

	switch (true) {
		case ratio >= 100:
		case ratio <= 0:
			color = 'danger-dark';
			icon = faSkullCrossbones;
			break;
		case ratio >= 90:
		case ratio <= 10:
			color = 'danger';
			break;
		case ratio > 80:
		case ratio < 20:
			color = 'warning-light';
			break;
		default:
			color = 'success';
			break;
	}

	return { color, icon };
}

function InRangeIcon(props) {
	const { color, icon } = getColorFromRatio(props.ratio);

	return (
		<FontAwesomeIcon icon={icon} className={`InRangeIcon text-${color}`} />
	);
}

export default InRangeIcon;
