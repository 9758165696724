import React from 'react';
import { CurrencyAmount } from '@uniswap/sdk-core';
import classnames from 'classnames';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

import { chainColors } from '../../../../constants/chains';

import { currencyFormat, fixedAsNumber } from '../../../../util/numberFormatter';
import { getTokens } from '../../../../util/tokens';

import Flex from '../../../../components/Flex';
import RefreshBtn from '../../../../components/RefreshBtn';
import Logo from '../../../../components/Logo';

import InRangeIcon from '../../components/InRangeIcon';
import RatioBadge from '../../components/RatioBadge';
import { getEarnedAprOfPos, getWeightedAvgApr, ChainToggle, ProgressBar } from '../Positions';
import './Mobile.scss';

const Totals = ({ positions, isFetching, refetch, totalValue, totalEarned, chainId }) => {
	const earnedPercent = fixedAsNumber(totalEarned/totalValue*100, 2);
	const earnedApr = getWeightedAvgApr(positions, totalEarned);
	const chainColor = chainColors[chainId];

	return (
		<Card className="shadow border-0 rounded p-3 Totals">
			<div className="chain-border-color" style={{backgroundColor: chainColor}}></div>
			<Flex justify="between">
				<Flex direction="column">
					<span className="label">Total</span>
					<span className="value">{currencyFormat(totalValue)}</span>
				</Flex>
				<RefreshBtn refreshing={isFetching} action={refetch} style={{fontSize: '0.9rem', alignSelf: 'start'}}/>
			</Flex>
			<Flex justify="between" className="mt-2">
				<Flex direction="column">
					<span className="label">Earned</span>
					<span className={classnames('value', {
						'text-success': totalEarned > 0
					})}>
						+{currencyFormat(totalEarned)}
						<span className="text-gray-600 font-weight-light f-rem-0.85 ml-2">({earnedPercent}%/total)</span>
					</span>
				</Flex>
				<Flex direction="column" className="text-right">
					<span className="label">APR</span>
					<span className="value text-gray-600">{earnedApr}%</span>
				</Flex>
			</Flex>
		</Card>
	);
}

const Position = ({ history, position: p, fiatToken, chainId }) => {
	const [Token0, Token1] = getTokens(p.pool.Token0, p.pool.Token1);
	const totalEarned = p.position.token0.earnedInDai + p.position.token1.earnedInDai;

	return (
		<Card
			pid={p.pid}
			className="shadow border-0 rounded mt-2.5 Position"
			onClick={() => history.push({
				pathname: `/uniswap/${p.pid}`,
				search: `?chainId=${chainId}`,
				state: { positions: [p] },
			})}
		>
			<Flex justify="between" className="p-3">
				<Flex direction="column">
		  		<Flex align="center" className="mb-1">
		  			<small className="align-self-start mr-2"><InRangeIcon ratio={p.position.token0.ratio}/></small>
		  			{[Token0, Token1].map((Token, idx) => (
		    			<Logo
		    				key={idx}
		    				token={{...Token, chainName: chainId}}
		    				className={classnames('align-self-start', {
		    					'pullLeft': idx > 0
		    				})}
		    			/>
		  			))}
		  			<span className="fw-5 mx-2">{p.pool.name}</span>
		  			<Badge className="border text-secondary">{p.pool.fee/10000}%</Badge>
		  		</Flex>
		  		<span className="text-monospace f-rem-0.9 fw-4">
		  			{currencyFormat(p.position.valueInDai)}
		  			<small className="text-gray-600 ml-2">{p.position.percentOfPool}%/tick</small>
		  		</span>
				</Flex>
				<Flex direction="column" className="text-right">
					<span className="f-rem-0.75 text-gray-600">{getEarnedAprOfPos(p)}% APR</span>
					<span className={classnames('text-monospace f-rem-0.9 mt-0.5', {'text-success': totalEarned > 0})}>
						+{currencyFormat(totalEarned)}
					</span>
				</Flex>
			</Flex>
			<Flex justify="between">
				{[
					[Token0, p.position.token0],
					[Token1, p.position.token1],
				].map(([Token, token] = [], idx) => (
					<Flex key={idx} direction="column" className="px-3 mb-2.5">
						<Flex direction={idx === 0 ? 'row' : 'row-reverse'}>
							<RatioBadge ratio={token.ratio} className="align-self-start" style={{marginTop: '1px'}}/>
							<Logo token={{...Token, chainName: chainId}} className={classnames('align-self-start', {
								'ml-2 mr-1': idx === 0,
								'ml-1 mr-2': idx > 0,
							})}/>
							<span style={{marginTop: '-1px'}}>{Token.symbol}</span>
						</Flex>
						<Flex
							align="center"
							direction={idx === 0 ? 'row' : 'row-reverse'}
							className={classnames('f-rem-0.9', { 'text-right': idx > 0 })}
						>
							<small className={classnames('text-gray-700 text-monospace', {
								'mr-1': idx === 0,
								'ml-1': idx > 0,
							})}>
								{currencyFormat(token.valueInDai)}
							</small>

							<small className="text-monospace text-gray-600">
								({currencyFormat(
									CurrencyAmount.fromRawAmount(fiatToken, token.priceInDai).toExact()
								)})
							</small>
						</Flex>
					</Flex>
				))}
			</Flex>
			<Flex justify="between" className="w-100">
				<ProgressBar ratio={p.position.token0.ratio} right={true} />
				<ProgressBar ratio={p.position.token1.ratio} right={false} />
			</Flex>
		</Card>
	);
}

function Mobile(props) {
	const { history, positions, isFetching, refetch, totalValue, totalEarned, fiatToken, chainId, setChainId } = props;

	return (
		<Flex direction="column" className="PositionsMobile">
			<ChainToggle
				chainId={chainId}
				setChainId={setChainId}
				history={history}
			/>
			<Totals
				positions={positions}
				isFetching={isFetching}
				refetch={refetch}
				totalValue={totalValue}
				totalEarned={totalEarned}
				chainId={chainId}
			/>

			{positions.map((p => (
				<Position
					key={p.pid}
					history={history}
					position={p}
					fiatToken={fiatToken}
					chainId={chainId}
				/>
			)))}
		</Flex>
	);
}

export default Mobile;
