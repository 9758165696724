import queryConstructor from '../../util/queryConstructor';
import { keepUnusedDataFor } from './constants';

export const handlers = (builder) => ({
	getPairAddress: builder.query({
		query: ({chainId, dex, token0Address, token1Address, feeRate}) => {
			const queryParams = queryConstructor({
				chainId, dex, token0Address, token1Address, feeRate
			});

			return `pairs/fetchPairAddress?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getTokensFromPairAddress: builder.query({
		query: ({chainId, pairAddress}) => {
			const queryParams = queryConstructor({
				chainId, pairAddress,
			});

			return `pairs/fetchTokensFromPairAddress?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getPools: builder.query({
		query: ({chainName}) => {
			const queryParams = queryConstructor({
				chainName
			});

			return `monitor/fetchPools?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getPool: builder.query({
		query: ({chainName, pairAddress}) => {
			const queryParams = queryConstructor({
				chainName, pairAddress
			});

			return `monitor/fetchPool?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getPoolFeeRate: builder.query({
		query: ({pairAddress, chainName, dexId}) => {
			const queryParams = queryConstructor({
				pairAddress, chainName, dexId
			});

			return `pairs/fetchFeeRate?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
	getDexesFromPairs: builder.query({
		query: ({chainName}) => {
			const queryParams = queryConstructor({
				chainName
			});

			return `monitor/fetchDexesFromPairs?${queryParams}`;
		},
		keepUnusedDataFor: keepUnusedDataFor,
	}),
});

export default handlers;
