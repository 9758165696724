import { TICK_SPACINGS, TickMath } from '@uniswap/v3-sdk';

export const FEE_RATES = [100,500,3000,10000];

export const DEFAULT_SURROUNDING_TICKS = {
  100: 180,
  500: 150,
  3000: 120,
  10000: 80,
};

export function generateSurroundingTicks(activeTickIdx, fee, numSurroundingTicks = DEFAULT_SURROUNDING_TICKS[fee]) {
  const tickSpacing = TICK_SPACINGS[fee];
  const tickIdxLowerBound = activeTickIdx - numSurroundingTicks * tickSpacing;
  const tickIdxUpperBound = activeTickIdx + numSurroundingTicks * tickSpacing;
  const ticks = [];

  for (let i = tickIdxLowerBound; i <= tickIdxUpperBound; i += tickSpacing) {
    ticks.push(i);
  }

  return ticks;
}

export function isValidTick(tick) {
  return tick !== undefined && tick !== null && tick !== false && tick <= TickMath.MAX_TICK && tick >= TickMath.MIN_TICK;
}

export function filterValidTickIdxs(ticks = [], key = 'tickIdx') {
  return ticks.filter(t => isValidTick(t[key]));
}
