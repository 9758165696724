import { ethers } from 'ethers';
import { CurrencyAmount } from '@uniswap/sdk-core';
import { NonfungiblePositionManager } from '@uniswap/v3-sdk';

import { defaultEncryptedWalletJSON } from '../../constants/wallets';
import genTxHashUrl from '../../constants/tx';
import genStaticGasPrice from '../../util/genStaticGasPrice';
import { getTokens } from '../../util/tokens';
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESS } from '../../constants/addresses';

import fetchPoolFeesEarned from './fetchPoolFeesEarned';
import fetchGasPrice from './fetchGasPrice';

const staticGasPrice = genStaticGasPrice('quick');

async function positionCollectFees(position, walletPublicAddress, walletPassword, provider) {
	const { pid, pool } = position;
	const [Token0, Token1] = getTokens(pool.Token0, pool.Token1);

	const [
		{ amount0, amount1 },
		currentGasPrice,
		transactionCount,
	] = await Promise.all([
		fetchPoolFeesEarned([position], walletPublicAddress, provider).then(res => res[pid]),
		fetchGasPrice(),
		provider.getTransactionCount(walletPublicAddress)
	]);

	console.log('transactionCount', transactionCount)

	const gasPrice = staticGasPrice.lt(currentGasPrice) ? currentGasPrice : staticGasPrice;

	const collectOptions = {
		tokenId: pid,
		expectedCurrencyOwed0: CurrencyAmount.fromRawAmount(Token0, amount0),
		expectedCurrencyOwed1: CurrencyAmount.fromRawAmount(Token1, amount1),
		recipient: walletPublicAddress,
	};

	const { calldata, value } = NonfungiblePositionManager.collectCallParameters(collectOptions);

	const wallet = (await ethers.Wallet.fromEncryptedJson(defaultEncryptedWalletJSON, walletPassword)).connect(provider);

	const tx = {
		from: wallet.address,
		to: NONFUNGIBLE_POSITION_MANAGER_ADDRESS,
		value: value,
		data: calldata,
		nonce: transactionCount,
		gasPrice: gasPrice,
		// maxFeePerGas: gasPrice,
		// maxPriorityFeePerGas: gasPrice,
	};

	return wallet.sendTransaction(tx).then(res => ({
		...res,
		hashUrl: genTxHashUrl(res.hash, res.chainId),
	})).catch(err => {
		if (err.message === 'already known' && err.transaction) {
			const { nonce } = err.transaction;

			return wallet.sendTransaction({ ...tx, nonce: nonce+1 }).then(res => ({
				...res,
				hashUrl: genTxHashUrl(res.hash, res.chainId),
			}));
		} else {
			throw err;
		}
	});
}

export default positionCollectFees;
