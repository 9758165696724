import { ethers } from 'ethers';
import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';

import Popup from '../Popup';
import Flex from '../Flex';
import { defaultWallet, highlineWallets } from '../../constants/wallets';

const styles = {
	button: {
		lineHeight: 1
	}
};

function SetWalletPopup(props) {
	const { walletPopupOpen, setWalletPopupOpen, wallet, setWallet } = props;
	const [error, setError] = useState();

	function handleChangeWallet(newWallet) {
		if (!newWallet) {
			setError('Please enter a value');
		} else if (newWallet) {
			if (newWallet.toLowerCase() === 'adam') {
				newWallet = defaultWallet;
			}

			if (!ethers.utils.isAddress(newWallet)) {
				setError('Not a valid EVM wallet address');
			} else {
				setError(null);
				localStorage.setItem('wallet.active', newWallet);
				setWallet(newWallet);
				setWalletPopupOpen(false);
				document.querySelector('body').classList.remove('noscroll');
			}
		}
	}

	return walletPopupOpen ? (
		<Popup
			open={walletPopupOpen}
			title="Set wallet"
			size="lg"
			containerClass="bg-gray-100"
			backBtnClass="bg-white"
			preventClose={!wallet}
			afterClose={() => setWalletPopupOpen(false)}
		>
			<Flex direction="column" justify="center" align="center" className="w-100 h-100 p-3">
				<span className="f-rem-0.9 mb-1">Set active wallet</span>
				<WalletInput
					wallet={wallet}
					setError={setError}
					handleChangeWallet={handleChangeWallet}
				/>
				{error && (
					<span className="text-danger f-rem-0.9 mt-1">{error}</span>
				)}

				{wallet && (
					<Flex direction="column" className="w-100 mt-4">
						<span className="mb-2 pb-1 border-bottom f-rem-0.9 fw-5 text-primary">Active Wallet</span>
						<span className="f-rem-0.9 text-primary">{wallet}</span>
					</Flex>
				)}

				<Flex direction="column" className="w-100 mt-4">
					<span className="mb-2 pb-1 border-bottom f-rem-0.9 fw-5 text-gray-600">Quick Wallets</span>
					{highlineWallets.map(({name, wallet: _wallet}, idx) => {
						const isActiveWallet = wallet?.toLowerCase?.() === _wallet.toLowerCase();

						return (
							<Flex
								key={idx}
								justify="between"
								wrap="wrap"
								className={classnames('w-100', {
									'mt-3': idx > 0
								})}
							>
								<Flex direction="column">
									<Flex justify="between" align="center" className="w-100">
										<span className={classnames({'fw-5': isActiveWallet})}>{name}</span>
										{isActiveWallet && (
											<FontAwesomeIcon icon={faCheck} className="text-success pl-2" />
										)}
									</Flex>
									<span
										className={classnames('f-rem-0.9', {
											'text-gray-700': !isActiveWallet,
											'text-primary': isActiveWallet
										})}
									>
										{_wallet}
									</span>
								</Flex>
								{!isActiveWallet && (
									<Button
										variant="primary"
										size="sm"
										className="shadow-sm ml-1 px-3 py-2 align-self-center"
										style={styles.button}
										onClick={() => handleChangeWallet(_wallet)}
									>
										SET
									</Button>
								)}
							</Flex>
						);
					})}
				</Flex>
			</Flex>
		</Popup>
	) : null;
};

export default SetWalletPopup;

const WalletInput = ({wallet = '', setError, handleChangeWallet}) => {
	const walletRef = useRef(wallet);

	return (
		<Flex className="w-100">
		<Form.Control
			ref={walletRef}
			className="Set wallet"
			type="text"
			name="setWallet"
			placeholder="Enter an EVM compatible wallet"
			onChange={() => setError(null)}
			onKeyPress={(e) => e.which === 13 && handleChangeWallet(walletRef.current.value)}
		/>
			<Button
				variant="primary"
				size="sm"
				className="shadow-sm ml-1 px-3"
				style={styles.button}
				onClick={() => handleChangeWallet(walletRef.current.value)}
			>
				SET
			</Button>
		</Flex>
	);
};
