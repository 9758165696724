import React, { useState } from 'react';
import classnames from 'classnames';
import Card from 'react-bootstrap/Card';

import { WRAPPED_NATIVE_CURRENCY } from '../../../../constants/tokens';
import TradingViewWidget from '../../../../components/TradingViewWidget';
import TV_symbolMap from '../../../../components/TradingViewWidget/symbolMap';
import HideCardTitle from '../../components/HideCardTitle';
import './TVChartCard.scss';

function getExchangePair(symbol) {
	const fromMap = TV_symbolMap[symbol];
	if (!fromMap) return ['', symbol];

	const [exchange, pair] = fromMap.split(':');
	const unwrappedSymbol = Object.values(WRAPPED_NATIVE_CURRENCY).some(o => o.symbol === symbol) ? symbol.slice(1) : symbol;
	return [exchange, pair.replace(unwrappedSymbol.toUpperCase(), `${unwrappedSymbol.toUpperCase()}/`)];
}

function TVChartCard(props) {
	const { Token, index } = props;
	const [hideChart, setHideChart] = useState(false);

	const [ exchange, pair ] = getExchangePair(Token.symbol);
	const Title = () => (
		<>
			<span className="text-gray-800">{pair}</span>
			<span className="text-gray-600 px-1">•</span>
			<span className="text-gray-600">{exchange}</span>
		</>
	);

	return (
		<Card className={`TVChartCard shadow border-0 rounded ${index > 0 && 'mt-3'}`}>
			<HideCardTitle
				title={<Title/>}
				hide={hideChart}
				setHide={setHideChart}
			/>
			<div
				className={classnames('chart-container', {
					'd-none': hideChart
				})}
			>
				<TradingViewWidget symbol={Token.symbol} />
			</div>
		</Card>
	);
}

export default TVChartCard;
