import { ethers } from "ethers";

const genStaticGasPrice = (which = 'fast') => {
	const units = "gwei";
	let price;

	switch(which) {
		case 'slow':
			price = ethers.utils.parseUnits("36", units);
			break;
		case 'reg':
			price = ethers.utils.parseUnits("50", units);
			break;
		case 'quick':
			price = ethers.utils.parseUnits("80", units);
			break;
		case 'fast':
			price = ethers.utils.parseUnits("120", units);
			break;
		default:
			price = ethers.utils.parseUnits("80", units);
			break;
	}

	return price;
};

export default genStaticGasPrice;
