import getBrowserExtensionProvider from 'util/getBrowserExtensionProvider';
import txNotification from 'util/txNotification';

export const TransactionState = {
  Failed: 'Failed',
  New: 'New',
  Rejected: 'Rejected',
  Sending: 'Sending',
  Sent: 'Sent',
};

// Transacting with a wallet extension via a Web3 Provider
export async function sendTransactionViaExtension(transaction) {
  const provider = getBrowserExtensionProvider();

  try {
    const txHash = await provider?.send(
      'eth_sendTransaction',
      [transaction]
    );

    if (txHash) {
      txNotification(txHash);
    }

    return {
      txState: txHash ? TransactionState.Sent : TransactionState.Failed,
      txHash,
    };
  } catch (e) {
    console.error('Err @ sendTransactionViaExtension()', e);
    return {
      txState: TransactionState.Rejected,
    }
  }
};
