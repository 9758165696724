const symbolMap = {
	CRV: 'BINANCE:CRVUSDT',
	DG: 'UNISWAP:DGWETH',
	LUNA: 'BINANCE:LUNAUSD',
	PYR: 'BINANCE:PYRUSDT',
	WETH: 'BINANCE:ETHUSDT',
	WMATIC: 'BINANCE:MATICUSD',
	DAI: 'BINANCEUS:DAIUSD',
	USDC: 'BINANCEUS:USDCUSD',
	USDT: 'COINBASE:USDTUSD',
	miMATIC: 'QUICKSWAP:MIMATICUSDT',
	FRAX: 'UNISWAP:FRAXUSDC',
	AAVE: 'BINANCE:AAVEUSDT',
	GRT: 'BINANCE:GRTUSDT',
	SUSHI: 'BINANCE:SUSHIUSDT',
	SOL: 'FTX:SOLUSD',
	SAND: 'BINANCE:SANDUSDT',
	SPHERE: 'QUICKSWAP:SPHEREWMATIC',
	CIV: 'UNISWAP:CIVWETH',
	WBTC: 'BINANCE:BTCUSDT',
	LINK: 'BINANCEUS:LINKUSD',
};

export default symbolMap;
