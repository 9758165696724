import React, { useState } from 'react';
import { ethers } from 'ethers';
import uniqBy from 'lodash/uniqBy';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSpinnerThird,
	faXmark,
	faCircleXmark,
	faCheck,
	faHourglassEmpty,
	faSquareArrowUpRight
} from '@fortawesome/pro-duotone-svg-icons';

import { getTokens } from 'util/tokens';
// import providers from 'constants/providers';
import { COMMON_TOKENS } from 'constants/tokens';
import positionCollectFees from 'api/positions/positionCollectFees';
import Popup from 'components/Popup';
import Flex from 'components/Flex';

const styles = {
	button: {
		lineHeight: 1
	},
	txIdx: {
		width: '20px',
	},
	removeCallIcon: {
		position: 'absolute',
		top: '-5px',
		right: '-5px',
		cursor: 'pointer'
	}
};

const BASE_TX_STATE = { status: false, sent: false, confirmed: false, error: false, txUrl: false };

async function runCollect({ provider, wallet, walletPassword, position, setTxState }) {
	function onErr(err) {
		console.error(err);
		Object.keys(err).forEach(key => console.log(key, err[key]));

		setTxState({
			...BASE_TX_STATE,
			error: err?.error?.message || err.message || err.reason,
		});
	}

	let newState = {
		...BASE_TX_STATE,
		status: 'sending',
	};

	setTxState(newState);
	return;

	const txResponse = await positionCollectFees(position, wallet, walletPassword, provider).catch(onErr);
	if (!txResponse) {
		return setTxState({...BASE_TX_STATE});
	};

	console.log('txResponse', txResponse);

	newState = {
		...newState,
		status: 'confirming',
		sent: true,
		txUrl: txResponse.hashUrl,
	};

	setTxState(newState);

	const txReceipt = await txResponse.wait(1).catch(onErr); //Wait for 1 confirmation
	if (!txReceipt) return;

	console.log('txReceipt', txReceipt);

	newState = {
		...newState,
		status: false,
		confirmed: true,
	};

	return setTxState(newState);
}

async function runSwap({ provider, wallet, walletPassword, position, params, setTxState }) {
	const { from, to } = params;
};

const CALLS = {
	collect: {
		callId: 'collect',
		title: 'Collect',
		method: runCollect,
		enabled: true,
	},
	swapToken0: {
		callId: 'swapToken0',
		title: 'Swap',
		method: runSwap,
		params: {
			from: null,
			to: null,
		},
		enabled: false,
		canRemove: true,
		component: ({...rest}) => <SetSwap key={rest.callId} {...rest} />,
	},
	swapToken1: {
		callId: 'swapToken1',
		title: 'Swap',
		method: runSwap,
		params: {
			from: null,
			to: null,
		},
		enabled: false,
		canRemove: true,
		component: ({...rest}) => <SetSwap key={rest.callId} {...rest} />,
	}
};

async function runCalls({ provider, wallet, position, callList, callStates, setCallStates }) {
	const walletPassword = window.prompt('Wallet password?');
	if (!walletPassword) return;

	for (let i = 0; i < callList.length; i++) {
		const { callId, params = {}, method } = callList[i];
		const txState = callStates[callId];

		const setTxState = (newState) => {
			setCallStates(prev => ({
				...prev,
				[callId]: newState,
			}));
		}

		await method({ provider, wallet, walletPassword, position, params, setTxState });
	}
}

const StatusIcon = ({ status, complete, error }) => (
	error ? (
		<FontAwesomeIcon icon={faXmark} className="text-danger"/>
	) : status ? (
		<FontAwesomeIcon icon={faSpinnerThird} spin={true} className="text-gray-800"/>
	) : complete ? (
		<FontAwesomeIcon icon={faCheck} className="text-success"/>
	) : (
		<FontAwesomeIcon icon={faHourglassEmpty} className="text-gray-600"/>
	)
);

const TxItem = ({ idx, callId, title, canRemove, handleRemoveCall, txState }) => {
	const loading = !!txState.status;

	return (
		<div className="bg-white w-100 mb-2.5 p-2 rounded shadow-sm position-relative">
			{!loading && canRemove && (
				<FontAwesomeIcon
					icon={faCircleXmark}
					className="f-rem-0.8 text-danger"
					style={styles.removeCallIcon}
					onClick={() => handleRemoveCall(callId)}
				/>
			)}

			<Flex align="center" className="w-100">
				<span className="text-center f-rem-0.8 border-right pr-1.5" style={styles.txIdx}>{idx+1}</span>
				<div className="pl-2.5">
					<span>{title}</span>
					{txState.txUrl && (
						<a
							href={txState.txUrl}
							target="_blank"
							rel="noreferrer"
							className="text-gray-600 f-rem-0.8 pl-2"
						>
							TX <FontAwesomeIcon icon={faSquareArrowUpRight} style={{paddingLeft: '2px'}}/>
						</a>
					)}
				</div>
				<span className="ml-auto f-rem-0.8 pr-1">
					<StatusIcon status={txState.status} complete={txState.confirmed} error={txState.error}/>
				</span>
			</Flex>

			{(loading || txState.confirmed) && (
				<Flex direction="column" className="w-100 border-top pt-2 mt-2.5 f-rem-0.75 text-gray-600">
					{/* Send status */}
					<Flex justify="between" align="center">
						<span>{txState.status === 'sending' ? 'Sending' : !txState.sent ? 'Send' : 'Sent'}</span>
						<span className="pr-1">
							<StatusIcon status={txState.status === 'sending'} complete={txState.sent}/>
						</span>
					</Flex>
					{/* Confirmed status */}
					<Flex justify="between" align="center" className="mt-1.5">
						<span>{txState.status === 'confirming' ? 'Confirming' : !txState.confirmed ? 'Confirm' : 'Confirmed'}</span>
						<span className="pr-1">
							<StatusIcon status={txState.status === 'confirming'} complete={txState.confirmed}/>
						</span>
					</Flex>
				</Flex>
			)}
		</div>
	);
}

const SetSwap = ({ callId, params, position, handleAddCall, handleRemoveCall }) => {
	const [Token, OtherPoolToken] = getTokens(
		position.pool[callId === 'swapToken0' ? 'Token0' : 'Token1'],
		position.pool[callId === 'swapToken0' ? 'Token1' : 'Token0']
	);
	const title = `Swap ${Token.symbol}${params.to ? ` to ${params.to.symbol}` : ''}`.trim();
	const tokensList = uniqBy(
		[
			...COMMON_TOKENS[Token.chainId].filter(t => t.address.toLowerCase() !== Token.address.toLowerCase()),
			OtherPoolToken,
		],
		t => t.address.toLowerCase()
	);

	function setSelectedToken(t) {
		const title = `Swap ${Token.symbol} to ${t.symbol}`;
		return handleAddCall(callId, title, {...params, to: t});
	}

	return (
		<Flex
			justify="between"
			align="center"
			className="border bg-gray-100 w-100 mb-2.5 py-2 px-2.5 rounded shadow-sm"
		>
			<span className="f-rem-0.95">{title}</span>
			<Dropdown>
				<Dropdown.Toggle size="sm" variant="none" className="py-0 f-rem-0.85">
					{params?.to?.symbol || "Select token"}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{tokensList.map(t => (
						<Dropdown.Item
							key={t.symbol}
							onClick={() => setSelectedToken(t)}
						>
							{t.symbol}
						</Dropdown.Item>
					))}
				</Dropdown.Menu>
			</Dropdown>
		</Flex>
	);
}

function CollectPopup({ chainId, wallet, position, afterClose }) {
	// const provider = new ethers.providers.JsonRpcProvider(providers[chainId]);
	const provider = null;
	const [callList, setCallList] = useState(Object.values(CALLS));
	const [callStates, setCallStates] = useState(Object.keys(CALLS).reduce((acc, key) => {
		acc[key] = {...BASE_TX_STATE};
		return acc;
	}, {}));

	const anyTxLoading = Object.values(callStates).some(c => !!c.status);
	const anyTxError = Object.values(callStates).find(c => !!c.error)?.error;

	const [Token0, Token1] = getTokens(position.pool.Token0, position.pool.Token1);

	function handleAddCall(callId, title, params) {
		setCallList(prev => prev.map(c => ({
			...c,
			...c.callId === callId ? {
				title,
				params,
				enabled: true,
			} : {},
		})));
	}

	function handleRemoveCall(callId) {
		setCallList(prev => prev.map(c => ({
			...c,
			...c.callId === callId ? {
				enabled: false
			} : {},
		})));
	}

	return (
		<Popup
			open={true}
			title="Collect fees"
			loading={anyTxLoading}
			size="lg"
			containerClass="bg-gray-100"
			backBtnClass="bg-white"
			afterClose={afterClose}
			preventClose={anyTxLoading}
		>
			<Flex direction="column" justify="center" align="center" className="w-100 h-100 p-3">
				{/* Title/Run btn */}
				<Flex justify="between" align="end" className="mb-2 w-100">
					<span className="f-rem-0.9">TX List</span>
					<Button
						variant="outline-primary"
						size="sm"
						className="shadow-sm"
						style={styles.button}
						disabled={anyTxLoading}
						onClick={() => !anyTxLoading && runCalls({ provider, wallet, position, callList, callStates, setCallStates })}
					>
						{anyTxLoading ? (
							<>
								<FontAwesomeIcon icon={faSpinnerThird} spin={true} className="f-rem-0.8" /> Running
							</>
						) : (
							"Run"
						)}
					</Button>
				</Flex>
				{/* Transaction List */}
				<Flex direction="column" className="w-100">
					{callList.filter(c => c.enabled).map((c, i) => (
						<TxItem
							key={c.callId}
							idx={i}
							callId={c.callId}
							title={c.title}
							canRemove={c.canRemove}
							handleRemoveCall={handleRemoveCall}
							txState={callStates[c.callId]}
						/>
					))}

					{callList.filter(c => !c.enabled).map((c, i) => (
						c.component({
							...c,
							position: position,
							handleAddCall: handleAddCall,
							handleRemoveCall: handleRemoveCall,
						})
					))}
				</Flex>

				{anyTxError && (
					<Flex direction="column" className="w-100 text-center px-2 mt-3">
						<span className="text-danger-dark mb-1">Error</span>
						<span className="text-gray-700">{anyTxError}</span>
					</Flex>
				)}
			</Flex>
		</Popup>
	)
}

export default CollectPopup;
