import React, { useState, useMemo } from 'react';
import { CurrencyAmount } from '@uniswap/sdk-core';
import classnames from 'classnames';
import sum from 'lodash/sum';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/pro-light-svg-icons';
import { faCopy } from '@fortawesome/pro-duotone-svg-icons';

import { useGetPositionsQuery } from 'api/client';
import supportedChainId, { chainNameMap, chainIdMap, chainColors } from 'constants/chains';
import { DAI } from 'constants/tokens';
import { walletNamesMap } from 'constants/wallets';

import { useRouter, useHistory } from 'util/router';
import { commaFormat, currencyFormat, fixedAsNumber } from 'util/numberFormatter';
import { getTokens } from 'util/tokens';
import { truncateWallet } from 'util/wallets';

import useIsMobile from 'hooks/useIsMobile';

import Flex from 'components/Flex';
import FullLoader from 'components/FullLoader';
import FullError from 'components/FullError';
import RefreshBtn from 'components/RefreshBtn';
import SortIcon from 'components/SortIcon';
import Logo from 'components/Logo';
import Toggle from 'components/Toggle';

import InRangeIcon from 'pages/uniswap/components/InRangeIcon';
import RatioBadge, { getColorFromRatio } from 'pages/uniswap/components/RatioBadge';
import './Positions.scss';
import Mobile from './Mobile';

const DEFAULT_CHAIN_ID = supportedChainId.POLYGON;
const chainsForSelect = Object.entries(chainNameMap)
	.filter(
		([key, val]) => ['optimism', 'polygon', 'arbitrum', 'binance'].some(c => c === val)
	).sort(
		(a, b) => chainIdMap[a] - chainIdMap[b]
	).reduce(
		(acc, [key, val]) => {
			acc[key] = val;
			return acc;
		}, {}
	);
const SECONDS_IN_DAY = 24 * 60 * 60;

function positionsSort(positions = [], sort) {
	const [col, dir] = sort;

	return [...positions].sort((a, b) => {
		let sortVal;
		const [aToken0, aToken1] = getTokens(a.pool.Token0, a.pool.Token1);
		const [bToken0, bToken1] = getTokens(b.pool.Token0, b.pool.Token1);

		switch (col) {
			case 'pool':
				sortVal = dir === 'asc' ? a.pool.name.localeCompare(b.pool.name) : b.pool.name.localeCompare(a.pool.name);
				break;
			case 'token0':
				sortVal = dir === 'asc' ? aToken0.symbol.localeCompare(bToken0.symbol) : bToken0.symbol.localeCompare(aToken0.symbol);
				break;
			case 'token1':
				sortVal = dir === 'asc' ? aToken1.symbol.localeCompare(bToken1.symbol) : bToken1.symbol.localeCompare(aToken1.symbol);
				break;
			case 'earned':
				const aEarned = a.position.token0.earnedInDai + a.position.token1.earnedInDai;
				const bEarned = b.position.token0.earnedInDai + b.position.token1.earnedInDai;
				sortVal = dir === 'asc' ? aEarned - bEarned : bEarned - aEarned;
				break;
			default:
				sortVal = a.pid - b.pid;
				break;
		}

		return sortVal;
	});
};

function getEarnedPercentageOfPos(p) {
	const positionVal = p.position.valueInDai;
	const earnedVal = p.position.token0.earnedInDai + p.position.token1.earnedInDai;
	if (!earnedVal || earnedVal === 0) return 0;

	return fixedAsNumber(earnedVal/positionVal*100, 2);
};

export function getEarnedAprOfPos(p, raw = false) {
	const { timeStamp: latestEvtTS } = p.position.latestEvt || {};
	const positionVal = p.position.valueInDai;
	const earnedVal = p.position.token0.earnedInDai + p.position.token1.earnedInDai;
	if (!earnedVal || earnedVal === 0) return 0;

	if (!latestEvtTS) {
		const apr = earnedVal/positionVal*100*360;
		return raw ? apr : commaFormat(fixedAsNumber(apr, 2));
	}
	else {
		const now = new Date();
		const utcMilllisecondsSinceEpoch = now.getTime() + (now.getTimezoneOffset() * 60 * 1000);
		const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000);

		const secondsSinceEvt = utcSecondsSinceEpoch - latestEvtTS;
		const multpleOfDay = SECONDS_IN_DAY / secondsSinceEvt;
		const apr = earnedVal/positionVal*100*360*multpleOfDay;

		return raw ? apr : commaFormat(fixedAsNumber(apr, 2));
	}
};

export function getWeightedAvgApr(positions = [], totalEarned) {
	const weighted = positions.reduce((acc, p) => {
		const earned = p.position.token0.earnedInDai + p.position.token1.earnedInDai;
		const apr = getEarnedAprOfPos(p, true);
		const weightedApr = apr*(earned/totalEarned);

		acc += weightedApr;
		return acc;
	}, 0);

	if (!weighted || weighted === 0 || isNaN(weighted)) return '0';

	return commaFormat(fixedAsNumber(weighted, 2));
};

function Positions(props) {
	const { wallet } = props;

	const router = useRouter();
	const history = useHistory();

	if (!router.query.chainId) {
		const search = new URLSearchParams({ chainId: DEFAULT_CHAIN_ID }).toString();
		history.replace({ search });
	}

	const [chainId, setChainId] = useState(Number(router.query?.chainId || DEFAULT_CHAIN_ID));
	const [sort, setSort] = useState([]);
	const fiatToken = DAI[chainId];
	const isMobile = useIsMobile();

	const {
    data: positions = [],
    isLoading,
    isFetching,
    isError,
    error,
    refetch
  } = useGetPositionsQuery({ chainId, wallet });

	const [totalValue, totalEarned] = useMemo(() => ([
		sum(positions.map(p => p.position.valueInDai)),
		sum(positions.flatMap(p => [p.position.token0.earnedInDai, p.position.token1.earnedInDai])),
	]), [positions]);

	const sortedPositions = positionsSort(positions, sort);
	console.log(positions);

	const walletName = wallet && walletNamesMap[wallet.toLowerCase()];

	return (
		positions.length < 1 && isLoading ? (
			<Container className="Positions py-4 py-lg-5">
				<ChainToggle
					chainId={chainId}
					setChainId={setChainId}
					history={history}
				/>
				<FullLoader
					text="positions"
					classNameOverrides="p-0"
				/>
			</Container>
		) : (
			<>
				{isError && (
					<Container className="Positions py-4 py-lg-5">
						<ChainToggle
							chainId={chainId}
							setChainId={setChainId}
							history={history}
						/>
						<FullError
							reason={error?.data?.reason || error?.reason || error || 'An unknown error occurred'}
							callback={refetch}
							callbackIsLoading={isFetching}
							hasItemsBelow={positions.length > 0}
							customPadding={0}
							className="px-0"
						/>
					</Container>
				)}

				{positions.length > 0 && !isError ? isMobile ? (
					<Mobile
						router={router}
						history={history}
						positions={sortedPositions}
						isFetching={isFetching}
						refetch={refetch}
						totalValue={totalValue}
						totalEarned={totalEarned}
						fiatToken={fiatToken}
						chainId={chainId}
						setChainId={setChainId}
					/>
				) : (
					<Container className="Positions py-4 py-lg-5">
						<ChainToggle
							chainId={chainId}
							setChainId={setChainId}
							history={history}
						/>
						<Card className="shadow border-0 rounded">
							<div className="chain-border-color" style={{backgroundColor: chainColors[chainId]}}></div>
							<Card.Body className="p-0">
								<Flex wrap="wrap" justify="between" align="center" className="py-3 px-4">
									<h5 className="mb-0 d-flex align-items-center">
										<span className="mr-2">Uniswap Pools</span>
										<RefreshBtn
											refreshing={isFetching}
											action={refetch}
											style={{fontSize: '0.9rem'}}
										/>
										<CopyPositionsToClipboardBtn positions={positions} />
									</h5>
									<h6 className="text-gray-700 mb-0 text-monospace text-right">{currencyFormat(totalValue)}</h6>
								</Flex>
								<div className="w-100" style={{ overflowX: 'scroll' }}>
									<Table className="table-stripe">
										<RenderedHeaders
											positions={sortedPositions}
											sort={sort}
											setSort={setSort}
											totalValue={totalValue}
											totalEarned={totalEarned}
										/>
										<tbody>
											{sortedPositions.length > 0 ? (
												sortedPositions.map(p => (
													<RenderPositionRow
														key={p.pid}
														chainId={chainId}
														position={p}
														history={history}
														fiatToken={fiatToken}
													/>
												))
											) : (
									  		<tr>
										  		<td colSpan="3">No positions found</td>
										  	</tr>
											)}
										</tbody>
									</Table>
								</div>
							</Card.Body>
						</Card>
					</Container>
				) : !isError ? (
					<>
						<Container style={{zIndex: 1}}>
							<ChainToggle
								chainId={chainId}
								setChainId={setChainId}
								history={history}
								className="pt-3.5 pb-2.5 mb-n5"
							/>
						</Container>
						<FullError
							reason={'No positions found'}
							callback={refetch}
							callbackIsLoading={isFetching}
							hasItemsBelow={false}
						/>
					</>
				) : null}

				<div className="w-100 text-center mx-auto py-2 py-lg-0">
					<span className="f-rem-0.8 text-gray-600">Wallet {truncateWallet(wallet)} | {walletName}</span>
				</div>
			</>
		)
	);
};

export default Positions;

export const ChainToggle = ({chainId, setChainId, history, className = ''}) => {
	return (
		<Flex className={className || 'mb-2.5'}>
			<Toggle
				className="shadow-sm"
				ops={Object.values(chainsForSelect)}
				isOpActive={(active, chainName) => chainNameMap[chainId] === chainName}
				setActive={(chainName) => {
					const id = supportedChainId[chainName.toUpperCase()];
					setChainId(id);

					const search = new URLSearchParams({ chainId: id }).toString();
					history.replace({ search });
				}}
			/>
		</Flex>
	);
};

const CopyPositionsToClipboardBtn = ({positions}) => {
	function copyPositionDataToClipboard() {
		const date = dayjs().format('MM-DD-YY HH:MM:ss');

		const positionsText = positions.map(p => {
			const [Token0, Token1] = getTokens(p.pool.Token0, p.pool.Token1);
			const feeRate = p.pool.fee/10000;
			const feesEarned = currencyFormat(p.position.token0.earnedInDai + p.position.token1.earnedInDai);
			const positionValue = currencyFormat(p.position.valueInDai);
			const chainName = chainNameMap[Token0.chainId] || 'Unknown';

			return [
				`${chainName.charAt(0).toUpperCase()}${chainName.slice(1)}`,
				'Uniswap V3',
				Token0.symbol,
				Token1.symbol,
				feeRate === 1 ? `${feeRate}.00%` : `${feeRate}%`,
				positionValue,
				feesEarned,
				date
			].join('\t');
		}).join('\n');

		copy(positionsText, {
			debug: true,
			format: 'text/plain',
			onCopy: () => toast.success(`Copied positions`, { autoClose: 3000 })
		});
	}

	return (
		<span
			className="shadow-sm text-secondary pointer border badge f-rem-0.8 ml-1.5 bg-white-hvr"
			onClick={copyPositionDataToClipboard}
		>
			<FontAwesomeIcon icon={faCopy} className="" />
		</span>
	);
};

const RenderedHeaders = ({ positions = [], sort, setSort, totalValue, totalEarned }) => {
	function toggleSortState(e) {
		const col = e.currentTarget.getAttribute('colname');
		const newState = sort[0] !== col ? 'desc' : sort[1] === 'desc' ? 'asc' : false;
		setSort(!newState ? [] : [col, newState]);
	}

	const earnedPercent = fixedAsNumber(totalEarned/totalValue*100, 2);
	const earnedApr = getWeightedAvgApr(positions, totalEarned);

	return (
		<thead>
		  <tr>
		    <th scope="col" colname="pool" className="hasSort" onClick={toggleSortState}>
		    	<span className="mr-2">Pool</span>
		    	<SortIcon direction={sort[0] === 'pool' && sort[1]}/>
		    </th>
		    <th scope="col" colname="token0" className="hasSort" onClick={toggleSortState}>
		    	<span className="mr-2">Token0</span>
		    	<SortIcon direction={sort[0] === 'token0' && sort[1]}/>
		    </th>
		    <th scope="col" colname="token1" className="hasSort" onClick={toggleSortState}>
		    	<span className="mr-2">Token1</span>
		    	<SortIcon direction={sort[0] === 'token1' && sort[1]}/>
		    </th>
		    <th scope="col" colname="earned" className="hasSort" onClick={toggleSortState}>
		    	<Flex justify="between" align="center">
		    		<span>
		    			<span className="mr-2">Earned</span>
		    			<SortIcon direction={sort[0] === 'earned' && sort[1]}/>
		    		</span>
		    		<span className="text-gray-700 text-monospace pl-4">
		    			<span>{currencyFormat(totalEarned)}</span>
		    			<span className="ml-2 text-gray-600 f-rem-0.6">({earnedPercent}%/total)</span>
		    			<span className="ml-2">{earnedApr}% apr</span>
		    		</span>
		    	</Flex>
		    </th>
		  </tr>
		</thead>
	);
};

export const ProgressBar = ({ ratio, right }) => {
	const [widthDelay, setWidthDelay] = useState(0);
	const color = getColorFromRatio(ratio);

	if (widthDelay !== ratio) {
		setTimeout(() => {
			setWidthDelay(ratio);
		}, 500);
	}

	return (
		<div className={classnames('progress-bar', { right: right })}>
			<div
				className={`progress-bar-inner bg-${color}`}
				style={{width: `${widthDelay}%`}}
			></div>
		</div>
	);
};

const RenderPositionRow = ({ chainId, position: p, history, fiatToken }) => {
	const [Token0, Token1] = getTokens(p.pool.Token0, p.pool.Token1);

	return (
    <tr
    	pid={p.pid}
    	onClick={() => history.push({
    		pathname: `/uniswap/${p.pid}`,
    		search: `?chainId=${chainId}`,
    		state: { positions: [p] },
    	})}
    >
    	{/*Pool info*/}
      <th scope="row">
      	<Flex align="start">
      		<small style={{marginTop: '2px'}}><InRangeIcon ratio={p.position.token0.ratio}/></small>
      		<Flex direction="column" className="ml-2.5">
	      		<Flex align="center" className="mb-1">
  						{[Token0, Token1].map((Token, idx) => (
  			  			<Logo
  			  				key={idx}
  			  				token={{...Token, chainName: chainId}}
  			  				className={classnames('align-self-start', {
  			  					'pullLeft': idx > 0
  			  				})}
  			  			/>
  						))}
	      			<span className="fw-5 mx-2">{p.pool.name}</span>
	      			<Badge className="border text-secondary">{p.pool.fee/10000}%</Badge>
	      		</Flex>
	      		<span className="text-monospace f-rem-0.9 fw-4">{currencyFormat(p.position.valueInDai)}</span>
	      		<small className="text-secondary">{p.position.percentOfPool}%/tick</small>
	      	</Flex>
      	</Flex>
      </th>
	    {/*Token0, Token1*/}
	    {[
	    	[Token0, p.position.token0],
	    	[Token1, p.position.token1],
	    ].map(([Token, token] = [], idx) => (
	    	<td key={idx}>
	    		<ProgressBar ratio={token.ratio} right={idx === 0} />
	    		<Flex justify="between" align="start">
	    			<Flex direction="column">
	    				<Flex>
	    					<Logo token={{...Token, chainName: chainId}} className="align-self-start"/>
	    					<span className="ml-2" style={{alignSelf: 'flex-end'}}>{Token.symbol}</span>
	    				</Flex>
	    				<span className="text-monospace f-rem-0.9 mt-1">{currencyFormat(token.valueInDai)}</span>
		    			<Flex align="center" className="text-secondary">
		    				<small>{commaFormat(token.deposit)}</small>
		    				<span className="f-rem-0.7 px-1" style={{paddingTop: '2px'}}><FontAwesomeIcon icon={faAt} /></span>
		    				<small className="text-monospace">
		    					{currencyFormat(
		    						CurrencyAmount.fromRawAmount(fiatToken, token.priceInDai).toExact()
		    					)}
		    				</small>
		    			</Flex>
	    			</Flex>
	    			<RatioBadge ratio={token.ratio} className="ml-3" style={{marginTop: '1px'}}/>
	    		</Flex>
	    	</td>
	    ))}
	    {/*Earned*/}
	    <td>
	    	<Flex direction="column">
	    		<Flex justify="between">
	    			<span className="text-monospace f-rem-0.9">{currencyFormat(p.position.token0.earnedInDai + p.position.token1.earnedInDai)}</span>
	    			<div className="pl-4 pl-lg-0 text-monospace text-right">
	    				<small className="text-muted mr-2">({getEarnedPercentageOfPos(p)}%/pos)</small>
	    				<span className="text-gray-700">{getEarnedAprOfPos(p)}% APR</span>
	    			</div>
	    		</Flex>
	    		{[
	    			[Token0, p.position.token0],
	    			[Token1, p.position.token1],
	    		].map(([Token, token] = [], key) => (
		    		<Flex
		    			key={key}
		    			justify="between"
		    			align="center"
		    			className={`text-secondary ${key > 0 && 'mt-1'}`}
		    		>
		    			<span className="pr-2 text-truncate" style={{width: '76px'}}>
		    				{Token.symbol}
		    			</span>
		    			<small className="px-1 text-gray-700 text-monospace text-left text-truncate" style={{width: '58px'}}>
		    				{currencyFormat(token.earnedInDai)}
		    			</small>
		    			<small className="pr-2 text-center text-truncate" style={{width: '38px'}}>
		    				{commaFormat(token.earned)}
		    			</small>
		    			<small className="pl-2 text-right text-truncate" style={{width: '38px'}}>
		    				{token.earnedRatio}%
		    			</small>
		    		</Flex>
	    		))}
	    	</Flex>
	    </td>
    </tr>
	);
};
