import React from "react";
import ReactDom from "react-dom";
import App from "./pages/_app";
import store from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from "./serviceWorker";
// import './startup';

async function start() {
	ReactDom.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById("root")
	);
}

start();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if (isDev) {
// 	serviceWorker.register();
// } else {
// 	serviceWorker.unregister();
// }
serviceWorker.unregister();
