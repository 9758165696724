import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { tickToPrice } from '@uniswap/v3-sdk';
import Flex from 'components/Flex';
import Toggle from 'components/Toggle';
import { commaFormat, fixedAsNumber } from 'util/numberFormatter';
import { getTokens } from 'util/tokens';
import { getColorFromRatio } from 'pages/uniswap/components/RatioBadge';
import './MyPositionInfo.scss';

function MyPositionInfo(props) {
	const { pool, ticks = [], hide, reverseChart, setReverseChart, mini } = props;
	const [priceDir, setPriceDir] = useState(reverseChart ? '1' : '0');
	const tokens = getTokens(pool.Token0, pool.Token1);
	const tokensSorted = priceDir === '0' ? [...tokens] : [...tokens].reverse();

	//Includes one extra tick at end that position is technically NOT in since that's the price of that extra tick is the 'max' price for the position
	const myTicks = ticks.filter((t, i) => t.isTickInPosition || ticks[i-1]?.isTickInPosition);
	const tickEnds = [myTicks[0], myTicks[myTicks.length - 1]].filter(o => !!o);
	const tickEndsSorted = priceDir === '0' ? tickEnds : tickEnds.reverse();

	return (
		<Flex
			direction={!mini ? 'column' : 'row'}
			className={classnames('MyPositionInfo py-2.5 px-3', { 'd-none': hide })}
		>
			<PriceInfoRendered
				pool={pool}
				tokens={tokens}
				tokensSorted={tokensSorted}
				tickEndsSorted={tickEndsSorted}
				priceDir={priceDir}
				setPriceDir={setPriceDir}
				setReverseChart={setReverseChart}
				myTicks={myTicks}
				mini={mini}
			/>
			{myTicks.length > 0 && (
				<PositionPercentages
					pool={pool}
					tickEndsSorted={tickEndsSorted}
					myTicks={myTicks}
					mini={mini}
				/>
			)}
		</Flex>
	);
};

export default MyPositionInfo;

const PriceInfoRendered = ({pool, tokens, tokensSorted, tickEndsSorted, priceDir, setPriceDir, setReverseChart, myTicks, mini}) => (
	<Flex direction="column" className={classnames('w-100', { 'mr-3': mini && myTicks.length > 0 })}>
		<Flex justify="between" align="end" className="w-100 mb-2.5">
			<span>Position price range</span>
			<Toggle
				active={priceDir}
				setActive={() => {
					setPriceDir(prev => prev === '0' ? '1' : '0');
					typeof setReverseChart === 'function' && setReverseChart(prev => !prev);
				}}
				ops={[tokens?.[0]?.symbol, tokens?.[1]?.symbol]}
				isOpActive={(active, op) => tokensSorted?.[0]?.symbol === op}
				className="f-rem-0.75 fw-5"
			/>
		</Flex>
		<Flex justify="between" align="center" className="w-100">
			<PriceContainer idx={0} tick={tickEndsSorted[0]} priceDir={priceDir} Tokens={tokensSorted} />
			<TicksInBetween pool={pool} myTicks={myTicks} tickEnds={tickEndsSorted} priceDir={priceDir} Tokens={tokensSorted} mini={mini} />
			<PriceContainer idx={1} tick={tickEndsSorted[1]} priceDir={priceDir} Tokens={tokensSorted} />
		</Flex>
	</Flex>
);

const PriceContainer = ({ idx, tick, priceDir, Tokens }) => tick ? (
	<Flex direction="column" className="PriceContainer border rounded p-2 text-center w-25">
		<span className="f-rem-0.7 fw-5 text-gray-600 text-wrap">{idx === 0 ? "Min" : "Max"} price</span>
		<span className="f-rem-0.9 fw-5 text-gray-700 text-wrap">{commaFormat(tick?.[`price${priceDir}`], 5)}</span>
		<span className="f-rem-0.7 fw-5 text-gray-600 text-wrap">{Tokens?.[1]?.symbol} per {Tokens?.[0]?.symbol}</span>
	</Flex>
) : null;

const TicksInBetween = ({ pool, myTicks, tickEnds, priceDir, Tokens, mini }) => {
	const inBetweenTicks = myTicks.slice(1,-1);
	const curPrice = pool[`token${priceDir}Price`];
	const curPricePerc = (Number(curPrice) - tickEnds[0]?.[`price${priceDir}`]) / (tickEnds[1]?.[`price${priceDir}`] - tickEnds[0]?.[`price${priceDir}`] || 1) * 100;

	return (
		<Flex direction="column" justify="between" align="center" className="TicksInBetween">
			<span className="price f-rem-0.8 text-gray-700 text-center text-wrap">Price: <span className="fw-5">{curPrice}</span> {Tokens?.[1]?.symbol} per {Tokens?.[0]?.symbol}</span>
			<ProgressBar curPricePerc={curPricePerc} />
			{!mini && (
				<Flex justify="between" className="w-100 fw-2 text-gray-600">
					{inBetweenTicks.length > 2 ? (priceDir === '0' ? inBetweenTicks : inBetweenTicks.reverse()).map((tick, idx, arr) => (
						<div
							key={idx}
							className={classnames('tick', {
								hide: idx === 0 || idx === arr.length - 1,
								current: tick.isCurrent
							})}
						/>
					)) : (
						<span className="no-ticks f-rem-0.8 text-gray-600 text-center text-wrap w-100">No ticks in between</span>
					)}
				</Flex>
			)}
		</Flex>
	);
};

const ProgressBar = ({ curPricePerc }) => {
	const [widthDelay, setWidthDelay] = useState(0);
	const progressBarColor = getColorFromRatio(curPricePerc);

	useEffect(() => {
		let timeout;

		if (widthDelay !== curPricePerc) {
			timeout = setTimeout(() => {
				setWidthDelay(curPricePerc);
			}, 500);
		}

		return () => {
			timeout && clearTimeout(timeout);
		}
	}, [widthDelay, curPricePerc]);

	return (
		<div className="price-progress-container">
			<div
				className={`price-progress bg-${progressBarColor}`}
				style={{width: `${widthDelay}%`}}
			/>
		</div>
	);
};

const PositionPercentages = ({pool, tickEndsSorted, myTicks, mini}) => {
	const currentTick = myTicks.find(t => t.isCurrent);
	const [startTick, endTick] = tickEndsSorted;

	const [Token0, Token1] = getTokens(pool.Token0, pool.Token1);

	const [
		startPercFormatted, endPercFormatted, rangePercFormatted
	] = useMemo(() => {
		if (!currentTick?.tickIdx) return [];

		const curTickPrice = tickToPrice(Token0, Token1, currentTick.tickIdx);
		const startTickPrice = tickToPrice(Token0, Token1, startTick.tickIdx);
		const endTickPrice = tickToPrice(Token0, Token1, endTick.tickIdx);

		const startPercAwayFromCurPrice = startTickPrice.subtract(curTickPrice).divide(startTickPrice).multiply(100);
		const endPercAwayFromCurPrice = endTickPrice.subtract(curTickPrice).divide(endTickPrice).multiply(100);
		const fullRangePerc = Math.abs(startPercAwayFromCurPrice.toFixed(2)) + Math.abs(endPercAwayFromCurPrice.toFixed(2));

		const startPercFormatted = `${Math.abs(startPercAwayFromCurPrice.toFixed(2))}%`;
		const endPercFormatted = `${Math.abs(endPercAwayFromCurPrice.toFixed(2))}%`;
		const rangePercFormatted = `${fixedAsNumber(fullRangePerc, 2)}%`;

		return [
			startPercFormatted,
			endPercFormatted,
			rangePercFormatted,
		];
	}, [Token0, Token1, currentTick?.tickIdx, startTick.tickIdx, endTick.tickIdx]);

	return !currentTick ? (
		<Flex justify="center" className="PositionPercentages">
			<span className="text-center">No position info found</span>
		</Flex>
	) : (
		<Flex direction="column" className={classnames('PositionPercentages', { 'border-0 mt-0 pt-0': mini })}>
			<span className={classnames({'mb-1.5': !mini, 'mb-3': mini})}>Position range</span>
			<Flex justify="between" align="center" className="range-container w-100">
				<Flex direction="column" className="edge-range-container border rounded p-2 text-center w-25">
					<span className="f-rem-0.9 fw-5 text-gray-700 text-wrap">-{startPercFormatted}</span>
					<span className="f-rem-0.7 fw-5 text-gray-600 text-wrap">From tick price</span>
				</Flex>
				<div className="border-line-between"></div>
				<Flex direction="column" className="edge-range-container border rounded py-2 px-4 text-center">
					<span className="f-rem-1 fw-5 text-gray-700 text-wrap">{rangePercFormatted}</span>
					<span className="f-rem-0.7 fw-5 text-gray-600 text-wrap">Full range</span>
				</Flex>
				<div className="border-line-between"></div>
				<Flex direction="column" className="edge-range-container border rounded p-2 text-center w-25">
					<span className="f-rem-0.9 fw-5 text-gray-700 text-wrap">+{endPercFormatted}</span>
					<span className="f-rem-0.7 fw-5 text-gray-600 text-wrap">From tick price</span>
				</Flex>
			</Flex>
		</Flex>
	);
};
