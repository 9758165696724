import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { BarChart, Bar, LabelList, XAxis, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import min from 'lodash/min';
import max from 'lodash/max';

import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

import { AutoColumn } from 'components/Column';
import { RowFixed } from 'components/Row';
import Flex from 'components/Flex';

import { compactNumber } from 'util/numberFormatter';
import { getTokens } from 'util/tokens';
import { useGetTickDataQuery } from 'api/client';

import HideCardTitle from 'pages/uniswap/components/HideCardTitle';
import useFormatPoolData from './hooks/useFormatPoolData';
import MyPositionInfo from './MyPositionInfo';
import './TickChart.scss';

const ZOOM_INTERVAL = 20;

const theme = {
	pink: "#ff007a",
	pinkDim: 'rgba(255,0,122,0.35)',
	blue: "#2172E5",
	blueDim: "rgba(33,144,229,0.25)",
	blueInPosition: "#4790f8",
	green: "#2dce89",
}

function trimTickEnds(ticks = []) {
	let startIdx = 0;
	let endIdx = 0;
	// From beg
	for (let i = 0; i < ticks.length; i++) {
		const tick = ticks[i];

		if (tick.activeLiquidity > 0) {
			startIdx = i;
			break;
		}
	}

	// from end
	for (let j = 0; j < ticks.length; j++) {
		const reversedIdx = ticks.length - (j+1);
		const tick = ticks[reversedIdx];

		if (tick.activeLiquidity > 0) {
			endIdx = reversedIdx;
			break;
		}
	}

	return ticks.slice(startIdx, endIdx + 1);
}

export function filterTicks(ticks = []) {
	return trimTickEnds(ticks);
}

const formatPrice = (price) => price ? Number(price).toLocaleString(undefined, { minimumSignificantDigits: 1 }) : '';

function TickChart(props) {
	const { chainId, pool, position, className, chartHeight = 410, hideCardTitle, hideCurrentPriceLabel, hidePositionInfo } = props;
	const [reverseChart, setReverseChart] = useState(true);
	const [hideChart, setHideChart] = useState(false);

	const [Token0, Token1] = getTokens(pool.Token0, pool.Token1);

	const {
    data: tickData = {},
    isLoading: tickDataIsLoading,
  } = useGetTickDataQuery({
  	chainId,
  	token0Address: Token0.address,
  	token1Address: Token1.address,
  	feeRate: pool.fee,
  	// pool,
  });

  const {
  	data: formattedTickData = [],
  	isLoading: formatPoolDataIsLoading,
  } = useFormatPoolData({ tickData, pool, position });

  const filteredTicks = filterTicks(formattedTickData);

	return (
		<Card
			className={`TickChart shadow border-0 rounded ${className}`}
		>
			{tickDataIsLoading || formatPoolDataIsLoading ? (
				<Flex justify="center" align="center" className="text-secondary py-2">
					<FontAwesomeIcon icon={faSpinnerThird} spin className="mr-1" /> Loading Liq Info
				</Flex>
			) : (
				<>
					{!hideCardTitle && (
						<HideCardTitle title="Liquidity Info" hide={hideChart} setHide={setHideChart} />
					)}
					{/*Chart*/}
					<RenderedChart
						hideChart={hideChart}
						filteredTicks={filteredTicks}
						pool={pool}
						chartHeight={chartHeight}
						reverseChart={reverseChart}
						hideCurrentPriceLabel={hideCurrentPriceLabel}
					/>
					{/*My Position*/}
					{!hidePositionInfo && position && Object.keys(position).length > 0 ? (
						<MyPositionInfo
							pool={pool}
							ticks={filteredTicks}
							hide={hideChart}
							reverseChart={reverseChart}
							setReverseChart={setReverseChart}
						/>
					) : null}
				</>
			)}
		</Card>
	);
};

export default TickChart;

const CustomBar = ({ x, y, width, height, fill, index, isTickInPosition, positionPercOfTickLiq }) => {
	const gradient = isTickInPosition && positionPercOfTickLiq && (
		<g>
			<defs>
				<linearGradient id={index} x1="0%" y1="0%" x2="0%" y2="100%">
					{/*Blue*/}
          <stop offset={`${100-(100*positionPercOfTickLiq)}%`} stopColor={fill} />
	        {/*Green*/}
	        {/*<stop offset={`${100*positionPercOfTickLiq}%`} stopColor={theme.green} stopOpacity="1" />*/}
          <stop offset="0%" stopColor={theme.green} stopOpacity="1" />
        </linearGradient>
      </defs>
      <rect x={x} y={y} fill={`url(#${index})`} width={width} height={height} rx="2" />
		</g>
	);

  return gradient || (
    <g>
      <rect x={x} y={y} fill={fill} width={width} height={height} rx="2" />
    </g>
  );
};

const CustomToolTip = ({ chartProps, poolData, currentPrice }) => {
	const [Token0, Token1] = getTokens(poolData.Token0, poolData.Token1);
	const price0 = chartProps?.payload?.[0]?.payload.price0;
	const price1 = chartProps?.payload?.[0]?.payload.price1;
	const tvlToken0 = chartProps?.payload?.[0]?.payload.tvlToken0;
	const tvlToken1 = chartProps?.payload?.[0]?.payload.tvlToken1;
	const tickIdx = chartProps?.payload?.[0]?.payload.tickIdx;
	const { tickCurrent } = poolData;

	return (
		<div className="CustomToolTip">
			<AutoColumn>
				<Flex justify="between">
					<span className="stats">Tick stats</span>
					<span className="stats">Idx: {tickIdx}</span>
				</Flex>
				<Flex justify="between" className="mt-1.5">
					<span>{Token0?.symbol} Price:</span>
					<span>{formatPrice(price0)} {Token1?.symbol}</span>
				</Flex>
				<Flex justify="between" className="mt-1.5">
					<span>{Token1?.symbol} Price:</span>
					<span>{formatPrice(price1)} {Token0?.symbol}</span>
				</Flex>
				{tickIdx >= tickCurrent && (
					<Flex justify="between" className="mt-1.5">
						<span>{Token0?.symbol} Locked:</span>
						<span>{tvlToken0 ? compactNumber(tvlToken0) : '0'} {Token0?.symbol}</span>
					</Flex>
				)}
				{tickIdx < tickCurrent && (
					<Flex justify="between" className="mt-1.5">
						<span>{Token1?.symbol} Locked:</span>
						<span>{tvlToken1 ? compactNumber(tvlToken1) : '0'} {Token1?.symbol}</span>
					</Flex>
				)}
				{/*<Flex justify="between" className="mt-1.5">
					{currentPrice && price0 && currentPrice > price0 ? (
						<>
							<span>{Token0?.symbol} Locked:</span>
							<span>{tvlToken0 ? compactNumber(tvlToken0) : '0'} {Token0?.symbol}</span>
						</>
					) : (
						<>
							<span>{Token1?.symbol} Locked:</span>
							<span>{tvlToken1 ? compactNumber(tvlToken1) : '0'} {Token1?.symbol}</span>
						</>
					)}
				</Flex>*/}
			</AutoColumn>
		</div>
	);
};

const CurrentPriceLabel = ({ data, chartProps, poolData, chartHeight }) => {
	const [Token0, Token1] = getTokens(poolData.Token0, poolData.Token1);
	const labelData = chartProps;
	const entryData = data?.[labelData.index];
	const price0 = entryData?.price0;
	const price1 = entryData?.price1;

	return !entryData?.isCurrent ? null : (
		<g className="CurrentPriceLabel">
		  <foreignObject x={labelData.x - 80} y={chartHeight - 92} width={'100%'} height={100}>
		    <div className="wrapper">
		      <AutoColumn gap="6px">
		        <RowFixed align="center">
		          <span className="mr-2 f-rem-0.75">Current Price</span>
		          <div
		            style={{
		              marginTop: '2px',
		              height: '6px',
		              width: '6px',
		              borderRadius: '50%',
		              backgroundColor: theme.pink,
		            }}
		          ></div>
		        </RowFixed>
		        <span className="f-rem-0.8">{`1 ${Token0.symbol} = ${formatPrice(price0)} ${Token1.symbol}`}</span>
		        <span className="f-rem-0.8">{`1 ${Token1.symbol} = ${formatPrice(price1)} ${Token0.symbol}`}</span>
		      </AutoColumn>
		    </div>
		  </foreignObject>
		</g>
	);
};

export const RenderedChart = ({ hideChart, filteredTicks, pool, chartHeight, hideCurrentPriceLabel, dimTicks, onTickTap, reverseChart = true }) => {
	const [zoomState, setZoomState] = useState({level: 0, left: 0, right: undefined});

	const zoomedTicks = useMemo(() => {
		if (filteredTicks) {
			return filteredTicks.slice(zoomState.left, zoomState.right);
		}
		return undefined;
	}, [filteredTicks, zoomState.left, zoomState.right]);

	function handleZoom(direction) {
		const newLevel = max([0, zoomState.level + direction]);
		const left = max([newLevel*ZOOM_INTERVAL], 0);
		const right = min([filteredTicks.length - (newLevel*ZOOM_INTERVAL), filteredTicks.length]);

		setZoomState({ level: newLevel, left, right });
	}

	return (
		<div
			className={classnames('chart-container w-100', {
				'd-none': hideChart
			})}
			style={{height: `${chartHeight}px`}}
		>
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
          width={500}
          height={chartHeight - 110}
          data={zoomedTicks}
          margin={{
            top: 7.5,
            right: 20,
            left: 20,
            bottom: !hideCurrentPriceLabel ? 60 : -10,
          }}
          onClick={(props) => typeof onTickTap === 'function' && onTickTap(props?.activePayload?.[0]?.payload)}
        >
        	<Tooltip
            content={(props) => (
              <CustomToolTip
              	chartProps={props}
              	poolData={pool}
              	currentPrice={pool.token0Price}
              />
            )}
          />
          <XAxis reversed={reverseChart} tick={false} />
          <Bar
            dataKey="activeLiquidity"
            fill="#2172E5"
            isAnimationActive={false}
            shape={(props) => {
              // eslint-disable-next-line react/prop-types
              return (
              	<CustomBar
              		{...props}
              	/>
              )
            }}
          >
            {zoomedTicks?.map((entry, index) => {
            	const { isCurrent, tickIdx, isTickInPosition } = entry;
            	const { lessThan, greaterThan } = dimTicks || {};
            	const isDim = !!dimTicks ? tickIdx < lessThan || tickIdx > greaterThan : false;
            	const fill = isCurrent && isDim ? theme.pinkDim
            		: isCurrent ? theme.pink
            		: isDim ? theme.blueDim
            		: isTickInPosition ? theme.blueInPosition
            		: theme.blue;

              return (
              	<Cell
              		key={`cell-${index}`}
              		fill={fill}
              	/>
              )
            })}
            {!hideCurrentPriceLabel && (
	            <LabelList
	              dataKey="activeLiquidity"
	              position="inside"
	              content={(props) => (
	              	<CurrentPriceLabel
	              		chartProps={props}
	              		poolData={pool}
	              		data={zoomedTicks}
	              		chartHeight={chartHeight}
	              	/>
	              )}
	            />
            )}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div className="chart-zoom-btns">
      	<FontAwesomeIcon icon={faMinus} className="zoom-btn" onClick={() => handleZoom(-1)}/>
      	<FontAwesomeIcon icon={faPlus}  className="zoom-btn" onClick={() => handleZoom(1)}/>
      </div>
		</div>
	);
};
