import { useEffect, useRef } from 'react';
import getBrowserExtensionProvider from 'util/getBrowserExtensionProvider';

function useOnBlockUpdated(callback) {
	const lastBlockNumber = useRef(0);

  useEffect(() => {
  	const provider = getBrowserExtensionProvider();
    const subscription = provider?.on('block', blockNumber => {
    	if (lastBlockNumber.current !== blockNumber) {
    		lastBlockNumber.current = blockNumber;
    		callback(blockNumber);
    	}
    });

    return () => subscription?.removeAllListeners();
  });
};

export default useOnBlockUpdated;
